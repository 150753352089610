// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Divider = require("@mui/joy/Divider").default;
var BillPayData$Thin = require("../drafts/BillPayData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDBillPayDrawer$Thin = require("./BDBillPayDrawer.bs.js");
var MakeAPaymentModal$Thin = require("../../common/molecules/payment-modules/MakeAPaymentModal.bs.js");
var MakePaymentDrawer$Thin = require("./make-payment/MakePaymentDrawer.bs.js");
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;

function BDBillingSummary(props) {
  var cantPay = props.cantPay;
  var refetch = props.refetch;
  var paymentSuggestions = props.paymentSuggestions;
  var draftMode = props.draftMode;
  var fetchErr = props.fetchErr;
  var draftBilling = props.draftBilling;
  var isActive = props.isActive;
  var match = React.useState(function () {
        return false;
      });
  var setInvoiceDrawerOpen = match[1];
  var invoiceDrawerOpen = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setMakePaymentDrawerOpen = match$1[1];
  var openPaymentDrawer = function (e) {
    e.preventDefault();
    if (invoiceDrawerOpen) {
      setInvoiceDrawerOpen(function (param) {
            return false;
          });
    }
    setMakePaymentDrawerOpen(function (param) {
          return true;
        });
  };
  var noPaymentsToMake = paymentSuggestions !== undefined ? CS_Decimal$Util.Decimal10_2.toFloat(paymentSuggestions.payFull) === 0.0 && paymentSuggestions.payCurrent === undefined && paymentSuggestions.payMin === undefined && paymentSuggestions.payCustom === undefined : true;
  var content = draftBilling !== undefined ? draftBilling : props.currentBilling;
  var viewDetails = JsxRuntime.jsxs(Stack, {
        direction: "row",
        justifyContent: "center",
        alignItems: "center",
        spacing: 1,
        sx: {
          "&:hover": {
            cursor: "pointer"
          }
        },
        onClick: (function (param) {
            setInvoiceDrawerOpen(function (param) {
                  return true;
                });
          }),
        children: [
          JsxRuntime.jsx(Stack, {
                justifyContent: "center",
                alignItems: "center",
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          color: "primary",
                          level: "body-sm",
                          children: Caml_option.some(Txt$Thin.BookingDetails.viewBillingDetails.value)
                        }))
              }),
          JsxRuntime.jsx(VisibilityOutlined, {
                color: "primary",
                fontSize: "xl",
                sx: {
                  position: "relative",
                  top: 1
                }
              })
        ]
      });
  var match$2 = React.useState(function () {
        return "Amount";
      });
  var setPane = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setWhichSelected = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setAmount = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setClearer = match$5[1];
  var match$6 = React.useState(function () {
        
      });
  var setPaymentAction = match$6[1];
  var paymentReturn = React.useState(function () {
        return "NotAsked";
      });
  var match$7 = React.useState(function () {
        
      });
  var setCalculatedFee = match$7[1];
  var clearAll = function () {
    setWhichSelected(function (param) {
          
        });
    setAmount(function (param) {
          
        });
    setPaymentAction(function (param) {
          
        });
    setCalculatedFee(function (param) {
          
        });
    setClearer(function (clearer) {
          return !clearer;
        });
    setPane(function (param) {
          return "Amount";
        });
    setMakePaymentDrawerOpen(function (param) {
          return false;
        });
  };
  var match$8 = React.useState(function () {
        return false;
      });
  var setProcessPaymentModalOpen = match$8[1];
  var match$9 = content.payment.pendingPaymentSubtotal;
  var match$10 = content.totalAmountIncludingPending;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Sheet, {
                      sx: {
                        p: {
                          xs: 2,
                          md: 4
                        },
                        borderRadius: "md",
                        border: 1,
                        borderColor: "neutral.300",
                        height: "100%"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  height: "100%"
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "h3",
                                                component: "h2",
                                                fontWeight: "md",
                                                children: Caml_option.some(Txt$Thin.BookingDetails.billingSummaryHeading.value)
                                              }),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                sx: {
                                                  display: {
                                                    xs: "none",
                                                    md: "flex"
                                                  },
                                                  height: "100%"
                                                },
                                                children: Caml_option.some(viewDetails)
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        justifyContent: "space-between",
                                        spacing: 2,
                                        sx: {
                                          height: "100%"
                                        },
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                spacing: 2,
                                                children: [
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        justifyContent: "space-between",
                                                        children: [
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-md",
                                                                children: Caml_option.some(Txt$Thin.BookingDetails.totalBilled.value)
                                                              }),
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-md",
                                                                children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.billing.billingLineSubtotal)))
                                                              })
                                                        ]
                                                      }),
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        justifyContent: "space-between",
                                                        children: [
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-md",
                                                                children: Caml_option.some(Txt$Thin.BookingDetails.totalPaid.value)
                                                              }),
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-md",
                                                                children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.payment.completedPaymentSubtotal)))
                                                              })
                                                        ]
                                                      }),
                                                  JsxRuntime.jsx(Divider, {}),
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        justifyContent: "space-between",
                                                        children: [
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-lg",
                                                                fontWeight: "700",
                                                                children: Caml_option.some(Txt$Thin.BookingDetails.remainingBalance.value)
                                                              }),
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-lg",
                                                                fontWeight: "700",
                                                                children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmount)))
                                                              })
                                                        ]
                                                      }),
                                                  CS_Decimal$Util.Decimal10_2.toFloat(match$9) > 0.0 ? JsxRuntime.jsxs(Stack, {
                                                          direction: "column",
                                                          spacing: 1,
                                                          children: [
                                                            JsxRuntime.jsxs(Stack, {
                                                                  direction: "row",
                                                                  justifyContent: "space-between",
                                                                  children: [
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-sm",
                                                                          sx: {
                                                                            color: "neutral.500",
                                                                            fontStyle: "italic"
                                                                          },
                                                                          fontWeight: "300",
                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.pendingPayment.value)
                                                                        }),
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-md",
                                                                          sx: {
                                                                            color: "text.primary",
                                                                            fontStyle: "italic"
                                                                          },
                                                                          children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match$9)))
                                                                        })
                                                                  ]
                                                                }),
                                                            JsxRuntime.jsxs(Stack, {
                                                                  direction: "row",
                                                                  justifyContent: "space-between",
                                                                  children: [
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-sm",
                                                                          sx: {
                                                                            color: "neutral.500",
                                                                            fontStyle: "italic"
                                                                          },
                                                                          fontWeight: "300",
                                                                          children: Caml_option.some(Txt$Thin.BookingDetails.balanceAfterPending.value)
                                                                        }),
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-md",
                                                                          sx: {
                                                                            color: "text.primary",
                                                                            fontStyle: "italic"
                                                                          },
                                                                          children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match$10)))
                                                                        })
                                                                  ]
                                                                })
                                                          ]
                                                        }) : null,
                                                  JsxRuntime.jsx(BDBillPayDrawer$Thin.make, {
                                                        open_: invoiceDrawerOpen,
                                                        onClose: (function () {
                                                            setInvoiceDrawerOpen(function (param) {
                                                                  return false;
                                                                });
                                                          }),
                                                        fragmentRefs: props.fragmentRefs,
                                                        isActive: isActive,
                                                        getPreviewFromDiff: props.getPreviewFromDiff,
                                                        invoiceContent: content,
                                                        draftMode: draftMode,
                                                        noPaymentsToMake: noPaymentsToMake,
                                                        openPaymentDrawer: openPaymentDrawer,
                                                        cantPay: cantPay
                                                      }),
                                                  draftBilling !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                                          message: JsxRuntime.jsxs(Stack, {
                                                                direction: "column",
                                                                children: [
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "title-sm",
                                                                        sx: {
                                                                          color: "#DBA313"
                                                                        },
                                                                        children: "Booking Changes"
                                                                      }),
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "body-sm",
                                                                        fontWeight: "normal",
                                                                        children: "This Billing Preview shows the new balance that will be due including the in-progress changes. You'll see a detailed comparison with your current charges when you select \"Save Changes.\""
                                                                      })
                                                                ]
                                                              }),
                                                          type_: "warning"
                                                        }) : null,
                                                  props.lifecycleStatus === "CANCELED" ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                                          message: JsxRuntime.jsxs(Stack, {
                                                                direction: "column",
                                                                children: [
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "title-sm",
                                                                        sx: {
                                                                          color: Common$Thin.NotificationColor.getColorNoSuccess("Danger2")
                                                                        },
                                                                        children: "Canceled Booking"
                                                                      }),
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "body-sm",
                                                                        fontWeight: "normal",
                                                                        children: "This booking has been canceled. No further changes can be made."
                                                                      })
                                                                ]
                                                              }),
                                                          type_: "error"
                                                        }) : null
                                                ]
                                              }),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "column",
                                                sx: {
                                                  display: {
                                                    xs: "flex",
                                                    md: "none"
                                                  },
                                                  flex: 1,
                                                  height: "100%"
                                                },
                                                children: Caml_option.some(BillPayData$Thin.PaymentsStatus.getPaymentStatusChip(content.paymentPlan, draftMode))
                                              }),
                                          isActive ? JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  justifyContent: {
                                                    xs: "space-between",
                                                    md: "space-between"
                                                  },
                                                  sx: {
                                                    width: "100%",
                                                    marginTop: {
                                                      xs: 5,
                                                      md: 0
                                                    }
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Stack, {
                                                          direction: "column",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          sx: {
                                                            display: {
                                                              xs: "flex",
                                                              md: "none"
                                                            },
                                                            height: "100%"
                                                          },
                                                          children: Caml_option.some(viewDetails)
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          direction: "column",
                                                          sx: {
                                                            display: {
                                                              xs: "none",
                                                              md: "flex"
                                                            },
                                                            height: "100%"
                                                          },
                                                          children: Caml_option.some(BillPayData$Thin.PaymentsStatus.getPaymentStatusChip(content.paymentPlan, draftMode))
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          direction: "column",
                                                          sx: {
                                                            display: draftMode || noPaymentsToMake ? "none" : "flex"
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                                                    onClick: openPaymentDrawer,
                                                                    size: "md",
                                                                    disabled: draftMode || noPaymentsToMake || cantPay,
                                                                    children: Caml_option.some(Txt$Thin.BookingDetails.makeAPayment.value)
                                                                  }))
                                                        })
                                                  ]
                                                }) : null,
                                          fetchErr !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  level: "body-sm",
                                                  sx: {
                                                    fontStyle: "italic"
                                                  },
                                                  children: Caml_option.some(fetchErr)
                                                }) : null
                                        ]
                                      })
                                ]
                              }))
                    }),
                paymentSuggestions !== undefined ? JsxRuntime.jsx(MakePaymentDrawer$Thin.make, {
                        open_: match$1[0],
                        onClose: (function () {
                            setMakePaymentDrawerOpen(function (param) {
                                  return false;
                                });
                          }),
                        paymentSuggestions: paymentSuggestions,
                        voyageSlug: props.voyageSlug,
                        bookingId: props.bookingId,
                        refetch: refetch,
                        pane: match$2[0],
                        setPane: setPane,
                        whichSelected: match$3[0],
                        setWhichSelected: setWhichSelected,
                        clearAll: clearAll,
                        amount: match$4[0],
                        setAmount: setAmount,
                        paymentAction: match$6[0],
                        setPaymentAction: setPaymentAction,
                        paymentReturn: paymentReturn,
                        calculatedFee: match$7[0],
                        setCalculatedFee: setCalculatedFee,
                        clearer: match$5[0],
                        setProcessPaymentModalOpen: setProcessPaymentModalOpen
                      }) : null,
                JsxRuntime.jsx(MakeAPaymentModal$Thin.make, {
                      open_: match$8[0],
                      onClose: (function () {
                          setProcessPaymentModalOpen(function (param) {
                                return false;
                              });
                        }),
                      paymentReturn: paymentReturn,
                      onFinish: (function (param) {
                          clearAll();
                        }),
                      onPaymentSuccess: (function (param) {
                          refetch();
                        }),
                      text: {
                        loadingTitle: "All hands on deck!",
                        loadingDesc: "Our crew is working diligently to process your payment. This should only take a moment.",
                        successTitle: "Full steam ahead!",
                        successDesc: "Great news! Your payment has gone through successfully. You're all set!",
                        successBtnText: "Got it!",
                        failureTitle: "Navigational error"
                      }
                    })
              ]
            });
}

var make = BDBillingSummary;

exports.make = make;
/* react Not a pure module */
