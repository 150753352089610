// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BookingDiff$Thin = require("./BookingDiff.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDPreferences_detail_graphql$Thin = require("../../__generated__/BDPreferences_detail_graphql.bs.js");
var ConnectedAccountsPage_query_graphql$Thin = require("../../__generated__/ConnectedAccountsPage_query_graphql.bs.js");

var genderOptions = [
  "F",
  "M",
  "X"
];

function genderToText(g) {
  if (!(g === "F" || g === "M" || g === "X")) {
    return "Unspecified";
  }
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

function getPaxDisplayName(p) {
  var name = p.preferredFullName;
  if (name !== undefined) {
    return name;
  }
  var match = p.docFName;
  var match$1 = p.docLName;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
    } else {
      return "TBA";
    }
  }
  var fName = Caml_option.valFromOption(match);
  if (match$1 !== undefined) {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
  } else {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
  }
}

function paxify(paxes) {
  return Belt_Array.map(paxes, (function (pax) {
                var bu = pax.bu;
                var match = pax.paxDataStatus;
                var tmp;
                tmp = (match === "INCOMPLETE" || match === "CONFIRMED" || match === "INVITED") && match === "INVITED" ? pax.invitationEmail : pax.email;
                return {
                        accountId: Caml_option.some(pax.accountId),
                        docFName: pax.docFirstName,
                        docLName: pax.docLastName,
                        docGender: pax.docGender,
                        docCitizenship: pax.docCitizenship,
                        preferredFName: pax.preferredFirstName,
                        preferredLName: pax.preferredLastName,
                        birthdate: pax.docBirthdate,
                        preferredFullName: pax.preferredFullName,
                        loyaltyNumber: bu !== undefined ? Caml_option.some(bu.loyaltyNumber) : undefined,
                        numLoyaltyVoyages: Belt_Option.getWithDefault(pax.numEligibleVoyages, 0) + Belt_Option.getWithDefault(pax.numPendingVoyages, 0) | 0,
                        loyaltyTier: pax.currentTierTitle,
                        email: tmp,
                        paxStatus: {
                          TAG: "Status",
                          _0: pax.paxDataStatus
                        },
                        idx: pax.idx,
                        shouldAttachToIdx: undefined,
                        paxPatchPerm: "Limited"
                      };
              }));
}

function getPaxPatchFromPax(pax, accountId) {
  return {
          accountId: accountId,
          pDocGender: pax.docGender,
          pDocCitizenship: pax.docCitizenship,
          updateUserAccount: true,
          updateOtherVoyages: []
        };
}

function getConnectedPatchFromPax(pax) {
  return {
          cpDocFirstName: Belt_Option.map(pax.docFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive),
          cpDocLastName: Belt_Option.map(pax.docLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive),
          cpDocGender: pax.docGender,
          cpDocCitizenship: pax.docCitizenship,
          cpBirthdate: pax.birthdate
        };
}

function postMushIdxMap(paxes) {
  return Belt_Array.reduceWithIndex(paxes, undefined, (function (acc, pax, i) {
                return Belt_MapInt.set(acc, pax.idx, i + 1 | 0);
              }));
}

function getFullPreferredName(pax) {
  var match = pax.preferredFName;
  var match$1 = pax.preferredLName;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
    } else {
      return ;
    }
  }
  var fName = Caml_option.valFromOption(match);
  if (match$1 !== undefined) {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1));
  } else {
    return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
  }
}

function replaceDropDetachOrReturn(paxes, diff, connectedAccounts, ownAccount) {
  return Belt_Array.keep(Belt_Array.keep(Belt_Array.keep(Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                                        var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceInvitedPax, undefined), pax.idx);
                                        if (match === undefined) {
                                          return pax;
                                        }
                                        var lastName = match.lastName;
                                        var firstName = match.firstName;
                                        var lastName$1 = Belt_Option.map(lastName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
                                        return {
                                                accountId: undefined,
                                                docFName: undefined,
                                                docLName: undefined,
                                                docGender: undefined,
                                                docCitizenship: undefined,
                                                preferredFName: Caml_option.some(firstName),
                                                preferredLName: lastName,
                                                birthdate: undefined,
                                                preferredFullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(firstName) + (
                                                  lastName$1 !== undefined ? " " + lastName$1 : ""
                                                ),
                                                loyaltyNumber: undefined,
                                                numLoyaltyVoyages: 0,
                                                loyaltyTier: undefined,
                                                email: Caml_option.some(match.email),
                                                paxStatus: {
                                                  TAG: "Draft",
                                                  _0: "Invite"
                                                },
                                                idx: pax.idx,
                                                shouldAttachToIdx: undefined,
                                                paxPatchPerm: "None"
                                              };
                                      })), (function (pax) {
                                    var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceExistingLoyaltyPax, undefined), pax.idx);
                                    if (match !== undefined) {
                                      return {
                                              accountId: Caml_option.some(match.acctId),
                                              docFName: undefined,
                                              docLName: undefined,
                                              docGender: undefined,
                                              docCitizenship: undefined,
                                              preferredFName: undefined,
                                              preferredLName: undefined,
                                              birthdate: undefined,
                                              preferredFullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.protectedName),
                                              loyaltyNumber: Caml_option.some(match.loyalty),
                                              numLoyaltyVoyages: 0,
                                              loyaltyTier: undefined,
                                              email: match.loyEmail,
                                              paxStatus: {
                                                TAG: "Draft",
                                                _0: "Replace"
                                              },
                                              idx: pax.idx,
                                              shouldAttachToIdx: undefined,
                                              paxPatchPerm: "None"
                                            };
                                    } else {
                                      return pax;
                                    }
                                  })), (function (pax) {
                                var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replacePaxExistingConnected, undefined), pax.idx);
                                if (accountId === undefined) {
                                  return pax;
                                }
                                if (connectedAccounts === undefined) {
                                  return pax;
                                }
                                var accountId$1 = Caml_option.valFromOption(accountId);
                                var match = Belt_Array.get(Belt_Array.keep(connectedAccounts.nodes, (function (acct) {
                                            return Caml_obj.equal(acct.accountId, accountId$1);
                                          })), 0);
                                if (match !== undefined) {
                                  return {
                                          accountId: Caml_option.some(match.accountId),
                                          docFName: match.legalDocumentFirstName,
                                          docLName: match.legalDocumentLastName,
                                          docGender: match.legalDocumentGender,
                                          docCitizenship: match.legalDocumentCitizenship,
                                          preferredFName: Caml_option.some(match.firstName),
                                          preferredLName: match.lastName,
                                          birthdate: match.legalDocumentBirthdate,
                                          preferredFullName: match.fullDisplayName,
                                          loyaltyNumber: Caml_option.some(match.loyaltyNumber),
                                          numLoyaltyVoyages: Belt_Option.getWithDefault(match.numEligibleVoyages, 0) + Belt_Option.getWithDefault(match.numPendingVoyages, 0) | 0,
                                          loyaltyTier: match.currentTierTitle,
                                          email: match.email,
                                          paxStatus: {
                                            TAG: "Draft",
                                            _0: "Replace"
                                          },
                                          idx: pax.idx,
                                          shouldAttachToIdx: undefined,
                                          paxPatchPerm: "Limited"
                                        };
                                }
                                if (ownAccount === undefined) {
                                  return pax;
                                }
                                var match$1 = Belt_Array.get(Belt_Array.keep(ownAccount.nodes, (function (acct) {
                                            return Caml_obj.equal(acct.accountId, accountId$1);
                                          })), 0);
                                if (match$1 !== undefined) {
                                  return {
                                          accountId: Caml_option.some(match$1.accountId),
                                          docFName: match$1.legalDocumentFirstName,
                                          docLName: match$1.legalDocumentLastName,
                                          docGender: match$1.legalDocumentGender,
                                          docCitizenship: match$1.legalDocumentCitizenship,
                                          preferredFName: match$1.firstName,
                                          preferredLName: match$1.lastName,
                                          birthdate: match$1.legalDocumentBirthdate,
                                          preferredFullName: getFullPreferredName(pax),
                                          loyaltyNumber: Caml_option.some(match$1.loyaltyNumber),
                                          numLoyaltyVoyages: Belt_Option.getWithDefault(match$1.numEligibleVoyages, 0) + Belt_Option.getWithDefault(match$1.numPendingVoyages, 0) | 0,
                                          loyaltyTier: match$1.currentTierTitle,
                                          email: match$1.email,
                                          paxStatus: {
                                            TAG: "Draft",
                                            _0: "Replace"
                                          },
                                          idx: pax.idx,
                                          shouldAttachToIdx: undefined,
                                          paxPatchPerm: "Limited"
                                        };
                                } else {
                                  return pax;
                                }
                              })), (function (pax) {
                            var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.replaceNewConnectedPax, undefined), pax.idx);
                            if (match === undefined) {
                              return pax;
                            }
                            var preferredLastName = match.preferredLastName;
                            var preferredFirstName = match.preferredFirstName;
                            var docLastName = match.docLastName;
                            var docFirstName = match.docFirstName;
                            var tmp;
                            if (preferredFirstName !== undefined) {
                              var fName = Caml_option.valFromOption(preferredFirstName);
                              tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
                            } else {
                              tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(docFirstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(docLastName);
                            }
                            return {
                                    accountId: undefined,
                                    docFName: Caml_option.some(docFirstName),
                                    docLName: Caml_option.some(docLastName),
                                    docGender: match.docGender,
                                    docCitizenship: Caml_option.some(match.docCitizenship),
                                    preferredFName: preferredFirstName,
                                    preferredLName: preferredLastName,
                                    birthdate: Caml_option.some(match.docBirthdate),
                                    preferredFullName: tmp,
                                    loyaltyNumber: undefined,
                                    numLoyaltyVoyages: 0,
                                    loyaltyTier: undefined,
                                    email: undefined,
                                    paxStatus: {
                                      TAG: "Draft",
                                      _0: "Replace"
                                    },
                                    idx: pax.idx,
                                    shouldAttachToIdx: undefined,
                                    paxPatchPerm: "All"
                                  };
                          })), (function (pax) {
                        var match = diff.dropBerths;
                        var match$1 = pax.idx;
                        if (match !== undefined) {
                          return !Belt_Array.some(match, (function (b) {
                                        return b === match$1;
                                      }));
                        } else {
                          return true;
                        }
                      })), (function (pax) {
                    var match = diff.detachPax;
                    var match$1 = pax.idx;
                    if (match !== undefined) {
                      return !Belt_Array.some(match, (function (b) {
                                    return b === match$1;
                                  }));
                    } else {
                      return true;
                    }
                  })), (function (pax) {
                var match = diff.revokePaxInvitation;
                var match$1 = pax.paxStatus;
                var match$2 = pax.idx;
                if (match === undefined) {
                  return true;
                }
                if (typeof match$1 !== "object") {
                  return true;
                }
                if (match$1.TAG !== "Status") {
                  return true;
                }
                var tmp = match$1._0;
                if ((tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") && tmp === "INVITED") {
                  return !Belt_MapInt.has(Caml_option.valFromOption(match), match$2);
                } else {
                  return true;
                }
              }));
}

function attachOrReturn(paxes, diff, connectedAccounts, ownAccount) {
  return Belt_Array.map(Belt_Array.map(Belt_Array.map(Belt_Array.map(paxes, (function (pax) {
                            var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachInvitedPax, undefined), pax.idx);
                            if (match === undefined) {
                              return pax;
                            }
                            var lastName = match.lastName;
                            var firstName = match.firstName;
                            var lastName$1 = Belt_Option.map(lastName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
                            return {
                                    accountId: pax.accountId,
                                    docFName: pax.docFName,
                                    docLName: pax.docLName,
                                    docGender: pax.docGender,
                                    docCitizenship: pax.docCitizenship,
                                    preferredFName: Caml_option.some(firstName),
                                    preferredLName: lastName,
                                    birthdate: pax.birthdate,
                                    preferredFullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(firstName) + (
                                      lastName$1 !== undefined ? " " + lastName$1 : ""
                                    ),
                                    loyaltyNumber: pax.loyaltyNumber,
                                    numLoyaltyVoyages: pax.numLoyaltyVoyages,
                                    loyaltyTier: pax.loyaltyTier,
                                    email: Caml_option.some(match.email),
                                    paxStatus: {
                                      TAG: "Draft",
                                      _0: "Invite"
                                    },
                                    idx: pax.idx,
                                    shouldAttachToIdx: undefined,
                                    paxPatchPerm: "None"
                                  };
                          })), (function (pax) {
                        var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachExistingLoyaltyPax, undefined), pax.idx);
                        if (match !== undefined) {
                          return {
                                  accountId: Caml_option.some(match.acctId),
                                  docFName: undefined,
                                  docLName: undefined,
                                  docGender: undefined,
                                  docCitizenship: undefined,
                                  preferredFName: undefined,
                                  preferredLName: undefined,
                                  birthdate: undefined,
                                  preferredFullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.protectedName),
                                  loyaltyNumber: Caml_option.some(match.loyalty),
                                  numLoyaltyVoyages: 0,
                                  loyaltyTier: undefined,
                                  email: match.loyEmail,
                                  paxStatus: {
                                    TAG: "Draft",
                                    _0: "Attach"
                                  },
                                  idx: pax.idx,
                                  shouldAttachToIdx: undefined,
                                  paxPatchPerm: "None"
                                };
                        } else {
                          return pax;
                        }
                      })), (function (pax) {
                    var accountId = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachExistingConnectedPax, undefined), pax.idx);
                    if (accountId === undefined) {
                      return pax;
                    }
                    if (connectedAccounts === undefined) {
                      return pax;
                    }
                    var accountId$1 = Caml_option.valFromOption(accountId);
                    var match = Belt_Array.get(Belt_Array.keep(connectedAccounts.nodes, (function (acct) {
                                return Caml_obj.equal(acct.accountId, accountId$1);
                              })), 0);
                    if (match !== undefined) {
                      return {
                              accountId: Caml_option.some(match.accountId),
                              docFName: match.legalDocumentFirstName,
                              docLName: match.legalDocumentLastName,
                              docGender: match.legalDocumentGender,
                              docCitizenship: match.legalDocumentCitizenship,
                              preferredFName: Caml_option.some(match.firstName),
                              preferredLName: match.lastName,
                              birthdate: match.legalDocumentBirthdate,
                              preferredFullName: match.fullDisplayName,
                              loyaltyNumber: Caml_option.some(match.loyaltyNumber),
                              numLoyaltyVoyages: Belt_Option.getWithDefault(match.numEligibleVoyages, 0) + Belt_Option.getWithDefault(match.numPendingVoyages, 0) | 0,
                              loyaltyTier: match.currentTierTitle,
                              email: match.email,
                              paxStatus: {
                                TAG: "Draft",
                                _0: "Attach"
                              },
                              idx: pax.idx,
                              shouldAttachToIdx: undefined,
                              paxPatchPerm: "Limited"
                            };
                    }
                    if (ownAccount === undefined) {
                      return pax;
                    }
                    var match$1 = Belt_Array.get(Belt_Array.keep(ownAccount.nodes, (function (acct) {
                                return Caml_obj.equal(acct.accountId, accountId$1);
                              })), 0);
                    if (match$1 !== undefined) {
                      return {
                              accountId: Caml_option.some(match$1.accountId),
                              docFName: match$1.legalDocumentFirstName,
                              docLName: match$1.legalDocumentLastName,
                              docGender: match$1.legalDocumentGender,
                              docCitizenship: match$1.legalDocumentCitizenship,
                              preferredFName: match$1.firstName,
                              preferredLName: match$1.lastName,
                              birthdate: match$1.legalDocumentBirthdate,
                              preferredFullName: getFullPreferredName(pax),
                              loyaltyNumber: Caml_option.some(match$1.loyaltyNumber),
                              numLoyaltyVoyages: Belt_Option.getWithDefault(match$1.numEligibleVoyages, 0) + Belt_Option.getWithDefault(match$1.numPendingVoyages, 0) | 0,
                              loyaltyTier: match$1.currentTierTitle,
                              email: match$1.email,
                              paxStatus: {
                                TAG: "Draft",
                                _0: "Replace"
                              },
                              idx: pax.idx,
                              shouldAttachToIdx: undefined,
                              paxPatchPerm: "Limited"
                            };
                    } else {
                      return pax;
                    }
                  })), (function (pax) {
                var match = Belt_MapInt.get(Belt_Option.getWithDefault(diff.attachNewConnectedPax, undefined), pax.idx);
                if (match === undefined) {
                  return pax;
                }
                var preferredLastName = match.preferredLastName;
                var preferredFirstName = match.preferredFirstName;
                var tmp;
                if (preferredFirstName !== undefined) {
                  var fName = Caml_option.valFromOption(preferredFirstName);
                  tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fName);
                } else {
                  tmp = preferredLastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(preferredLastName)) : undefined;
                }
                return {
                        accountId: pax.accountId,
                        docFName: Caml_option.some(match.docFirstName),
                        docLName: Caml_option.some(match.docLastName),
                        docGender: match.docGender,
                        docCitizenship: Caml_option.some(match.docCitizenship),
                        preferredFName: preferredFirstName,
                        preferredLName: preferredLastName,
                        birthdate: Caml_option.some(match.docBirthdate),
                        preferredFullName: tmp,
                        loyaltyNumber: pax.loyaltyNumber,
                        numLoyaltyVoyages: pax.numLoyaltyVoyages,
                        loyaltyTier: pax.loyaltyTier,
                        email: undefined,
                        paxStatus: {
                          TAG: "Draft",
                          _0: "Attach"
                        },
                        idx: pax.idx,
                        shouldAttachToIdx: undefined,
                        paxPatchPerm: "All"
                      };
              }));
}

function patchPaxes(paxes, diff) {
  return Belt_Array.map(paxes, (function (pax) {
                var reco = Belt_MapInt.get(Belt_Option.getWithDefault(diff.patchPax, undefined), pax.idx);
                if (reco !== undefined) {
                  return {
                          accountId: pax.accountId,
                          docFName: pax.docFName,
                          docLName: pax.docLName,
                          docGender: pax.docGender === reco.pDocGender ? pax.docGender : reco.pDocGender,
                          docCitizenship: pax.docCitizenship === reco.pDocCitizenship ? pax.docCitizenship : reco.pDocCitizenship,
                          preferredFName: pax.preferredFName,
                          preferredLName: pax.preferredLName,
                          birthdate: pax.birthdate,
                          preferredFullName: pax.preferredFullName,
                          loyaltyNumber: pax.loyaltyNumber,
                          numLoyaltyVoyages: pax.numLoyaltyVoyages,
                          loyaltyTier: pax.loyaltyTier,
                          email: pax.email,
                          paxStatus: pax.paxStatus,
                          idx: pax.idx,
                          shouldAttachToIdx: pax.shouldAttachToIdx,
                          paxPatchPerm: pax.paxPatchPerm
                        };
                } else {
                  return pax;
                }
              }));
}

function createTBAs(attachIndex, paxCount, numBerths) {
  var _i = attachIndex;
  var _paxCount = paxCount;
  var _acc = [];
  while(true) {
    var acc = _acc;
    var paxCount$1 = _paxCount;
    var i = _i;
    if (paxCount$1 >= numBerths) {
      return acc;
    }
    _acc = Belt_Array.concat(acc, [{
            accountId: undefined,
            docFName: undefined,
            docLName: undefined,
            docGender: undefined,
            docCitizenship: undefined,
            preferredFName: undefined,
            preferredLName: undefined,
            birthdate: undefined,
            preferredFullName: undefined,
            loyaltyNumber: undefined,
            numLoyaltyVoyages: 0,
            loyaltyTier: undefined,
            email: undefined,
            paxStatus: "TBA",
            idx: i,
            shouldAttachToIdx: attachIndex,
            paxPatchPerm: "None"
          }]);
    _paxCount = paxCount$1 + 1 | 0;
    _i = i + 1 | 0;
    continue ;
  };
}

function generatePaxList(paxes, diff, connectedAccounts, ownAccount, numBerths) {
  var nonTBAPaxes = replaceDropDetachOrReturn(paxify(paxes), diff, connectedAccounts, ownAccount);
  var attachIndex = Belt_Array.reduce(nonTBAPaxes, 0, (function (acc, pax) {
          return Math.max(acc, pax.idx);
        })) + 1 | 0;
  return patchPaxes(Belt_Array.concat(nonTBAPaxes, attachOrReturn(createTBAs(attachIndex, nonTBAPaxes.length, numBerths), diff, connectedAccounts, ownAccount)), diff);
}

var Pax_empty = {
  accountId: undefined,
  docFName: undefined,
  docLName: undefined,
  docGender: undefined,
  docCitizenship: undefined,
  preferredFName: undefined,
  preferredLName: undefined,
  birthdate: undefined,
  preferredFullName: undefined,
  loyaltyNumber: undefined,
  numLoyaltyVoyages: 0,
  loyaltyTier: undefined,
  email: undefined,
  paxStatus: "TBA",
  idx: 0,
  shouldAttachToIdx: undefined,
  paxPatchPerm: "None"
};

var Pax = {
  empty: Pax_empty,
  genderOptions: genderOptions,
  genderToText: genderToText,
  getPaxDisplayName: getPaxDisplayName,
  paxify: paxify,
  getPaxPatchFromPax: getPaxPatchFromPax,
  getConnectedPatchFromPax: getConnectedPatchFromPax,
  postMushIdxMap: postMushIdxMap,
  getFullPreferredName: getFullPreferredName,
  replaceDropDetachOrReturn: replaceDropDetachOrReturn,
  attachOrReturn: attachOrReturn,
  patchPaxes: patchPaxes,
  createTBAs: createTBAs,
  generatePaxList: generatePaxList
};

function addToDraft(diff, url) {
  var searchStr = BookingDiff$Thin.serialize(diff);
  var urlStr = "/" + Belt_List.toArray(url.path).join("/");
  var url$1 = searchStr === "" ? urlStr : urlStr + "?" + searchStr;
  RescriptReactRouter.push(url$1);
}

function makeBookingDiffInput(diff, postMushIdxMap) {
  var dropBerths = Belt_Option.map(diff.dropBerths, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        return b;
                      }));
        }));
  var detachPax = Belt_Option.map(diff.detachPax, (function (pax) {
          return Belt_Array.map(pax, (function (a) {
                        return a;
                      }));
        }));
  var intMapToOptionWrappedArray = function (innerMapFn, intMap, idxMap) {
    return Belt_Array.map(Belt_MapInt.toArray(intMap), (function (p) {
                  return innerMapFn(p, idxMap);
                }));
  };
  var mapPaxApplication = function (p, idxMap) {
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            accountId: p[1]
          };
  };
  var mapPaxPatch = function (p, param) {
    var reco = p[1];
    var updateOtherVoyages = reco.updateOtherVoyages;
    var docGender = Belt_Option.map(reco.pDocGender, (function (g) {
            return Belt_Option.getWithDefault(ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode(g), "X");
          }));
    var updateOtherVoyages$1 = updateOtherVoyages.length === 0 ? undefined : Belt_Array.map(updateOtherVoyages, (function (v) {
              return Caml_option.some(v);
            }));
    return {
            accountId: reco.accountId,
            docGender: docGender,
            docCitizenship: reco.pDocCitizenship,
            updateOtherVoyages: updateOtherVoyages$1,
            updateUserAccount: reco.updateUserAccount
          };
  };
  var mapPaxInvitation = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            invitationEmail: reco.email,
            preferredFirstName: reco.firstName,
            preferredLastName: reco.lastName
          };
  };
  var mapNewConnectedPax = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            custodianAccountId: reco.custodianAccountId,
            docFirstname: reco.docFirstName,
            docLastname: reco.docLastName,
            docGender: Belt_Option.getWithDefault(ConnectedAccountsPage_query_graphql$Thin.Utils.docGender_decode(reco.docGender), "X"),
            docCitizenship: reco.docCitizenship,
            docBirthdate: reco.docBirthdate,
            preferredFirstName: reco.preferredFirstName,
            preferredLastName: reco.preferredLastName
          };
  };
  var mapExistingLoyaltyPax = function (p, idxMap) {
    var reco = p[1];
    var idx = p[0];
    var idx$1 = idxMap !== undefined ? Belt_Option.getWithDefault(Belt_MapInt.get(Caml_option.valFromOption(idxMap), idx), idx) : idx;
    return {
            idx: idx$1,
            accountId: reco.acctId,
            loyaltyNumber: reco.loyalty
          };
  };
  var attachExistingConnectedPax = Belt_Option.map(diff.attachExistingConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxExistingConnected = Belt_Option.map(diff.replacePaxExistingConnected, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var revokePaxInvitation = Belt_Option.map(diff.revokePaxInvitation, (function (m) {
          return intMapToOptionWrappedArray(mapPaxApplication, m, undefined);
        }));
  var attachInvitedPax = Belt_Option.map(diff.attachInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxInvited = Belt_Option.map(diff.replaceInvitedPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxInvitation, m, undefined);
        }));
  var attachNewConnectedPax = Belt_Option.map(diff.attachNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, Caml_option.some(postMushIdxMap));
        }));
  var replaceNewConnectedPax = Belt_Option.map(diff.replaceNewConnectedPax, (function (m) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, m, undefined);
        }));
  var attachExistingLoyaltyPax = Belt_Option.map(diff.attachExistingLoyaltyPax, (function (m) {
          return intMapToOptionWrappedArray(mapExistingLoyaltyPax, m, Caml_option.some(postMushIdxMap));
        }));
  var replacePaxExistingLoyalty = Belt_Option.map(diff.replaceExistingLoyaltyPax, (function (m) {
          return intMapToOptionWrappedArray(mapExistingLoyaltyPax, m, undefined);
        }));
  var patchPax = Belt_Option.map(diff.patchPax, (function (m) {
          return intMapToOptionWrappedArray(mapPaxPatch, m, undefined);
        }));
  var updateBookingPreferences = Belt_Option.map(diff.updateBookingPreferences, (function (param) {
          var cabinLongitudePref = Belt_Option.map(param.cabinLongitudePref, (function (p) {
                  return Belt_Option.getWithDefault(BDPreferences_detail_graphql$Thin.Utils.longitudinality_decode(p), "FORE");
                }));
          var cabinElevatorPref = Belt_Option.map(param.cabinElevatorPref, (function (p) {
                  return Belt_Option.getWithDefault(BDPreferences_detail_graphql$Thin.Utils.elevatorProximity_decode(p), "FARELEVATORS");
                }));
          return {
                  bedsTogetherPref: param.bedsTogetherPref,
                  cabinLongitudePref: cabinLongitudePref,
                  cabinElevatorPref: cabinElevatorPref,
                  cabinPlacementRequest: param.cabinPlacementRequest,
                  earlyDinnerSeatingPref: param.earlyDinnerSeatingPref,
                  generalPref: param.generalPref
                };
        }));
  return {
          dropBerths: dropBerths,
          addBerths: diff.addBerths,
          detachPax: detachPax,
          attachExistingConnectedPax: attachExistingConnectedPax,
          attachExistingLoyaltyPax: attachExistingLoyaltyPax,
          attachInvitedPax: attachInvitedPax,
          attachNewConnectedPax: attachNewConnectedPax,
          replacePaxExistingConnected: replacePaxExistingConnected,
          replacePaxExistingLoyalty: replacePaxExistingLoyalty,
          replacePaxInvited: replacePaxInvited,
          replaceNewConnectedPax: replaceNewConnectedPax,
          revokePaxInvitation: revokePaxInvitation,
          changeCabinUnbooked: diff.changeCabinU,
          updateBookingPreferences: updateBookingPreferences,
          patchPax: patchPax
        };
}

function createPreviewCallback(bookingId, bookingVersionNumber, voyage, postMushIdxMap) {
  return function (diff) {
    return {
            bookingId: bookingId,
            bookingVersionNumber: bookingVersionNumber,
            voyage: voyage,
            thinDiff: makeBookingDiffInput(diff, postMushIdxMap)
          };
  };
}

var Preview = {
  makeBookingDiffInput: makeBookingDiffInput,
  createPreviewCallback: createPreviewCallback
};

exports.Pax = Pax;
exports.addToDraft = addToDraft;
exports.Preview = Preview;
/* BookingDiff-Thin Not a pure module */
