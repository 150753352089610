// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SignUp$Thin = require("../../common/mutations/SignUp.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var LoginSubmit$Thin = require("../../login/mutations/LoginSubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BCForm_query_graphql$Thin = require("../../../__generated__/BCForm_query_graphql.bs.js");
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");
var UserSignOnStatusQueryNew_graphql$Thin = require("../../../__generated__/UserSignOnStatusQueryNew_graphql.bs.js");

var convertFragment = BCForm_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BCForm_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BCForm_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BCForm_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = UserSignOnStatusQueryNew_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = UserSignOnStatusQueryNew_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BCForm_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = BCForm_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = BCForm_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BCForm$VerifyEmail(props) {
  var refetchAll = props.refetchAll;
  var match = HopperState$Util.Observable.FormField.useState(props.bc, SFState$Thin.email, undefined);
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "center",
              spacing: 1,
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: "neutral.200",
                borderRadius: "12px"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          pb: 1
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "h4",
                                children: "Verify Your Email"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  textAlign: "center"
                                },
                                textColor: "neutral.600",
                                children: Caml_option.some("We've sent a verification link to " + Belt_Option.getWithDefault(match[0], "your inbox") + ". Please click the link in the email to verify your account and return to this page.")
                              }),
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "fit-content",
                                  pt: 3
                                },
                                children: Caml_option.some(JsxRuntime.jsx(AppButton$Thin.make, {
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              refetchAll();
                                            }),
                                          size: "lg",
                                          children: "I did it!"
                                        }))
                              })
                        ]
                      }))
            });
}

var VerifyEmail = {
  make: BCForm$VerifyEmail
};

function BCForm(props) {
  var countryFrag = props.countryFrag;
  var setPageLevelError = props.setPageLevelError;
  var refetchCurrentUserInfo = props.refetchCurrentUserInfo;
  var urlState = props.urlState;
  var url = props.url;
  var bc = props.bc;
  var data = use(countryFrag);
  var onCabinHoldSuccess = function (expiry) {
    SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setCabinHold(urlState, expiry)), url);
  };
  var match = RequestCabinHoldByCategory$Thin.useMutation(props.flow, props.voyageSlug, setPageLevelError);
  var requestCabinHoldByCategory = match.requestCabinHoldByCategory;
  var match$1 = CurrentUser$Thin.Context.use();
  var refetchSignOnStatus = match$1.refetchSignOnStatus;
  var refetchAll = function () {
    refetchCurrentUserInfo();
    refetchSignOnStatus();
  };
  var match$2 = LoginSubmit$Thin.useMutationOnSignup(refetchAll);
  var match$3 = SignUp$Thin.useMutation(setPageLevelError, undefined, match$2.loginOnSignUp);
  var signUpError = match$3.error;
  React.useEffect((function () {
          var match = data.currentUserSignonStatus;
          var match$1 = urlState.cabinHold;
          if ((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "SIGNED_ON") {
            if (match$1 !== undefined) {
              
            } else {
              requestCabinHoldByCategory(urlState, onCabinHoldSuccess);
            }
          }
          
        }), [
        data.currentUserSignonStatus,
        urlState.cabinHold
      ]);
  var intervalRef = React.useRef(null);
  React.useEffect((function () {
          var interval = setInterval((function () {
                  var match = data.currentUserSignonStatus;
                  if (!(match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON")) {
                    return ;
                  }
                  switch (match) {
                    case "ANONYMOUS" :
                    case "VERIFY_EMAIL" :
                        return refetchAll();
                    default:
                      return ;
                  }
                }), 10000);
          var match = data.currentUserSignonStatus;
          if ((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "VERIFY_EMAIL") {
            intervalRef.current = interval;
          }
          return (function () {
                    var id = intervalRef.current;
                    if (!(id == null)) {
                      clearInterval(id);
                      return ;
                    }
                    
                  });
        }), [data.currentUserSignonStatus]);
  React.useEffect((function () {
          var match = data.currentUserSignonStatus;
          var exit = 0;
          if (!((match === "VERIFY_EMAIL" || match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "VERIFY_EMAIL")) {
            exit = 1;
          }
          if (exit === 1) {
            Belt_Option.map(Caml_option.nullable_to_opt(intervalRef.current), (function (id) {
                    clearInterval(id);
                  }));
          }
          
        }), [data.currentUserSignonStatus]);
  var match$4 = data.currentUserSignonStatus;
  if ((match$4 === "VERIFY_EMAIL" || match$4 === "INVITED" || match$4 === "PENDING_2FA" || match$4 === "CLEAR_JWT" || match$4 === "ANONYMOUS" || match$4 === "SIGNED_ON") && match$4 === "VERIFY_EMAIL") {
    return JsxRuntime.jsx(BCForm$VerifyEmail, {
                refetchAll: refetchAll,
                bc: bc
              });
  }
  var match$5 = data.currentUserSignonStatus;
  var tmp;
  tmp = (match$5 === "VERIFY_EMAIL" || match$5 === "INVITED" || match$5 === "PENDING_2FA" || match$5 === "CLEAR_JWT" || match$5 === "ANONYMOUS" || match$5 === "SIGNED_ON") && match$5 === "ANONYMOUS" ? JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            pb: 2
          },
          textColor: "neutral.600",
          children: "We're creating an account for you. Your information will only be used for reservation and customer service purposes."
        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {});
  return JsxRuntime.jsxs(PaxFrame$Thin.make, {
              title: "Billing Contact",
              required: true,
              variant: "Grey",
              children: [
                tmp,
                JsxRuntime.jsx(PassengerForms$Thin.SignUp.make, {
                      fragmentRefs: countryFrag,
                      showTitleSection: false,
                      pax: bc,
                      signedUp: data.currentUserSignonStatus === "SIGNED_ON",
                      disabled: data.currentUserSignonStatus === "SIGNED_ON",
                      noHelperText: true,
                      loading: match$3.isMutating || match$2.isMutating,
                      signUpError: signUpError,
                      setLoginError: match$2.setError,
                      setSignUpError: match$3.setError,
                      signUp: match$3.signUp
                    }),
                signUpError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: signUpError,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = BCForm;

exports.QueryFragment = QueryFragment;
exports.VerifyEmail = VerifyEmail;
exports.make = make;
/* react Not a pure module */
