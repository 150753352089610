// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var DateFns$Util = require("../DateFns.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function fromString(a) {
  return {
          TAG: "Ok",
          _0: a
        };
}

function toString(a) {
  return a;
}

var $$String = {
  fromString: fromString,
  toString: toString
};

var QueryVar = {
  $$String: $$String
};

function MakeFromMetaStruct(FromStruct) {
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, FromStruct.struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(FromStruct.primitiveToJson(prim));
  };
  var toPrimitive = FromStruct.toPrimitive;
  var toEl = function (t) {
    return FromStruct.primitiveToEl(toPrimitive(t));
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, FromStruct.struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, FromStruct.struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, FromStruct.struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(FromStruct.primitiveToJson(prim));
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var fromString = FromStruct.QueryVar.fromString;
  var toString = FromStruct.QueryVar.toString;
  var QueryVar = {
    fromString: fromString,
    toString: toString
  };
  var cmp = function (l, r) {
    return Caml_obj.compare(FromStruct.toPrimitive(l), FromStruct.toPrimitive(r));
  };
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: FromStruct.struct,
          meta: FromStruct.meta,
          Exn: Exn,
          QueryVar: QueryVar,
          Comparable: Comparable
        };
}

function MakeFromStruct(FromStruct) {
  var toPrimitive = FromStruct.toPrimitive;
  var primitiveToEl = FromStruct.primitiveToEl;
  var primitiveToJson = FromStruct.primitiveToJson;
  var struct = FromStruct.struct;
  var QueryVar = FromStruct.QueryVar;
  var meta = function (t) {
    return FromStruct.toPrimitive(t);
  };
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(primitiveToJson(prim));
  };
  var toEl = function (t) {
    return primitiveToEl(toPrimitive(t));
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(primitiveToJson(prim));
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var QueryVar$1 = QueryVar;
  var cmp = function (l, r) {
    return Caml_obj.compare(toPrimitive(l), toPrimitive(r));
  };
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: struct,
          meta: meta,
          Exn: Exn,
          QueryVar: QueryVar$1,
          Comparable: Comparable
        };
}

function MakeFromMetaStruct$1(FromStruct) {
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, FromStruct.struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(FromStruct.primitiveToJson(prim));
  };
  var toPrimitive = FromStruct.toPrimitive;
  var toEl = function (t) {
    return FromStruct.primitiveToEl(toPrimitive(t));
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, FromStruct.struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, FromStruct.struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, FromStruct.struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(FromStruct.primitiveToJson(prim));
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var fromString = FromStruct.QueryVar.fromString;
  var toString = FromStruct.QueryVar.toString;
  var QueryVar = {
    fromString: fromString,
    toString: toString
  };
  var cmp = function (l, r) {
    return Caml_obj.compare(FromStruct.toPrimitive(l), FromStruct.toPrimitive(r));
  };
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: FromStruct.struct,
          meta: FromStruct.meta,
          Exn: Exn,
          QueryVar: QueryVar,
          Comparable: Comparable,
          toString: toPrimitive
        };
}

function MakeFromStruct$1(FromStruct) {
  var toPrimitive = FromStruct.toPrimitive;
  var primitiveToEl = FromStruct.primitiveToEl;
  var primitiveToJson = FromStruct.primitiveToJson;
  var struct = FromStruct.struct;
  var QueryVar = FromStruct.QueryVar;
  var meta = function (t) {
    return FromStruct.toPrimitive(t);
  };
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(primitiveToJson(prim));
  };
  var toEl = function (t) {
    return primitiveToEl(toPrimitive(t));
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(primitiveToJson(prim));
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var QueryVar$1 = QueryVar;
  var cmp = function (l, r) {
    return Caml_obj.compare(toPrimitive(l), toPrimitive(r));
  };
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: struct,
          meta: meta,
          Exn: Exn,
          QueryVar: QueryVar$1,
          Comparable: Comparable,
          toString: toPrimitive
        };
}

var Stringable = {
  MakeFromMetaStruct: MakeFromMetaStruct$1,
  MakeFromStruct: MakeFromStruct$1
};

function MakeWithRefinements(RefineDate) {
  var dateTimeSchema = S$RescriptSchema.refine(S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 8, undefined), (function (s) {
          return function (v) {
            var match = DateFns$Util.parseISO(v);
            if (match.TAG === "Ok") {
              return ;
            } else {
              return s.fail("Not an ISO 8601 compatible string", undefined);
            }
          };
        }));
  var toPrimitive = function (a) {
    return a;
  };
  var struct = S$RescriptSchema.refine(dateTimeSchema, (function (s) {
          return function (v) {
            var e = RefineDate.check(v);
            if (e.TAG === "Ok") {
              return ;
            } else {
              return s.fail(e._0, undefined);
            }
          };
        }));
  var meta = function (t) {
    return t;
  };
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(prim);
  };
  var toEl = function (t) {
    return t;
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(prim);
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var QueryVar = {
    fromString: fromString,
    toString: toString
  };
  var cmp = Caml_obj.compare;
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var toDate = function (v) {
    return Belt_Result.getExn(DateFns$Util.parseISO(v));
  };
  var format = function (v, fstr) {
    return DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(v)), fstr);
  };
  var isBefore = function (l, r) {
    return DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(l)), Belt_Result.getExn(DateFns$Util.parseISO(r)));
  };
  var isInThePast = function (v) {
    var now = new Date();
    return DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(v)), now);
  };
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: struct,
          meta: meta,
          Exn: Exn,
          QueryVar: QueryVar,
          Comparable: Comparable,
          toDate: toDate,
          toString: toPrimitive,
          format: format,
          isBefore: isBefore,
          isInThePast: isInThePast
        };
}

var dateTimeSchema = S$RescriptSchema.refine(S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 8, undefined), (function (s) {
        return function (v) {
          var match = DateFns$Util.parseISO(v);
          if (match.TAG === "Ok") {
            return ;
          } else {
            return s.fail("Not an ISO 8601 compatible string", undefined);
          }
        };
      }));

function toPrimitive(a) {
  return a;
}

var struct = S$RescriptSchema.refine(dateTimeSchema, (function (s) {
        return function (v) {
          var e = {
            TAG: "Ok",
            _0: v
          };
          if (e.TAG === "Ok") {
            return ;
          } else {
            return s.fail(e._0, undefined);
          }
        };
      }));

function meta(t) {
  return t;
}

function fromJson(json) {
  var a = S$RescriptSchema.parseWith(json, struct);
  if (a.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: a._0
          };
  } else {
    return {
            TAG: "Error",
            _0: S$RescriptSchema.$$Error.reason(a._0)
          };
  }
}

function fromPrimitive(prim) {
  return fromJson(prim);
}

function toEl(t) {
  return t;
}

function toJson(t) {
  return S$RescriptSchema.serializeOrRaiseWith(t, struct);
}

function parse(json) {
  var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
  var res = S$RescriptSchema.parseWith(json, struct);
  if (res.TAG === "Ok") {
    return res._0;
  }
  console.log(res._0);
  console.log(json);
  throw {
        RE_EXN_ID: CustomScalarParse,
        _1: "Can't parse custom scalar",
        Error: new Error()
      };
}

function serialize(t) {
  return S$RescriptSchema.serializeOrRaiseWith(t, struct);
}

function fromPrimitive$1(prim) {
  return parse(prim);
}

var Exn = {
  parse: parse,
  serialize: serialize,
  fromPrimitive: fromPrimitive$1
};

var QueryVar$1 = {
  fromString: fromString,
  toString: toString
};

var cmp = Caml_obj.compare;

var Comparable = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function toDate(v) {
  return Belt_Result.getExn(DateFns$Util.parseISO(v));
}

function format(v, fstr) {
  return DateFns.format(Belt_Result.getExn(DateFns$Util.parseISO(v)), fstr);
}

function isBefore(l, r) {
  return DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(l)), Belt_Result.getExn(DateFns$Util.parseISO(r)));
}

function isInThePast(v) {
  var now = new Date();
  return DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(v)), now);
}

var Plain = {
  fromPrimitive: fromPrimitive,
  toPrimitive: toPrimitive,
  toJson: toJson,
  fromJson: fromJson,
  toEl: toEl,
  schema: struct,
  meta: meta,
  Exn: Exn,
  QueryVar: QueryVar$1,
  Comparable: Comparable,
  toDate: toDate,
  toString: toPrimitive,
  format: format,
  isBefore: isBefore,
  isInThePast: isInThePast
};

var Dateable = {
  MakeWithRefinements: MakeWithRefinements,
  Plain: Plain
};

function MakeWithRefinements$1(RefineDecimable) {
  var toPrimitive = function (a) {
    return a;
  };
  var struct = S$RescriptSchema.refine(S$RescriptSchema.string, (function (s) {
          return function (v) {
            var e = RefineDecimable.check(v);
            if (e.TAG === "Ok") {
              return ;
            } else {
              return s.fail(e._0, undefined);
            }
          };
        }));
  var meta = function (t) {
    return t;
  };
  var fromJson = function (json) {
    var a = S$RescriptSchema.parseWith(json, struct);
    if (a.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: a._0
            };
    } else {
      return {
              TAG: "Error",
              _0: S$RescriptSchema.$$Error.reason(a._0)
            };
    }
  };
  var fromPrimitive = function (prim) {
    return fromJson(prim);
  };
  var toEl = function (t) {
    return t;
  };
  var toJson = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var parse = function (json) {
    var CustomScalarParse = /* @__PURE__ */Caml_exceptions.create("CustomScalarParse");
    var res = S$RescriptSchema.parseWith(json, struct);
    if (res.TAG === "Ok") {
      return res._0;
    }
    console.log(res._0);
    console.log(json);
    throw {
          RE_EXN_ID: CustomScalarParse,
          _1: "Can't parse custom scalar",
          Error: new Error()
        };
  };
  var serialize = function (t) {
    return S$RescriptSchema.serializeOrRaiseWith(t, struct);
  };
  var fromPrimitive$1 = function (prim) {
    return parse(prim);
  };
  var Exn = {
    parse: parse,
    serialize: serialize,
    fromPrimitive: fromPrimitive$1
  };
  var QueryVar = {
    fromString: fromString,
    toString: toString
  };
  var cmp = Caml_obj.compare;
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var toInt = function (s) {
    return Belt_Result.getExn(RefineDecimable.check(s));
  };
  var toFloat = function (s) {
    return RefineDecimable.toFloat(Belt_Result.getExn(RefineDecimable.check(s)));
  };
  var toString$1 = function (t) {
    return t;
  };
  return {
          fromPrimitive: fromPrimitive,
          toPrimitive: toPrimitive,
          toJson: toJson,
          fromJson: fromJson,
          toEl: toEl,
          schema: struct,
          meta: meta,
          Exn: Exn,
          QueryVar: QueryVar,
          Comparable: Comparable,
          toInt: toInt,
          toFloat: toFloat,
          toString: toString$1
        };
}

var Decimable = {
  MakeWithRefinements: MakeWithRefinements$1
};

exports.QueryVar = QueryVar;
exports.MakeFromMetaStruct = MakeFromMetaStruct;
exports.MakeFromStruct = MakeFromStruct;
exports.Stringable = Stringable;
exports.Dateable = Dateable;
exports.Decimable = Decimable;
/* dateTimeSchema Not a pure module */
