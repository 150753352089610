// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var Box = require("@mui/joy/Box").default;
var AppLink$Thin = require("../components/common/atoms/AppLink.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var JoCoLoGo$Thin = require("../assets/JoCoLoGo.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../components/common/atoms/AppButton.bs.js");
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var LoginSubmit$Thin = require("../components/login/mutations/LoginSubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;

function LoginPage(props) {
  var match = CurrentUser$Thin.Context.use();
  var match$1 = LoginSubmit$Thin.useMutation(match.refetchSignOnStatus, undefined, undefined);
  var error = match$1.error;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  var setEmail = match$1.setEmail;
  var email = match$1.email;
  return JsxRuntime.jsxs(PageFrame$Thin.make, {
              children: [
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(JoCoLoGo$Thin.make, {})),
                      sx: {
                        width: "10rem",
                        mb: "1rem"
                      }
                    }),
                JsxRuntime.jsx(AuthPanel$Thin.make, {
                      heading: Txt$Thin.Login.heading.value,
                      subHeadComponent: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                textColor: "text.tertiary",
                                children: Caml_option.some(Merge$Util.render(Txt$Thin.Login.subHeading, SysCtx$Thin.vars))
                              })),
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            component: "form",
                            spacing: 2,
                            sx: {
                              width: "100%"
                            },
                            onSubmit: match$1.onSubmit,
                            children: [
                              JsxRuntime.jsx(FormControl, {
                                    error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                    children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                              type_: "email",
                                              size: "lg",
                                              fullWidth: true,
                                              placeholder: Txt$Thin.Login.emailPlaceholder.value,
                                              value: email,
                                              onChange: (function (e) {
                                                  var val = e.currentTarget.value;
                                                  setEmail(function (param) {
                                                        return val;
                                                      });
                                                }),
                                              autoComplete: "on"
                                            }))
                                  }),
                              JsxRuntime.jsx(FormControl, {
                                    children: Caml_option.some(JsxRuntime.jsx(PasswordInput$Thin.make, {
                                              fullWidth: true,
                                              size: "lg",
                                              placeholder: Txt$Thin.Login.passwordPlaceholder.value,
                                              value: password,
                                              onChange: (function (e) {
                                                  var val = e.currentTarget.value;
                                                  setPassword(function (param) {
                                                        return val;
                                                      });
                                                }),
                                              autoComplete: "current-password"
                                            }))
                                  }),
                              JsxRuntime.jsx(Box, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              children: Caml_option.some(JsxRuntime.jsx(AppLink$Thin.make, {
                                                        to: "/reset-password",
                                                        children: Txt$Thin.Login.forgotPassword.value
                                                      }))
                                            })),
                                    sx: {
                                      alignSelf: "flex-start",
                                      paddingTop: 0
                                    }
                                  }),
                              JsxRuntime.jsx(AppButton$Thin.make, {
                                    fullWidth: true,
                                    loading: match$1.isMutating,
                                    type_: "submit",
                                    size: "lg",
                                    disabled: password === "" || Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                    children: Caml_option.some(Txt$Thin.Login.login.value)
                                  }),
                              error !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                      message: error,
                                      type_: "error"
                                    }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                            ]
                          })
                    })
              ],
              auth: true
            });
}

var make = LoginPage;

exports.make = make;
/* Joy-Util Not a pure module */
