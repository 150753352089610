// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../Common.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var PassengerState$Thin = require("../passengers/PassengerState.bs.js");
var SalesByCategoryPage_query_graphql$Thin = require("../../__generated__/SalesByCategoryPage_query_graphql.bs.js");

var NoAccountFound = /* @__PURE__ */Caml_exceptions.create("MyAccountState-Thin.NoAccountFound");

var currentPassword = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.currentPassword;
        }),
      write: (function (pw, currentPassword) {
          return {
                  currentPassword: currentPassword,
                  newPassword: pw.newPassword,
                  confirmPassword: pw.confirmPassword
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), S$RescriptSchema.string, "", undefined);

var newPassword = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.newPassword;
        }),
      write: (function (pw, newPassword) {
          return {
                  currentPassword: pw.currentPassword,
                  newPassword: newPassword,
                  confirmPassword: pw.confirmPassword
                };
        })
    }, (function (v) {
        if (Common$Thin.isPasswordValid(v)) {
          return {
                  TAG: "Ok",
                  _0: v
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Password must be at least 10 characters"
                };
        }
      }), (function (v) {
        return v;
      }), S$RescriptSchema.string, "", undefined);

var confirmPassword = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.confirmPassword;
        }),
      write: (function (pw, confirmPassword) {
          return {
                  currentPassword: pw.currentPassword,
                  newPassword: pw.newPassword,
                  confirmPassword: confirmPassword
                };
        })
    }, (function (v) {
        if (Common$Thin.isPasswordValid(v)) {
          return {
                  TAG: "Ok",
                  _0: v
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Password must be at least 10 characters"
                };
        }
      }), (function (v) {
        return v;
      }), S$RescriptSchema.string, "", undefined);

var emailChange = HopperState$Util.Observable.FormField.make({
      read: (function (param) {
          return param.emailChange;
        }),
      write: (function (model, emailChange) {
          return {
                  account: model.account,
                  pw: model.pw,
                  emailChange: emailChange
                };
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, S$RescriptSchema.string, "", undefined);

var blankPw_currentPassword = currentPassword.blankStore;

var blankPw_newPassword = newPassword.blankStore;

var blankPw_confirmPassword = confirmPassword.blankStore;

var blankPw = {
  currentPassword: blankPw_currentPassword,
  newPassword: blankPw_newPassword,
  confirmPassword: blankPw_confirmPassword
};

function blankModel() {
  return {
          account: PassengerState$Thin.blankPax(1),
          pw: blankPw,
          emailChange: emailChange.blankStore
        };
}

function pwView(model) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (param) {
                  return param.pw;
                }),
              write: (function (model, pw) {
                  return {
                          account: model.account,
                          pw: pw,
                          emailChange: model.emailChange
                        };
                })
            }, undefined);
}

function accountView(model) {
  return HopperState$Util.Observable.makeView(model, {
              read: (function (param) {
                  return param.account;
                }),
              write: (function (model, account) {
                  return {
                          account: account,
                          pw: model.pw,
                          emailChange: model.emailChange
                        };
                })
            }, undefined);
}

function prefillFromCurrentUserProfile(model, prof) {
  var newrecord = Caml_obj.obj_dup(PassengerState$Thin.blankPax(1));
  var account = PassengerState$Thin.halMarinerNumber.setFromValid(PassengerState$Thin.loyaltyTier.setFromValid(PassengerState$Thin.loyalty.setFromValid(PassengerState$Thin.acquisitionSlug.setFromValid(PassengerState$Thin.email.setFromValid(PassengerState$Thin.residenceCountry.setFromValid(PassengerState$Thin.postalCode.setFromValid(PassengerState$Thin.phoneCountryCode.setFromValid(PassengerState$Thin.phone.setFromValid(PassengerState$Thin.docGender.setFromValid(PassengerState$Thin.docCitizenship.setFromValid(PassengerState$Thin.docBirthdate.setFromValid(PassengerState$Thin.docLastName.setFromValid(PassengerState$Thin.docFirstName.setFromValid(PassengerState$Thin.preferredLastName.setFromValid(PassengerState$Thin.preferredFirstName.setFromValid((newrecord.paxType = "ProvideInfo", newrecord.pendingVoyageSlugs = Belt_Array.keepMap(Belt_Option.getWithDefault(prof.pendingVoyageSlugs, []), (function (a) {
                                                                            return a;
                                                                          })), newrecord.accountId = Caml_option.some(prof.accountId), newrecord.isComplete = prof.travelDocComplete, newrecord), prof.firstName, "InitialValueAsPrefill"), prof.lastName, "InitialValueAsPrefill"), prof.legalDocumentFirstName, "InitialValueAsPrefill"), prof.legalDocumentLastName, "InitialValueAsPrefill"), prof.legalDocumentBirthdate, "InitialValueAsPrefill"), prof.legalDocumentCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prof.legalDocumentGender, SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode), "InitialValueAsPrefill"), Belt_Option.flatMap(prof.phone, (function (v) {
                                              return CS_Phone$Util.Phone.getNumber(CS_Phone$Util.Phone.toPrimitive(v));
                                            })), "InitialValueAsPrefill"), Belt_Option.getWithDefault(Belt_Option.flatMap(prof.phone, (function (p) {
                                              return CS_Phone$Util.Phone.meta(p).callingCode;
                                            })), PassengerState$Thin.phoneCountryCode.blankValue), "InitialValueAsPrefill"), prof.postalCode, "InitialValueAsPrefill"), prof.residenceCountry, "InitialValueAsPrefill"), prof.email, "InitialValueAsPrefill"), prof.custAcquisitionSlug, "InitialValueAsPrefill"), Caml_option.some(prof.loyaltyNumber), "InitialValueAsPrefill"), prof.currentTierTitle, "InitialValueAsPrefill"), prof.halMarinerNumber, "InitialValueAsPrefill");
  return {
          account: account,
          pw: model.pw,
          emailChange: model.emailChange
        };
}

function validateChangePassword(pw) {
  var confirmPassword = pw.confirmPassword;
  var newPassword = pw.newPassword;
  if (newPassword.value !== confirmPassword.value) {
    return {
            TAG: "Error",
            _0: "Passwords do not match!"
          };
  }
  var currentPassword = pw.currentPassword;
  if (currentPassword.value === newPassword.value) {
    return {
            TAG: "Error",
            _0: "New password must be different from current password!"
          };
  } else if (currentPassword.value === "") {
    return {
            TAG: "Error",
            _0: "Please enter your current password!"
          };
  } else if (newPassword.value === "") {
    return {
            TAG: "Error",
            _0: "Please enter a new password!"
          };
  } else if (confirmPassword.value === "") {
    return {
            TAG: "Error",
            _0: "Please confirm your new password!"
          };
  } else {
    return {
            TAG: "Ok",
            _0: {
              allegedCurrentPass: currentPassword.value,
              newPass: confirmPassword.value
            }
          };
  }
}

function validateChangeEmail(model) {
  var email = CS_Emails$Util.Email.fromPrimitive(model.emailChange.value);
  if (email.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              newEmail: email._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: email._0
          };
  }
}

exports.NoAccountFound = NoAccountFound;
exports.currentPassword = currentPassword;
exports.newPassword = newPassword;
exports.confirmPassword = confirmPassword;
exports.emailChange = emailChange;
exports.blankPw = blankPw;
exports.blankModel = blankModel;
exports.pwView = pwView;
exports.accountView = accountView;
exports.prefillFromCurrentUserProfile = prefillFromCurrentUserProfile;
exports.validateChangePassword = validateChangePassword;
exports.validateChangeEmail = validateChangeEmail;
/* currentPassword Not a pure module */
