// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var AppAlert$Thin = require("../components/common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../context/AppConfig.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var Button = require("@mui/joy/Button").default;
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JustAnError$Thin = require("./JustAnError.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var PassengerForms$Thin = require("../components/common/molecules/PassengerForms.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var GroupAdd = require("@mui/icons-material/GroupAdd").default;
var KeyOutlined = require("@mui/icons-material/KeyOutlined").default;
var ClaimNewPaxInvitationModel$Thin = require("../utils/accounts/ClaimNewPaxInvitationModel.bs.js");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;

function doNothingOnSubmit(e) {
  e.preventDefault();
}

function ClaimNewPaxInvitationPage$SignupError(props) {
  return HopperState$Util.Observable.useComputed(props.view, (function (param) {
                var claimProgress = param.claimProgress;
                if (typeof claimProgress !== "object" || claimProgress.TAG !== "Failure") {
                  return null;
                } else {
                  return JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: claimProgress._0,
                              type_: "error"
                            });
                }
              }), undefined, undefined);
}

var SignupError = {
  make: ClaimNewPaxInvitationPage$SignupError
};

function ClaimNewPaxInvitationPage$InvitationNotFound(props) {
  return JsxRuntime.jsx(JustAnError$Thin.make, {
              text: "This invitation does not exist, is expired, or is otherwise unable to be claimed (" + CS_UUID$Util.PlainUUID.toString(props.allegedToken) + ")."
            });
}

var InvitationNotFound = {
  make: ClaimNewPaxInvitationPage$InvitationNotFound
};

function ClaimNewPaxInvitationPage$ClaimWrapper(props) {
  var prefill = props.prefill;
  var match = AppConfig$Thin.Context.use();
  var v = Belt_Array.get(Belt_Array.keep(match.voyages.allVoyages, (function (v) {
              return Caml_obj.equal(v.voyageSlug, prefill.voyageSlug);
            })), 0);
  var voyage = v !== undefined ? v.voyageName : "a " + match.brandFamily.brandFamilyName + " voyage";
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              sx: {
                maxWidth: "900px"
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      startDecorator: Caml_option.some(JsxRuntime.jsx(PersonOutlined, {})),
                      level: "title-lg",
                      sx: {
                        maxWidth: "600px",
                        alignItems: "flex-start"
                      },
                      children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(prefill.preferredFirstName) + " (" + CS_Emails$Util.Email.toString(prefill.invitationEmail) + ") was invited to join " + CS_NonemptyStrings$Util.NonEmptyString.toString(prefill.invitedBy) + "'s booking on " + voyage + "!")
                    }),
                props.children
              ]
            });
}

var ClaimWrapper = {
  make: ClaimNewPaxInvitationPage$ClaimWrapper
};

function ClaimNewPaxInvitationPage$FormWrapper(props) {
  var headline = props.headline;
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.FormWrapper.make, {
                        startDecorator: null,
                        title: "",
                        titleSize: "md",
                        variant: "Gray",
                        showTitleSection: false,
                        children: JsxRuntime.jsxs(Stack, {
                              spacing: 2,
                              children: [
                                headline !== undefined ? JsxRuntime.jsx(Typography, {
                                        level: "title-lg",
                                        children: Caml_option.some(headline)
                                      }) : null,
                                props.children
                              ]
                            })
                      }))
            });
}

var FormWrapper = {
  make: ClaimNewPaxInvitationPage$FormWrapper
};

function ClaimNewPaxInvitationPage$SelectClaimMethod$Choices(props) {
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimWrapper, {
                    prefill: props.prefill,
                    children: JsxRuntime.jsx(ClaimNewPaxInvitationPage$FormWrapper, {
                          children: JsxRuntime.jsx(Stack, {
                                spacing: 2,
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(Belt_Array.mapWithIndex(props.choices, (function (idx, param) {
                                            var btnAction = param[2];
                                            var color = param[1];
                                            var desc = param[0];
                                            switch (btnAction.TAG) {
                                              case "RegularLink" :
                                                  var href = btnAction._0;
                                                  return JsxRuntime.jsx(Button, {
                                                              onClick: (function (e) {
                                                                  e.preventDefault();
                                                                  RescriptReactRouter.push(href);
                                                                }),
                                                              component: "a",
                                                              color: color,
                                                              href: href,
                                                              size: "lg",
                                                              sx: {
                                                                width: "100%",
                                                                paddingInline: "0"
                                                              },
                                                              children: Caml_option.some(desc)
                                                            });
                                              case "TargetBlank" :
                                                  return JsxRuntime.jsx(Button, {
                                                              target: "_blank",
                                                              component: "a",
                                                              color: color,
                                                              href: btnAction._0,
                                                              size: "lg",
                                                              sx: {
                                                                width: "100%",
                                                                paddingInline: "0"
                                                              },
                                                              children: Caml_option.some(desc)
                                                            });
                                              case "OnClick" :
                                                  var fx = btnAction._0;
                                                  return JsxRuntime.jsx(Button, {
                                                              onClick: (function (param) {
                                                                  fx();
                                                                }),
                                                              component: "a",
                                                              color: color,
                                                              size: "lg",
                                                              sx: {
                                                                width: "100%",
                                                                paddingInline: "0"
                                                              },
                                                              children: Caml_option.some(desc)
                                                            });
                                              
                                            }
                                          })))
                              }),
                          headline: props.headline
                        })
                  }),
              auth: true
            });
}

var Choices = {
  make: ClaimNewPaxInvitationPage$SelectClaimMethod$Choices
};

function ClaimNewPaxInvitationPage$SelectClaimMethod(props) {
  var allegedToken = props.allegedToken;
  var prefill = props.prefill;
  var match = CurrentUser$Thin.Context.use();
  var email = match.email;
  var refetchSignOnStatus = match.refetchSignOnStatus;
  var match$1 = AppConfig$Thin.Context.use();
  var logoutEffect = function (param) {
    Common$Thin.cookieLogout(Caml_option.some((function () {
                refetchSignOnStatus();
              })), undefined);
  };
  var tmp = match.signOnStatus;
  if (!(tmp === "VERIFY_EMAIL" || tmp === "INVITED" || tmp === "PENDING_2FA" || tmp === "CLEAR_JWT" || tmp === "ANONYMOUS" || tmp === "SIGNED_ON")) {
    return JsxRuntime.jsx(JustAnError$Thin.make, {
                text: "You are logged in but in a status that is beyond the ken of this humble page. We're as confused as you are. Sorry?"
              });
  }
  switch (tmp) {
    case "ANONYMOUS" :
        return JsxRuntime.jsx(ClaimNewPaxInvitationPage$SelectClaimMethod$Choices, {
                    prefill: prefill,
                    headline: "Claim your " + match$1.brandFamily.brandFamilyName + " invitation.",
                    choices: [
                      [
                        "Create a new account",
                        "primary",
                        {
                          TAG: "RegularLink",
                          _0: ClaimNewPaxInvitationModel$Thin.$$URL.toString([
                                allegedToken,
                                "ClaimAnonymously"
                              ])
                        }
                      ],
                      [
                        "Log in to an existing account",
                        "secondary",
                        {
                          TAG: "OnClick",
                          _0: (function () {
                              HopperURL$Thin.redirectToPath("/login", (function (prevGlobals) {
                                      return {
                                              dismissibleAlerts: prevGlobals.dismissibleAlerts,
                                              loginRedirect: ClaimNewPaxInvitationModel$Thin.$$URL.toString([
                                                    allegedToken,
                                                    "ClaimLoggedIn"
                                                  ]),
                                              token: prevGlobals.token,
                                              landing: prevGlobals.landing,
                                              expiryMillis: prevGlobals.expiryMillis
                                            };
                                    }), undefined);
                            })
                        }
                      ]
                    ]
                  });
    case "VERIFY_EMAIL" :
    case "SIGNED_ON" :
        break;
    default:
      return JsxRuntime.jsx(JustAnError$Thin.make, {
                  text: "You are logged in but in a status that is beyond the ken of this humble page. We're as confused as you are. Sorry?"
                });
  }
  if (match.canBookVoyage(prefill.voyageSlug)) {
    return JsxRuntime.jsx(ClaimNewPaxInvitationPage$SelectClaimMethod$Choices, {
                prefill: prefill,
                headline: "You are logged in as " + email + ".",
                choices: [
                  [
                    "Claim invitation as " + email,
                    "secondary",
                    {
                      TAG: "RegularLink",
                      _0: ClaimNewPaxInvitationModel$Thin.$$URL.toString([
                            allegedToken,
                            "ClaimLoggedIn"
                          ])
                    }
                  ],
                  [
                    "Log me out",
                    "primary",
                    {
                      TAG: "OnClick",
                      _0: logoutEffect
                    }
                  ]
                ]
              });
  } else {
    return JsxRuntime.jsx(ClaimNewPaxInvitationPage$SelectClaimMethod$Choices, {
                prefill: prefill,
                headline: "You are logged in as " + email + ". This account can't accept the invitation.",
                choices: [
                  [
                    "Contact support",
                    "secondary",
                    {
                      TAG: "TargetBlank",
                      _0: "mailto:" + SysCtx$Thin.vars.mailtoEmail + "?subject=Unable to accept invitation to " + CS_Slugs$Util.VoyageSlug.toString(prefill.voyageSlug) + " Booking from " + CS_NonemptyStrings$Util.NonEmptyString.toString(prefill.invitedBy) + " (" + email + ")"
                    }
                  ],
                  [
                    "Log me out",
                    "primary",
                    {
                      TAG: "OnClick",
                      _0: logoutEffect
                    }
                  ]
                ]
              });
  }
}

var SelectClaimMethod = {
  Choices: Choices,
  make: ClaimNewPaxInvitationPage$SelectClaimMethod
};

function ClaimNewPaxInvitationPage$ClaimAnonymously$SubmitStack(props) {
  var allegedToken = props.allegedToken;
  var view = props.view;
  var validated = HopperState$Util.Observable.useComputed(view, (function (m) {
          return ClaimNewPaxInvitationModel$Thin.validateClaimAnonymouslyInput(m, allegedToken);
        }), undefined, undefined);
  var isDirty = HopperState$Util.Observable.useComputed(view, ClaimNewPaxInvitationModel$Thin.checkIsDirty, undefined, undefined);
  var loading = HopperState$Util.Observable.useComputed(view, (function (model) {
          var match = model.claimProgress;
          if (typeof match !== "object" && match === "Loading") {
            return true;
          } else {
            return false;
          }
        }), undefined, undefined);
  var mutation = RemoteData$Util.Relay.useMutationWithNotify(ClaimNewPaxInvitationModel$Thin.Mutation.ClaimAnonymously.use, (function (response) {
          var match = response.claimNewPassengerInvitationAnonymously;
          if (match === undefined) {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
          var match$1 = match.claimResult;
          if (match$1 !== undefined) {
            return {
                    TAG: "Failure",
                    _0: ClaimNewPaxInvitationModel$Thin.describeComboError(match$1.signUpErr, match$1.bookingUpdateError)
                  };
          } else {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
        }), (function (claimProgress) {
          HopperState$Util.Observable.notify(view, (function (model) {
                  return {
                          claimProgress: claimProgress,
                          password: model.password,
                          preferredFirstName: model.preferredFirstName,
                          preferredLastName: model.preferredLastName,
                          docFirstName: model.docFirstName,
                          docLastName: model.docLastName,
                          docFirstSameAsPreferredFirst: model.docFirstSameAsPreferredFirst,
                          docLastSameAsPreferredLast: model.docLastSameAsPreferredLast,
                          docBirthdate: model.docBirthdate,
                          docCitizenship: model.docCitizenship,
                          docGender: model.docGender,
                          phone: model.phone,
                          phoneCountryCode: model.phoneCountryCode,
                          postalCode: model.postalCode,
                          residenceCountry: model.residenceCountry,
                          email: model.email,
                          acquisitionSlug: model.acquisitionSlug,
                          halMarinerNumber: model.halMarinerNumber
                        };
                }));
        }));
  var match;
  if (validated.TAG === "Ok") {
    var input = validated._0;
    match = [
      "Join Booking",
      "success",
      JsxRuntime.jsx(GroupAdd, {}),
      (function (param) {
          mutation({
                input: input
              }, undefined, undefined, undefined, undefined);
        }),
      undefined
    ];
  } else {
    var tmp;
    if (isDirty) {
      switch (validated._0) {
        case "PersonalFieldsIncomplete" :
            tmp = "Complete all the elements in the Personal Fields section to complete your signup";
            break;
        case "TravelDocFieldsIncomplete" :
            tmp = "Complete all the elements in the Travel Documentation section to complete your signup";
            break;
        case "AccountCredentialsIncomplete" :
            tmp = "Fill in your email and password in order to complete your signup";
            break;
        
      }
    } else {
      tmp = "Your submission is incomplete";
    }
    match = [
      "Show Errors",
      "warning",
      JsxRuntime.jsx(WarningAmber, {}),
      (function (param) {
          HopperState$Util.Observable.notify(view, ClaimNewPaxInvitationModel$Thin.forceShowErrors);
        }),
      tmp
    ];
  }
  var btnColor = match[1];
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                Belt_Option.mapWithDefault(match[4], null, (function (expl) {
                        return JsxRuntime.jsx(Typography, {
                                    sx: {
                                      color: "danger.400"
                                    },
                                    children: Caml_option.some(expl)
                                  });
                      })),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        width: "fit-content"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                loading: loading,
                                type: "submit",
                                onClick: match[3],
                                startDecorator: Caml_option.some(match[2]),
                                loadingPosition: "end",
                                variant: btnColor === "warning" ? "outlined" : "solid",
                                color: btnColor,
                                size: "lg",
                                children: Caml_option.some(match[0])
                              }))
                    })
              ]
            });
}

var SubmitStack = {
  make: ClaimNewPaxInvitationPage$ClaimAnonymously$SubmitStack
};

function ClaimNewPaxInvitationPage$ClaimAnonymously$Passwords(props) {
  var view = props.view;
  var passwordsDoNotMatch = HopperState$Util.Observable.useComputed(view, (function (m) {
          return m.password.newPassword.value !== m.password.confirmNewPassword.value;
        }), undefined, undefined);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: ClaimNewPaxInvitationModel$Thin.newPassword,
                                children: (function (param) {
                                    var error = param.error;
                                    var set = param.set;
                                    var wasTouched = param.wasTouched;
                                    return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                label: "Password",
                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                errorText: param.errorText,
                                                error: error,
                                                children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                      placeholder: "Password",
                                                      required: true,
                                                      error: error && wasTouched,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        }),
                                                      autoComplete: "new-password"
                                                    }),
                                                required: true,
                                                wasTouched: wasTouched
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: ClaimNewPaxInvitationModel$Thin.confirmNewPassword,
                                children: (function (param) {
                                    var error = param.error;
                                    var set = param.set;
                                    var wasTouched = param.wasTouched;
                                    var errorText = passwordsDoNotMatch ? "Passwords do not match" : param.errorText;
                                    return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                label: "Confirm Password",
                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                errorText: errorText,
                                                error: error,
                                                children: JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                      placeholder: "Confirm Password",
                                                      required: true,
                                                      error: error && wasTouched,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        }),
                                                      autoComplete: "new-password"
                                                    }),
                                                required: true,
                                                wasTouched: wasTouched
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var Passwords = {
  make: ClaimNewPaxInvitationPage$ClaimAnonymously$Passwords
};

function ClaimNewPaxInvitationPage$ClaimAnonymously(props) {
  var prefill = props.prefill;
  var view = props.view;
  React.useEffect((function () {
          ClaimNewPaxInvitationModel$Thin.prefill(view, prefill);
        }), [Belt_Option.getWithDefault(JSON.stringify(prefill), "")]);
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimWrapper, {
                    prefill: prefill,
                    children: JsxRuntime.jsxs(ClaimNewPaxInvitationPage$FormWrapper, {
                          children: [
                            JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 3,
                                  onSubmit: doNothingOnSubmit,
                                  children: [
                                    JsxRuntime.jsxs(Stack, {
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                                                  level: "h4",
                                                  component: "h2",
                                                  sx: {
                                                    mb: 2
                                                  },
                                                  children: "Account Details"
                                                }),
                                            JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                                  children: [
                                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                          children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                                    view: {
                                                                      TAG: "Direct",
                                                                      _0: view
                                                                    },
                                                                    input: ClaimNewPaxInvitationModel$Thin.email,
                                                                    map: [
                                                                      (function (o) {
                                                                          return Belt_Option.getWithDefault(o, "");
                                                                        }),
                                                                      (function (s) {
                                                                          if (s === "") {
                                                                            return ;
                                                                          } else {
                                                                            return s;
                                                                          }
                                                                        })
                                                                    ],
                                                                    children: (function (param) {
                                                                        var set = param.set;
                                                                        return JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                                                    label: Txt$Thin.BookingDetails.addPassDetailsEmail.value,
                                                                                    errorText: param.errorText,
                                                                                    error: param.error,
                                                                                    children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                                          type_: "text",
                                                                                          placeholder: Txt$Thin.BookingDetails.addPassDetailsEmail.value,
                                                                                          variant: "plain",
                                                                                          value: param.value,
                                                                                          onChange: (function (e) {
                                                                                              set(function (param) {
                                                                                                    return e.target.value;
                                                                                                  });
                                                                                            })
                                                                                        }),
                                                                                    required: true,
                                                                                    wasTouched: param.wasTouched
                                                                                  });
                                                                      })
                                                                  })),
                                                          xs: 12,
                                                          md: 6
                                                        }),
                                                    JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                          sx: {
                                                            display: {
                                                              xs: "none",
                                                              md: "block"
                                                            }
                                                          },
                                                          md: 6
                                                        }),
                                                    JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimAnonymously$Passwords, {
                                                          view: view
                                                        })
                                                  ],
                                                  container: true,
                                                  spacing: {
                                                    TAG: "Fixed",
                                                    _0: 1
                                                  },
                                                  rowSpacing: 2,
                                                  columnSpacing: 2
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsxs(Stack, {
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(PersonOutlined, {})),
                                                  level: "h4",
                                                  component: "h2",
                                                  sx: {
                                                    mb: 2
                                                  },
                                                  children: "Personal Information"
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.PersonalFields.make, {
                                                            interface: PassengerForms$Thin.PersonalFields.ClaimNewPaxInvitation.$$interface(view),
                                                            variant: "Gray",
                                                            disabled: false,
                                                            trackAcquisition: true,
                                                            requireNames: true
                                                          })),
                                                  container: true,
                                                  spacing: {
                                                    TAG: "Fixed",
                                                    _0: 1
                                                  },
                                                  rowSpacing: 2,
                                                  columnSpacing: 2
                                                })
                                          ]
                                        })
                                  ]
                                }),
                            JsxRuntime.jsx(Stack, {
                                  spacing: 2,
                                  sx: {
                                    py: 2
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                                            interface: PassengerForms$Thin.Travel.ClaimNewPaxInvitation.$$interface(view),
                                            subtitle: "Information below must exactly match your travel document (e.g. passport). We will address you by your preferred name (above) whenever possible.",
                                            disabled: false,
                                            restrictedEdit: "Never",
                                            spacing: 2
                                          }))
                                }),
                            JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimAnonymously$SubmitStack, {
                                  view: view,
                                  allegedToken: props.allegedToken
                                }),
                            JsxRuntime.jsx(ClaimNewPaxInvitationPage$SignupError, {
                                  view: view
                                })
                          ]
                        })
                  }),
              auth: true
            });
}

var ClaimAnonymously = {
  SubmitStack: SubmitStack,
  Passwords: Passwords,
  make: ClaimNewPaxInvitationPage$ClaimAnonymously
};

function ClaimNewPaxInvitationPage$ClaimLoggedIn$SubmitStack(props) {
  var allegedToken = props.allegedToken;
  var view = props.view;
  var validated = HopperState$Util.Observable.useComputed(view, (function (m) {
          return ClaimNewPaxInvitationModel$Thin.validateClaimLoggedInInput(m, allegedToken);
        }), undefined, undefined);
  var isDirty = HopperState$Util.Observable.useComputed(view, ClaimNewPaxInvitationModel$Thin.checkIsDirty, undefined, undefined);
  var loading = HopperState$Util.Observable.useComputed(view, (function (model) {
          var match = model.claimProgress;
          if (typeof match !== "object" && match === "Loading") {
            return true;
          } else {
            return false;
          }
        }), undefined, undefined);
  var mutation = RemoteData$Util.Relay.useMutationWithNotify(ClaimNewPaxInvitationModel$Thin.Mutation.ClaimLoggedIn.use, (function (response) {
          var match = response.claimNewPassengerInvitationToExistingAccount;
          if (match === undefined) {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
          var match$1 = match.claimResult;
          if (match$1 !== undefined) {
            return {
                    TAG: "Failure",
                    _0: ClaimNewPaxInvitationModel$Thin.describeComboError(match$1.signUpErr, match$1.bookingUpdateError)
                  };
          } else {
            return {
                    TAG: "Success",
                    _0: undefined
                  };
          }
        }), (function (claimProgress) {
          HopperState$Util.Observable.notify(view, (function (model) {
                  return {
                          claimProgress: claimProgress,
                          password: model.password,
                          preferredFirstName: model.preferredFirstName,
                          preferredLastName: model.preferredLastName,
                          docFirstName: model.docFirstName,
                          docLastName: model.docLastName,
                          docFirstSameAsPreferredFirst: model.docFirstSameAsPreferredFirst,
                          docLastSameAsPreferredLast: model.docLastSameAsPreferredLast,
                          docBirthdate: model.docBirthdate,
                          docCitizenship: model.docCitizenship,
                          docGender: model.docGender,
                          phone: model.phone,
                          phoneCountryCode: model.phoneCountryCode,
                          postalCode: model.postalCode,
                          residenceCountry: model.residenceCountry,
                          email: model.email,
                          acquisitionSlug: model.acquisitionSlug,
                          halMarinerNumber: model.halMarinerNumber
                        };
                }));
        }));
  var match;
  if (validated.TAG === "Ok") {
    var input = validated._0;
    match = [
      "Join Booking",
      "success",
      JsxRuntime.jsx(GroupAdd, {}),
      (function (param) {
          mutation({
                input: input
              }, undefined, undefined, undefined, undefined);
        }),
      undefined
    ];
  } else {
    var tmp;
    if (isDirty) {
      switch (validated._0) {
        case "PersonalFieldsIncomplete" :
            tmp = "Complete all the elements in the Personal Fields section to complete your signup";
            break;
        case "TravelDocFieldsIncomplete" :
            tmp = "Complete all the elements in the Travel Documentation section to complete your signup";
            break;
        case "AccountCredentialsIncomplete" :
            tmp = "Fill in your email and password in order to complete your signup";
            break;
        
      }
    } else {
      tmp = "Your submission is incomplete";
    }
    match = [
      "Show Errors",
      "warning",
      JsxRuntime.jsx(WarningAmber, {}),
      (function (param) {
          HopperState$Util.Observable.notify(view, ClaimNewPaxInvitationModel$Thin.forceShowErrors);
        }),
      tmp
    ];
  }
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              children: [
                Belt_Option.mapWithDefault(match[4], null, (function (expl) {
                        return JsxRuntime.jsx(Typography, {
                                    sx: {
                                      color: "danger.400"
                                    },
                                    children: Caml_option.some(expl)
                                  });
                      })),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        width: "fit-content"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                loading: loading,
                                type: "submit",
                                onClick: match[3],
                                endDecorator: Caml_option.some(match[2]),
                                loadingPosition: "end",
                                color: match[1],
                                size: "lg",
                                children: Caml_option.some(match[0])
                              }))
                    })
              ]
            });
}

var SubmitStack$1 = {
  make: ClaimNewPaxInvitationPage$ClaimLoggedIn$SubmitStack
};

function ClaimNewPaxInvitationPage$ClaimLoggedIn(props) {
  var realAccountData = props.realAccountData;
  var view = props.view;
  React.useEffect((function () {
          ClaimNewPaxInvitationModel$Thin.prefillFromAccount(view, realAccountData);
        }), [Belt_Option.getWithDefault(JSON.stringify(realAccountData), "")]);
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimWrapper, {
                    prefill: props.prefill,
                    children: JsxRuntime.jsxs(ClaimNewPaxInvitationPage$FormWrapper, {
                          children: [
                            JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 3,
                                  onSubmit: doNothingOnSubmit,
                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                            children: [
                                              JsxRuntime.jsx(Typography, {
                                                    startDecorator: Caml_option.some(JsxRuntime.jsx(PersonOutlined, {})),
                                                    level: "h4",
                                                    component: "h2",
                                                    sx: {
                                                      mb: 2
                                                    },
                                                    children: "Personal Information"
                                                  }),
                                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.PersonalFields.make, {
                                                              interface: PassengerForms$Thin.PersonalFields.ClaimNewPaxInvitation.$$interface(view),
                                                              variant: "Gray",
                                                              disabled: false,
                                                              trackAcquisition: Belt_Option.isNone(realAccountData.acquisitionSlug)
                                                            })),
                                                    container: true,
                                                    spacing: {
                                                      TAG: "Fixed",
                                                      _0: 1
                                                    },
                                                    rowSpacing: 2,
                                                    columnSpacing: 2
                                                  })
                                            ]
                                          }))
                                }),
                            JsxRuntime.jsx(Stack, {
                                  spacing: 2,
                                  sx: {
                                    py: 2
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                                            interface: PassengerForms$Thin.Travel.ClaimNewPaxInvitation.$$interface(view),
                                            subtitle: "Information below must exactly match your travel document (e.g. passport). We will address you by your preferred name (above) whenever possible.",
                                            disabled: false,
                                            restrictedEdit: "WhenNotNull"
                                          }))
                                }),
                            JsxRuntime.jsx(ClaimNewPaxInvitationPage$ClaimLoggedIn$SubmitStack, {
                                  view: view,
                                  allegedToken: props.allegedToken
                                }),
                            JsxRuntime.jsx(ClaimNewPaxInvitationPage$SignupError, {
                                  view: view
                                })
                          ]
                        })
                  }),
              auth: true
            });
}

var ClaimLoggedIn = {
  SubmitStack: SubmitStack$1,
  make: ClaimNewPaxInvitationPage$ClaimLoggedIn
};

var FormControl;

var PersonalFields;

var TravelDocFields;

var Model;

var variant = "Gray";

exports.FormControl = FormControl;
exports.PersonalFields = PersonalFields;
exports.TravelDocFields = TravelDocFields;
exports.Model = Model;
exports.variant = variant;
exports.doNothingOnSubmit = doNothingOnSubmit;
exports.SignupError = SignupError;
exports.InvitationNotFound = InvitationNotFound;
exports.ClaimWrapper = ClaimWrapper;
exports.FormWrapper = FormWrapper;
exports.SelectClaimMethod = SelectClaimMethod;
exports.ClaimAnonymously = ClaimAnonymously;
exports.ClaimLoggedIn = ClaimLoggedIn;
/* react Not a pure module */
