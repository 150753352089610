// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Grid = require("@mui/joy/Grid").default;
var Link = require("@mui/joy/Link").default;
var Input = require("@mui/joy/Input").default;
var Select = require("@mui/joy/Select").default;
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Autocomplete = require("@mui/joy/Autocomplete").default;
var ListItemButton = require("@mui/joy/ListItemButton").default;

var Base = {};

var Impl = {};

function Joy$JoyAuto$Single(props) {
  var __startDecorator = props.startDecorator;
  var __onOpen = props.onOpen;
  var __onInputChange = props.onInputChange;
  var __onClose = props.onClose;
  var __onChange = props.onChange;
  var __multiple = props.multiple;
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var multiple = __multiple !== undefined ? __multiple : false;
  var onChange = __onChange !== undefined ? __onChange : (function (param, param$1) {
        
      });
  var onClose = __onClose !== undefined ? __onClose : (function () {
        
      });
  var onInputChange = __onInputChange !== undefined ? __onInputChange : (function (param, param$1, param$2) {
        
      });
  var onOpen = __onOpen !== undefined ? __onOpen : (function () {
        
      });
  var startDecorator = __startDecorator !== undefined ? Caml_option.valFromOption(__startDecorator) : null;
  return JsxRuntime.jsx(Autocomplete, {
              children: Caml_option.some(children),
              options: Caml_option.some(props.options),
              "aria-describedby": props["aria-describedby"],
              "aria-label": props["aria-label"],
              "aria-labelledby": props["aria-labelledby"],
              autoFocus: props.autoFocus,
              clearIcon: props.clearIcon,
              clearText: props.clearText,
              closeText: props.closeText,
              color: props.color,
              defaultValue: props.defaultValue,
              disableClearable: props.disableClearable,
              disabled: props.disabled,
              endDecorator: props.endDecorator,
              error: props.error,
              getOptionLabel: props.getOptionLabel,
              id: props.id,
              inputValue: props.inputValue,
              isOptionEqualToValue: props.isOptionEqualToValue,
              loading: props.loading,
              loadingText: props.loadingText,
              multiple: multiple,
              name: props.name,
              noOptionsText: props.noOptionsText,
              onChange: (function (e, v) {
                  onChange((e == null) ? undefined : Caml_option.some(e), (v == null) ? undefined : Caml_option.some(v));
                }),
              onClose: onClose,
              onInputChange: onInputChange,
              onOpen: onOpen,
              open: props.open,
              openText: props.openText,
              placeholder: props.placeholder,
              popupIcon: props.popupIcon,
              readOnly: props.readOnly,
              required: props.required,
              slotProps: props.slotProps,
              slots: props.slots,
              startDecorator: Caml_option.some(startDecorator),
              sx: props.sx,
              type: props.type,
              value: props.value,
              variant: props.variant,
              renderOption: props.renderOption
            });
}

var Single = {
  make: Joy$JoyAuto$Single
};

function Joy$JoyAuto$Multi(props) {
  var __startDecorator = props.startDecorator;
  var __onOpen = props.onOpen;
  var __onInputChange = props.onInputChange;
  var __onClose = props.onClose;
  var __onChange = props.onChange;
  var __multiple = props.multiple;
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var multiple = __multiple !== undefined ? __multiple : true;
  var onChange = __onChange !== undefined ? __onChange : (function (param, param$1) {
        
      });
  var onClose = __onClose !== undefined ? __onClose : (function () {
        
      });
  var onInputChange = __onInputChange !== undefined ? __onInputChange : (function (param, param$1, param$2) {
        
      });
  var onOpen = __onOpen !== undefined ? __onOpen : (function () {
        
      });
  var startDecorator = __startDecorator !== undefined ? Caml_option.valFromOption(__startDecorator) : null;
  return JsxRuntime.jsx(Autocomplete, {
              children: Caml_option.some(children),
              options: Caml_option.some(props.options),
              "aria-describedby": props["aria-describedby"],
              "aria-label": props["aria-label"],
              "aria-labelledby": props["aria-labelledby"],
              autoFocus: props.autoFocus,
              clearIcon: props.clearIcon,
              clearText: props.clearText,
              closeText: props.closeText,
              color: props.color,
              defaultValue: props.defaultValue,
              disableClearable: props.disableClearable,
              disabled: props.disabled,
              endDecorator: props.endDecorator,
              error: props.error,
              getOptionLabel: props.getOptionLabel,
              id: props.id,
              inputValue: props.inputValue,
              isOptionEqualToValue: props.isOptionEqualToValue,
              loading: props.loading,
              loadingText: props.loadingText,
              multiple: multiple,
              name: props.name,
              noOptionsText: props.noOptionsText,
              onChange: (function (e, v) {
                  onChange((e == null) ? undefined : Caml_option.some(e), (v == null) ? undefined : Caml_option.some(v));
                }),
              onClose: onClose,
              onInputChange: onInputChange,
              onOpen: onOpen,
              open: props.open,
              openText: props.openText,
              placeholder: props.placeholder,
              popupIcon: props.popupIcon,
              readOnly: props.readOnly,
              required: props.required,
              slotProps: props.slotProps,
              slots: props.slots,
              startDecorator: Caml_option.some(startDecorator),
              sx: props.sx,
              type: props.type,
              value: props.value,
              variant: props.variant
            });
}

var Multi = {
  make: Joy$JoyAuto$Multi
};

var JoyAuto = {
  Impl: Impl,
  Single: Single,
  Multi: Multi
};

var Box = {};

var CircularProgress = {};

var Divider = {};

var FormControl = {};

var FormHelperText = {};

var FormLabel = {};

var Impl$1 = {};

function Joy$Grid(props) {
  var columns = props.columns;
  var spacing = props.spacing;
  var alignItems = props.alignItems;
  var justifyContent = props.justifyContent;
  return JsxRuntime.jsx(Grid, {
              children: Caml_option.some(Belt_Option.getWithDefault(props.children, null)),
              container: props.container,
              justifyContent: Caml_option.some(justifyContent !== undefined ? justifyContent._0 : undefined),
              alignItems: Caml_option.some(alignItems !== undefined ? alignItems._0 : undefined),
              spacing: Caml_option.some(spacing !== undefined ? spacing._0 : 0),
              rowSpacing: props.rowSpacing,
              columnSpacing: props.columnSpacing,
              columns: Caml_option.some(columns !== undefined ? columns._0 : undefined),
              slotProps: props.slotProps,
              slots: props.slots,
              variant: props.variant,
              sx: props.sx,
              xs: props.xs,
              sm: props.sm,
              md: props.md,
              lg: props.lg,
              xl: props.xl
            });
}

var Grid$1 = {
  Impl: Impl$1,
  make: Joy$Grid
};

var Impl$2 = {};

function Joy$Input(props) {
  var __children = props.children;
  var __endDecorator = props.endDecorator;
  var __startDecorator = props.startDecorator;
  var __onBlur = props.onBlur;
  var __onFocus = props.onFocus;
  var __onKeyUp = props.onKeyUp;
  var __onChange = props.onChange;
  var __variant = props.variant;
  var __required = props.required;
  var __disabled = props.disabled;
  var __fullWidth = props.fullWidth;
  var __size = props.size;
  var size = __size !== undefined ? __size : "md";
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var variant = __variant !== undefined ? __variant : "outlined";
  var onChange = __onChange !== undefined ? __onChange : (function (param) {
        
      });
  var onKeyUp = __onKeyUp !== undefined ? __onKeyUp : (function (param) {
        
      });
  var onFocus = __onFocus !== undefined ? __onFocus : (function (param) {
        
      });
  var onBlur = __onBlur !== undefined ? __onBlur : (function (param) {
        
      });
  var startDecorator = __startDecorator !== undefined ? Caml_option.valFromOption(__startDecorator) : null;
  var endDecorator = __endDecorator !== undefined ? Caml_option.valFromOption(__endDecorator) : null;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var min = Belt_Option.map(props.min, (function (prim) {
          return prim.toString();
        }));
  var step = Belt_Option.map(props.step, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx(Input, {
              className: props.className,
              onFocus: onFocus,
              onBlur: onBlur,
              fullWidth: fullWidth,
              size: size,
              color: props.color,
              placeholder: props.placeholder,
              disabled: disabled,
              required: required,
              variant: variant,
              error: props.error,
              sx: props.sx,
              role: props.role,
              type: props.type_,
              value: props.value,
              onChange: onChange,
              onKeyUp: onKeyUp,
              startDecorator: Caml_option.some(startDecorator),
              endDecorator: Caml_option.some(endDecorator),
              slotProps: props.slotProps,
              children: Caml_option.some(children),
              autoComplete: props.autoComplete,
              min: Caml_option.some(min),
              step: Caml_option.some(step),
              pattern: Caml_option.some(props.pattern)
            });
}

var Input$1 = {
  Impl: Impl$2,
  make: Joy$Input
};

var JoyOption = {};

var Impl$3 = {};

function Joy$JoySelect(props) {
  var __startDecorator = props.startDecorator;
  var __onClose = props.onClose;
  var __onChange = props.onChange;
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var onChange = __onChange !== undefined ? __onChange : (function (param, param$1) {
        
      });
  var onClose = __onClose !== undefined ? __onClose : (function () {
        
      });
  var startDecorator = __startDecorator !== undefined ? Caml_option.valFromOption(__startDecorator) : null;
  return JsxRuntime.jsx(Select, {
              action: props.action,
              autoFocus: props.autoFocus,
              children: Caml_option.some(children),
              color: props.color,
              component: props.component,
              defaultListboxOpen: props.defaultListboxOpen,
              defaultValue: Caml_option.some(props.defaultValue),
              disabled: props.disabled,
              endDecorator: props.endDecorator,
              getSerializedValue: props.getSerializedValue,
              indicator: props.indicator,
              listboxId: props.listboxId,
              listboxOpen: props.listboxOpen,
              name: props.name,
              onChange: (function (e, v) {
                  onChange((e == null) ? undefined : Caml_option.some(e), (v == null) ? undefined : Caml_option.some(v));
                }),
              onClose: onClose,
              onListboxOpenChange: props.onListboxOpenChange,
              placeholder: props.placeholder,
              renderValue: props.renderValue,
              required: props.required,
              size: props.size,
              slotProps: props.slotProps,
              slots: props.slots,
              startDecorator: Caml_option.some(startDecorator),
              sx: props.sx,
              value: Caml_option.some(Js_null_undefined.fromOption(props.value)),
              variant: props.variant
            });
}

var JoySelect = {
  Impl: Impl$3,
  make: Joy$JoySelect
};

function Joy$Link(props) {
  var onClick = props.onClick;
  var target = props.target;
  var href = props.href;
  var match = target !== undefined ? (
      href !== undefined && target !== "" && href !== "" ? [
          target,
          href,
          undefined
        ] : [
          undefined,
          undefined,
          undefined
        ]
    ) : (
      href !== undefined ? [
          undefined,
          href,
          (function (e) {
              if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
                return ;
              } else {
                e.preventDefault();
                return RescriptReactRouter.push(href);
              }
            })
        ] : (
          onClick !== undefined ? [
              undefined,
              undefined,
              onClick
            ] : [
              undefined,
              undefined,
              undefined
            ]
        )
    );
  return JsxRuntime.jsx(Link, {
              children: Caml_option.some(props.children),
              color: props.color,
              component: props.component,
              endDecorator: props.endDecorator,
              level: props.level,
              textColor: props.textColor,
              underline: props.underline,
              variant: props.variant,
              onClick: match[2],
              sx: props.sx,
              href: match[1],
              target: match[0]
            });
}

var Link$1 = {
  make: Joy$Link
};

function Joy$ListItemButton(props) {
  var onClick = props.onClick;
  var target = props.target;
  var href = props.href;
  var match = target !== undefined ? (
      href !== undefined && target !== "" && href !== "" ? [
          target,
          href,
          undefined
        ] : [
          undefined,
          undefined,
          undefined
        ]
    ) : (
      href !== undefined ? [
          undefined,
          href,
          (function (e) {
              if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
                return ;
              } else {
                e.preventDefault();
                return RescriptReactRouter.push(href);
              }
            })
        ] : (
          onClick !== undefined ? [
              undefined,
              undefined,
              onClick
            ] : [
              undefined,
              undefined,
              undefined
            ]
        )
    );
  return JsxRuntime.jsx(ListItemButton, {
              action: props.action,
              autoFocus: props.autoFocus,
              children: Caml_option.some(props.children),
              color: props.color,
              component: props.component,
              disabled: props.disabled,
              focusVisibleClassName: props.focusVisibleClassName,
              href: match[1],
              onClick: match[2],
              orientation: props.orientation,
              selected: props.selected,
              slotProps: props.slotProps,
              slots: props.slots,
              sx: props.sx,
              variant: props.variant,
              endDecorator: props.endDecorator
            });
}

var ListItemButton$1 = {
  make: Joy$ListItemButton
};

var Switch = {};

var $$Text = {};

var IconBase = {};

var AlternateEmail = {};

var Add = {};

var BoatOutlined = {};

var LuggageOutlined = {};

var ContactMail = {};

var EnhancedEncryptionOutlined = {};

var ArticleOutlined = {};

var AttachMoney = {};

var CreditCard = {};

var DateRangeOutlined = {};

var DirectionsBoatOutlined = {};

var CalendarIcon = {};

var QuestionMark = {};

var LockClockOutlined = {};

var VisibilityOff = {};

var Visibility = {};

var Delete = {};

var DeleteForever = {};

var Cancel = {};

var Info = {};

var ArrowDropDownIcon = {};

var ArrowForward = {};

var ArrowLeft = {};

var Block = {};

var LockOutlined = {};

var Close = {};

var CloseRounded = {};

var HomeOutlined = {};

var Layers = {};

var LayersOutlined = {};

var Groups2Outlined = {};

var GroupAdd = {};

var ExpandMore = {};

var DottedClock = {};

var ChevronRight = {};

var Cached = {};

var ChevronLeft = {};

var FilterList = {};

var Save = {};

var Search = {};

var Share = {};

var Warning = {};

var WarningAmber = {};

var Report = {};

var ReportOutlined = {};

var TimerOutlined = {};

var UpdateIcon = {};

var Check = {};

var CheckCircle = {};

var CheckCircleOutline = {};

var Hamburger = {};

var Boat = {};

var Logout = {};

var LoyaltyOutlined = {};

var AccountCircle = {};

var AccountCircleOutlined = {};

var PersonOutlined = {};

var Person2Outlined = {};

var LinkIcon = {};

var OpenInNew = {};

var EditMode = {};

var EditModeOutlined = {};

var $$History = {};

var SailingOutlined = {};

var BedroomParentOutlined = {};

var MailOutline = {};

var ArrowUpward = {};

var ArrowDownward = {};

var ArrowForwardIos = {};

var MoreHoriz = {};

var SendAndArchiveOutlined = {};

var InfoOutlined = {};

var ErrorOutline = {};

var DoNotDisturbAlt = {};

var PersonRemoveOutlined = {};

var PeopleOutlined = {};

var Height = {};

var ElevatorOutlined = {};

var BedOutlined = {};

var ContentCopyOutlined = {};

var ScheduleSendOutlined = {};

var PersonAddDisabledOutlined = {};

var Send = {};

var SendOutlined = {};

var SquareFootOutlined = {};

var PersonAddOutlined = {};

var ManageAccountsOutlined = {};

var KeyOutlined = {};

var Diversity3 = {};

var SupervisorAccountOutlined = {};

var VisibilityOutlined = {};

var Restore = {};

var SquareRounded = {};

var KeyboardReturn = {};

var KeyboardDoubleArrowRightOutlined = {};

var PublishedWithChanges = {};

var KeyboardArrowDown = {};

var ArrowOutward = {};

var ArrowBackIosNew = {};

var ForwardOutlined = {};

var IosShare = {};

var Settings = {};

var Replay = {};

var ReplayCircleFilled = {};

var CssVarsProvider = {};

var Styles = {
  CssVarsProvider: CssVarsProvider
};

exports.Base = Base;
exports.JoyAuto = JoyAuto;
exports.Box = Box;
exports.CircularProgress = CircularProgress;
exports.Divider = Divider;
exports.FormControl = FormControl;
exports.FormHelperText = FormHelperText;
exports.FormLabel = FormLabel;
exports.Grid = Grid$1;
exports.Input = Input$1;
exports.JoyOption = JoyOption;
exports.JoySelect = JoySelect;
exports.Link = Link$1;
exports.ListItemButton = ListItemButton$1;
exports.Switch = Switch;
exports.$$Text = $$Text;
exports.IconBase = IconBase;
exports.AlternateEmail = AlternateEmail;
exports.Add = Add;
exports.BoatOutlined = BoatOutlined;
exports.LuggageOutlined = LuggageOutlined;
exports.ContactMail = ContactMail;
exports.EnhancedEncryptionOutlined = EnhancedEncryptionOutlined;
exports.ArticleOutlined = ArticleOutlined;
exports.AttachMoney = AttachMoney;
exports.CreditCard = CreditCard;
exports.DateRangeOutlined = DateRangeOutlined;
exports.DirectionsBoatOutlined = DirectionsBoatOutlined;
exports.CalendarIcon = CalendarIcon;
exports.QuestionMark = QuestionMark;
exports.LockClockOutlined = LockClockOutlined;
exports.VisibilityOff = VisibilityOff;
exports.Visibility = Visibility;
exports.Delete = Delete;
exports.DeleteForever = DeleteForever;
exports.Cancel = Cancel;
exports.Info = Info;
exports.ArrowDropDownIcon = ArrowDropDownIcon;
exports.ArrowForward = ArrowForward;
exports.ArrowLeft = ArrowLeft;
exports.Block = Block;
exports.LockOutlined = LockOutlined;
exports.Close = Close;
exports.CloseRounded = CloseRounded;
exports.HomeOutlined = HomeOutlined;
exports.Layers = Layers;
exports.LayersOutlined = LayersOutlined;
exports.Groups2Outlined = Groups2Outlined;
exports.GroupAdd = GroupAdd;
exports.ExpandMore = ExpandMore;
exports.DottedClock = DottedClock;
exports.ChevronRight = ChevronRight;
exports.Cached = Cached;
exports.ChevronLeft = ChevronLeft;
exports.FilterList = FilterList;
exports.Save = Save;
exports.Search = Search;
exports.Share = Share;
exports.Warning = Warning;
exports.WarningAmber = WarningAmber;
exports.Report = Report;
exports.ReportOutlined = ReportOutlined;
exports.TimerOutlined = TimerOutlined;
exports.UpdateIcon = UpdateIcon;
exports.Check = Check;
exports.CheckCircle = CheckCircle;
exports.CheckCircleOutline = CheckCircleOutline;
exports.Hamburger = Hamburger;
exports.Boat = Boat;
exports.Logout = Logout;
exports.LoyaltyOutlined = LoyaltyOutlined;
exports.AccountCircle = AccountCircle;
exports.AccountCircleOutlined = AccountCircleOutlined;
exports.PersonOutlined = PersonOutlined;
exports.Person2Outlined = Person2Outlined;
exports.LinkIcon = LinkIcon;
exports.OpenInNew = OpenInNew;
exports.EditMode = EditMode;
exports.EditModeOutlined = EditModeOutlined;
exports.$$History = $$History;
exports.SailingOutlined = SailingOutlined;
exports.BedroomParentOutlined = BedroomParentOutlined;
exports.MailOutline = MailOutline;
exports.ArrowUpward = ArrowUpward;
exports.ArrowDownward = ArrowDownward;
exports.ArrowForwardIos = ArrowForwardIos;
exports.MoreHoriz = MoreHoriz;
exports.SendAndArchiveOutlined = SendAndArchiveOutlined;
exports.InfoOutlined = InfoOutlined;
exports.ErrorOutline = ErrorOutline;
exports.DoNotDisturbAlt = DoNotDisturbAlt;
exports.PersonRemoveOutlined = PersonRemoveOutlined;
exports.PeopleOutlined = PeopleOutlined;
exports.Height = Height;
exports.ElevatorOutlined = ElevatorOutlined;
exports.BedOutlined = BedOutlined;
exports.ContentCopyOutlined = ContentCopyOutlined;
exports.ScheduleSendOutlined = ScheduleSendOutlined;
exports.PersonAddDisabledOutlined = PersonAddDisabledOutlined;
exports.Send = Send;
exports.SendOutlined = SendOutlined;
exports.SquareFootOutlined = SquareFootOutlined;
exports.PersonAddOutlined = PersonAddOutlined;
exports.ManageAccountsOutlined = ManageAccountsOutlined;
exports.KeyOutlined = KeyOutlined;
exports.Diversity3 = Diversity3;
exports.SupervisorAccountOutlined = SupervisorAccountOutlined;
exports.VisibilityOutlined = VisibilityOutlined;
exports.Restore = Restore;
exports.SquareRounded = SquareRounded;
exports.KeyboardReturn = KeyboardReturn;
exports.KeyboardDoubleArrowRightOutlined = KeyboardDoubleArrowRightOutlined;
exports.PublishedWithChanges = PublishedWithChanges;
exports.KeyboardArrowDown = KeyboardArrowDown;
exports.ArrowOutward = ArrowOutward;
exports.ArrowBackIosNew = ArrowBackIosNew;
exports.ForwardOutlined = ForwardOutlined;
exports.IosShare = IosShare;
exports.Settings = Settings;
exports.Replay = Replay;
exports.ReplayCircleFilled = ReplayCircleFilled;
exports.Styles = Styles;
/* @mui/joy/Grid Not a pure module */
