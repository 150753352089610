// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var AppLink$Thin = require("../../common/atoms/AppLink.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var JoCoLoGo$Thin = require("../../../assets/JoCoLoGo.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var LoginSubmit$Thin = require("../../login/mutations/LoginSubmit.bs.js");
var ModalDrawer$Thin = require("../../common/molecules/ModalDrawer.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../../common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");

function SalesFlowLoginModal(props) {
  var refetchCurrentUserInfo = props.refetchCurrentUserInfo;
  var nextTarget = props.nextTarget;
  var url = props.url;
  var urlState = props.urlState;
  var onClose = props.onClose;
  var redirectUrl = SFState$Thin.urlString(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(urlState, nextTarget)), url);
  console.log(redirectUrl);
  var onCabinHoldSuccess = function (expiry) {
    var redirect = SFState$Thin.urlString(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(SFState$Thin.Alter.setCabinHold(urlState, expiry), nextTarget)), url);
    onClose();
    RescriptReactRouter.push(redirect);
  };
  var match = CurrentUser$Thin.Context.use();
  var refetchSignOnStatus = match.refetchSignOnStatus;
  var refetch = function () {
    refetchSignOnStatus();
    return refetchCurrentUserInfo();
  };
  var match$1 = RequestCabinHoldByCategory$Thin.useMutation(props.flow, props.voyageSlug, undefined);
  var setCabinHoldError = match$1.setError;
  var cabinHoldError = match$1.error;
  var requestCabinHoldByCategory = match$1.requestCabinHoldByCategory;
  var match$2 = LoginSubmit$Thin.useMutation(refetch, Caml_option.some(undefined), (function () {
          requestCabinHoldByCategory(urlState, onCabinHoldSuccess);
        }));
  var setLoginError = match$2.setError;
  var loginError = match$2.error;
  var setPassword = match$2.setPassword;
  var password = match$2.password;
  var setEmail = match$2.setEmail;
  var email = match$2.email;
  var onModalClose = function () {
    onClose();
    setLoginError(function (param) {
          
        });
    setCabinHoldError(function (param) {
          
        });
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(ModalDrawer$Thin.make, {
                        open_: props.modalOpen,
                        title: "Start Reservation",
                        onClose: onModalClose,
                        children: JsxRuntime.jsxs(Stack, {
                              spacing: 1,
                              sx: {
                                p: 2
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-lg",
                                              children: "You are about to reserve!"
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              sx: {
                                                color: "text.secondary"
                                              },
                                              children: "After login, your cabin will be held for 15 minutes."
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              component: "form",
                                              spacing: 2,
                                              sx: {
                                                backgroundColor: "background.level1",
                                                borderRadius: "sm",
                                                p: 2
                                              },
                                              onSubmit: match$2.onSubmit,
                                              children: [
                                                JsxRuntime.jsx(Box, {
                                                      children: Caml_option.some(JsxRuntime.jsx(JoCoLoGo$Thin.make, {})),
                                                      sx: {
                                                        width: "7rem"
                                                      }
                                                    }),
                                                JsxRuntime.jsxs(Stack, {
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-lg",
                                                              children: "Have you sailed with us before?"
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                color: "text.secondary"
                                                              },
                                                              children: "If so, we'd love to have you on board again. Simply log in using your email and password to continue your journey with us!"
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsx(FormControl, {
                                                      error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                                      children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                type_: "email",
                                                                size: "md",
                                                                fullWidth: true,
                                                                placeholder: Txt$Thin.Login.emailPlaceholder.value,
                                                                value: email,
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    setEmail(function (param) {
                                                                          return val;
                                                                        });
                                                                  }),
                                                                autoComplete: "on"
                                                              }))
                                                    }),
                                                JsxRuntime.jsx(FormControl, {
                                                      children: Caml_option.some(JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                fullWidth: true,
                                                                size: "md",
                                                                placeholder: Txt$Thin.Login.passwordPlaceholder.value,
                                                                value: password,
                                                                onChange: (function (e) {
                                                                    var val = e.currentTarget.value;
                                                                    setPassword(function (param) {
                                                                          return val;
                                                                        });
                                                                  }),
                                                                autoComplete: "current-password"
                                                              }))
                                                    }),
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "row",
                                                      spacing: 1,
                                                      sx: {
                                                        width: {
                                                          xs: "100%",
                                                          md: "fit-content"
                                                        }
                                                      },
                                                      children: [
                                                        JsxRuntime.jsx(AppButton$Thin.make, {
                                                              loading: match$2.isMutating || match$1.isMutating,
                                                              type_: "submit",
                                                              size: "md",
                                                              disabled: password === "" || Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                                              children: Caml_option.some(Txt$Thin.Login.login.value)
                                                            }),
                                                        JsxRuntime.jsx(Box, {
                                                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                        level: "body-sm",
                                                                        children: Caml_option.some(JsxRuntime.jsx(AppLink$Thin.make, {
                                                                                  to: "/reset-password",
                                                                                  forceTargetBlank: true,
                                                                                  children: Txt$Thin.Login.forgotPassword.value
                                                                                }))
                                                                      })),
                                                              sx: {
                                                                alignSelf: "center",
                                                                paddingTop: 0
                                                              }
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              sx: {
                                                width: {
                                                  xs: "100%",
                                                  md: "fit-content"
                                                },
                                                alignItems: {
                                                  xs: "center",
                                                  md: "flex-start"
                                                }
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                                                        children: JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                color: "text.secondary"
                                                              },
                                                              children: "Create my account during reservation"
                                                            }),
                                                        component: "button",
                                                        underline: "always",
                                                        color: "neutral",
                                                        onClick: (function (param) {
                                                            onClose();
                                                            RescriptReactRouter.push(redirectUrl);
                                                          }),
                                                        sx: {
                                                          width: "fit-content"
                                                        }
                                                      }))
                                            })
                                      ]
                                    }),
                                loginError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                        message: loginError,
                                        type_: "error"
                                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                                cabinHoldError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                        message: cabinHoldError,
                                        type_: "error"
                                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                              ]
                            })
                      }))
            });
}

var make = SalesFlowLoginModal;

exports.make = make;
/* Joy-Util Not a pure module */
