// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var AttachMoney = require("@mui/icons-material/AttachMoney").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var UpcomingBookingsSection_query_graphql$Thin = require("../../__generated__/UpcomingBookingsSection_query_graphql.bs.js");
var UpcomingBookingsSection_listitem_graphql$Thin = require("../../__generated__/UpcomingBookingsSection_listitem_graphql.bs.js");

var convertFragment = UpcomingBookingsSection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpcomingBookingsSection_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(UpcomingBookingsSection_query_graphql$Thin.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = UpcomingBookingsSection_listitem_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

var ListitemFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function UpcomingBookingsSection$FeaturedVoyage(props) {
  var query = use$1(props.fragmentRefs);
  var voyageSlug = CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug);
  var bookingId = CS_NonemptyStrings$Util.BookingId.toString(query.bookingId);
  var daysRemaining = DateOnly$Util.daysUntil(query.voyStartDate);
  var remainingPax = (query.numBerths - (
      query.isPax ? 1 : 0
    ) | 0) - Belt_Option.getWithDefault(query.connectedPassengers, []).length | 0;
  var name = Belt_MapString.get(props.voyageNameMap, CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug));
  var connectedPassengers = query.connectedPassengers;
  return JsxRuntime.jsx(Sheet, {
              onClick: (function (param) {
                  RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink + "/" + bookingId);
                }),
              sx: {
                zIndex: 1,
                backgroundColor: "background.surface",
                alignSelf: {
                  xs: "center",
                  md: "flex-start"
                },
                borderRadius: "12px",
                border: 1,
                borderColor: "neutral.200",
                p: {
                  xs: 2,
                  md: 3
                },
                width: "100%",
                boxSizing: "border-box",
                "&:hover": {
                  boxShadow: "md"
                },
                cursor: "pointer"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: {
                          xs: "column",
                          md: "row"
                        },
                        spacing: {
                          xs: 2,
                          md: 3
                        },
                        sx: {
                          width: "100%"
                        },
                        children: [
                          JsxRuntime.jsxs(AspectRatio, {
                                children: [
                                  JsxRuntime.jsx("img", {
                                        draggable: false,
                                        style: {
                                          height: "100%",
                                          width: "100%"
                                        },
                                        alt: voyageSlug,
                                        loading: "lazy",
                                        src: "https://i.ibb.co/6WW5vVC/shiptest.jpg"
                                      }),
                                  JsxRuntime.jsx(Chip, {
                                        children: Caml_option.some(String(daysRemaining) + " day" + (
                                              daysRemaining === 1 ? "" : "s"
                                            ) + " remaining"),
                                        size: "sm",
                                        sx: {
                                          position: "absolute",
                                          top: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          left: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          borderRadius: "4px",
                                          zIndex: 5
                                        }
                                      })
                                ],
                                ratio: "1.5",
                                sx: {
                                  borderRadius: "sm",
                                  width: {
                                    xs: "100%",
                                    md: "22rem"
                                  },
                                  height: "100%",
                                  position: "relative"
                                }
                              }),
                          JsxRuntime.jsxs(Stack, {
                                justifyContent: "center",
                                spacing: {
                                  md: 3
                                },
                                sx: {
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: {
                                    xs: "column-reverse",
                                    md: "column"
                                  }
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 3,
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "column",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-xs",
                                                        textColor: "text.tertiary",
                                                        textTransform: "uppercase",
                                                        children: Caml_option.some(DateOnly$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "h3",
                                                        component: "h2",
                                                        children: Caml_option.some(bookingId)
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-md",
                                                        sx: {
                                                          color: "text.tertiary"
                                                        },
                                                        children: Caml_option.some(name !== undefined ? name : CS_Slugs$Util.VoyageSlug.toString(query.voyageSlug))
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: {
                                                  xs: "row",
                                                  md: "row"
                                                },
                                                spacing: 1,
                                                children: [
                                                  query.isBc ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: "Billing Contact"
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(AttachMoney, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null,
                                                  query.isPax ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: "You"
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null,
                                                  connectedPassengers !== undefined ? Belt_Array.map(connectedPassengers, (function (v) {
                                                            return JsxRuntime.jsx(Chip, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  fontWeight: "sm",
                                                                                  children: Caml_option.some(Belt_Option.getWithDefault(v, "Connected Account"))
                                                                                })),
                                                                        size: "md",
                                                                        startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                                        sx: {
                                                                          backgroundColor: "neutral.100",
                                                                          color: "text.primary",
                                                                          borderRadius: "xs"
                                                                        }
                                                                      });
                                                          })) : null,
                                                  remainingPax > 0 ? JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: Caml_option.some(String(remainingPax) + " Other Guest" + (
                                                                          remainingPax === 1 ? "" : "s"
                                                                        ))
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          width: "100%",
                                          display: {
                                            xs: "none",
                                            md: "flex"
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(Divider, {}),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "row",
                                                sx: {
                                                  width: "100%",
                                                  flexWrap: "wrap",
                                                  zIndex: 5
                                                },
                                                children: Caml_option.some(Belt_Array.mapWithIndex([
                                                          [
                                                            "Boarding Guide",
                                                            "/"
                                                          ],
                                                          [
                                                            "Shore Excursions",
                                                            "/"
                                                          ],
                                                          [
                                                            "Route Map",
                                                            "/"
                                                          ]
                                                        ], (function (i, param) {
                                                            return JsxRuntime.jsx(AppButton$Thin.make, {
                                                                        onClick: (function (e) {
                                                                            e.stopPropagation();
                                                                          }),
                                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(OpenInNew, {})),
                                                                        component: "a",
                                                                        variant: "plain",
                                                                        color: "neutral",
                                                                        href: param[1],
                                                                        children: Caml_option.some(param[0])
                                                                      }, String(i));
                                                          })))
                                              })
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var FeaturedVoyage = {
  make: UpcomingBookingsSection$FeaturedVoyage
};

function UpcomingBookingsSection(props) {
  var query = use(props.fragmentRefs);
  var match = query.brandFamilyVoyages;
  var voyageNameMap = match !== undefined ? Belt_MapString.fromArray(Belt_Array.map(match.nodes, (function (n) {
                return [
                        CS_Slugs$Util.VoyageSlug.toString(n.voyageSlug),
                        CS_NonemptyStrings$Util.NonEmptyString.toString(n.voyageName)
                      ];
              }))) : undefined;
  var match$1 = query.upcoming;
  var tmp;
  if (match$1 !== undefined) {
    var nodes = match$1.nodes;
    var match$2 = nodes.length;
    tmp = match$2 !== 0 ? JsxRuntime.jsxs(Stack, {
            spacing: 2,
            sx: {
              width: "100%"
            },
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "h3",
                    component: "h1",
                    children: Caml_option.some(Txt$Thin.Bookings.upcomingHeading.value)
                  }),
              Belt_Array.map(nodes, (function (node) {
                      return JsxRuntime.jsx(UpcomingBookingsSection$FeaturedVoyage, {
                                  fragmentRefs: node.fragmentRefs,
                                  voyageNameMap: voyageNameMap
                                }, node.bookingId);
                    }))
            ]
          }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "h3",
                    component: "h1",
                    children: Caml_option.some(Txt$Thin.Bookings.noUpcomingText.value)
                  }),
              JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: "You aren't participating in any upcoming bookings."
                  })
            ]
          });
  } else {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Typography, {
                  level: "h3",
                  component: "h1",
                  children: Caml_option.some(Txt$Thin.Bookings.noUpcomingText.value)
                }),
            JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  children: "You aren't participating in any upcoming bookings."
                })
          ]
        });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = UpcomingBookingsSection;

exports.QueryFragment = QueryFragment;
exports.ListitemFragment = ListitemFragment;
exports.FeaturedVoyage = FeaturedVoyage;
exports.make = make;
/* Txt-Thin Not a pure module */
