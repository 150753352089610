// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var PaymentSuggestions$Thin = require("../../../utils/sales-flow/PaymentSuggestions.bs.js");
var SaveBookingSession_SaveBookingSessionMutation_graphql$Thin = require("../../../__generated__/SaveBookingSession_SaveBookingSessionMutation_graphql.bs.js");

var convertVariables = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var SaveBookingSessionMutation_bookingSessionError_decode = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.bookingSessionError_decode;

var SaveBookingSessionMutation_bookingSessionError_fromString = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.bookingSessionError_fromString;

var SaveBookingSessionMutation_docGender_decode = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.docGender_decode;

var SaveBookingSessionMutation_docGender_fromString = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.docGender_fromString;

var SaveBookingSessionMutation_thinBookingSessionError_decode = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.thinBookingSessionError_decode;

var SaveBookingSessionMutation_thinBookingSessionError_fromString = SaveBookingSession_SaveBookingSessionMutation_graphql$Thin.Utils.thinBookingSessionError_fromString;

var SaveBookingSessionMutation = {
  bookingSessionError_decode: SaveBookingSessionMutation_bookingSessionError_decode,
  bookingSessionError_fromString: SaveBookingSessionMutation_bookingSessionError_fromString,
  docGender_decode: SaveBookingSessionMutation_docGender_decode,
  docGender_fromString: SaveBookingSessionMutation_docGender_fromString,
  thinBookingSessionError_decode: SaveBookingSessionMutation_thinBookingSessionError_decode,
  thinBookingSessionError_fromString: SaveBookingSessionMutation_thinBookingSessionError_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useQuery(occupancy, fareClassSlug, voyageSlug, setPageLevelError, sessionData) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var save = function (input, onSuccess, setLocalError, setLocalLoading) {
    HopperState$Util.Observable.notify(sessionData, (function (m) {
            return {
                    paxes: m.paxes,
                    bc: m.bc,
                    pax1: m.pax1,
                    paymentOption: m.paymentOption,
                    sessionSaveIsMutating: m.sessionSaveIsMutating,
                    signup: m.signup,
                    url: m.url,
                    paymentSuggestions: m.paymentSuggestions,
                    quote: "Unavailable"
                  };
          }));
    if (setLocalError !== undefined) {
      setLocalError(function (param) {
            
          });
    }
    var setError$1 = setLocalError !== undefined ? setLocalError : setError;
    if (setLocalLoading !== undefined) {
      setLocalLoading(function (param) {
            return true;
          });
    }
    mutate({
          input: input
        }, undefined, undefined, undefined, (function (res, err) {
            if (err === undefined) {
              var match = res.saveBookingSession;
              if (match !== undefined) {
                var match$1 = match.saveBookingSessionResponse;
                if (match$1 !== undefined) {
                  var match$2 = match$1.success;
                  var quote = match$1.quote;
                  var paymentPlan = match$1.paymentPlan;
                  var err$1 = match$1.validationErr;
                  if (err$1 !== undefined) {
                    var exit = 0;
                    if (err$1 === "INVALID_PAYMENT_PLAN" || err$1 === "NEW_UNCLAIMED_USER_WITH_NULL_CUSTODIAN_ACCOUNT_ID" || err$1 === "USER_DELETED_STATUS" || err$1 === "AMBIGUOUS_PAX_TARGETS" || err$1 === "PAX_INVALID_AGE_FOR_PAX1" || err$1 === "PAX_IDX_INVALID" || err$1 === "PAX_HAS_ANOTHER_ACTIVE_BOOKING" || err$1 === "PAX_ACCT_INVALID" || err$1 === "BILLING_CONTACT_HAS_TOO_MANY_BOOKINGS" || err$1 === "INVALID_BILLING_CONTACT" || err$1 === "INVALID_CABIN_VARS" || err$1 === "INVALID_CABIN" || err$1 === "INVALID_FARE_CLASS" || err$1 === "BOOKING_ID_ALREADY_IN_USE" || err$1 === "NO_PAX") {
                      switch (err$1) {
                        case "BOOKING_ID_ALREADY_IN_USE" :
                            setPageLevelError(function (param) {
                                  return "Booking ID already in use.";
                                });
                            break;
                        case "INVALID_FARE_CLASS" :
                            setPageLevelError(function (param) {
                                  return "Invalid fare class.";
                                });
                            break;
                        case "INVALID_CABIN" :
                            setPageLevelError(function (param) {
                                  return "Invalid cabin selection.";
                                });
                            break;
                        case "INVALID_BILLING_CONTACT" :
                            setPageLevelError(function (param) {
                                  return "Invalid billing contact.";
                                });
                            break;
                        case "BILLING_CONTACT_HAS_TOO_MANY_BOOKINGS" :
                            setPageLevelError(function (param) {
                                  return "Billing contact has too many bookings.";
                                });
                            break;
                        case "PAX_ACCT_INVALID" :
                            setError$1(function (param) {
                                  return "Invalid passenger account.";
                                });
                            break;
                        case "PAX_IDX_INVALID" :
                            setError$1(function (param) {
                                  return "Invalid passenger index.";
                                });
                            break;
                        case "AMBIGUOUS_PAX_TARGETS" :
                            setError$1(function (param) {
                                  return "Ambiguous passenger targets.";
                                });
                            break;
                        case "USER_DELETED_STATUS" :
                            setError$1(function (param) {
                                  return "A user you tried to add has been deleted from our system.";
                                });
                            break;
                        case "NEW_UNCLAIMED_USER_WITH_NULL_CUSTODIAN_ACCOUNT_ID" :
                            setError$1(function (param) {
                                  return "A new customer without a custodian account ID cannot be added.";
                                });
                            break;
                        case "NO_PAX" :
                            setError$1(function (param) {
                                  return "No passengers added to this booking session.";
                                });
                            break;
                        default:
                          exit = 2;
                      }
                    } else {
                      exit = 2;
                    }
                    if (exit === 2) {
                      setError$1(function (param) {
                            return "A validation error occurred. Please try again.";
                          });
                    }
                    if (setLocalLoading !== undefined) {
                      setLocalLoading(function (param) {
                            return false;
                          });
                    }
                    return ;
                  }
                  if (match$2 !== undefined) {
                    if (match$2) {
                      var newQuote;
                      if (quote !== undefined && fareClassSlug !== undefined) {
                        var quote$1 = SalesCabin$Thin.getMiniQuote({
                              TAG: "SessionSave",
                              _0: quote
                            }, Caml_option.valFromOption(fareClassSlug));
                        newQuote = quote$1 !== undefined ? ({
                              TAG: "Quote",
                              _0: quote$1
                            }) : "Unavailable";
                      } else {
                        newQuote = "Unavailable";
                      }
                      if (paymentPlan !== undefined) {
                        HopperState$Util.Observable.notify(sessionData, (function (m) {
                                return {
                                        paxes: m.paxes,
                                        bc: m.bc,
                                        pax1: m.pax1,
                                        paymentOption: m.paymentOption,
                                        sessionSaveIsMutating: m.sessionSaveIsMutating,
                                        signup: m.signup,
                                        url: m.url,
                                        paymentSuggestions: PaymentSuggestions$Thin.getPaymentSuggestions({
                                              TAG: "SessionSave",
                                              _0: paymentPlan
                                            }),
                                        quote: newQuote
                                      };
                              }));
                      }
                      return onSuccess();
                    }
                    var err$2 = match$1.thinErr;
                    if (err$2 !== undefined) {
                      var exit$1 = 0;
                      if (err$2 === "INVALID_CONTROLLED_PAX_WITH_TRAVEL_DOC_UPDATE" || err$2 === "NO_MATERIALIZED_PAX" || err$2 === "INVALID_CONTROLLED_PAX" || err$2 === "INVALID_LOYALTY_PAX" || err$2 === "INVALID_OCCUPANCY_FOR_HELD_CABIN" || err$2 === "NO_CABIN_HOLD" || err$2 === "UPDATE_TRAVEL_DOC_FAILURE") {
                        switch (err$2) {
                          case "NO_CABIN_HOLD" :
                              setError$1(function (param) {
                                    return "No cabin hold found.";
                                  });
                              break;
                          case "INVALID_OCCUPANCY_FOR_HELD_CABIN" :
                              setError$1(function (param) {
                                    return "Invalid occupancy for held cabin.";
                                  });
                              break;
                          case "INVALID_LOYALTY_PAX" :
                              setError$1(function (param) {
                                    return "Invalid loyalty passenger added.";
                                  });
                              break;
                          case "INVALID_CONTROLLED_PAX" :
                              setError$1(function (param) {
                                    return "Invalid passenger added.";
                                  });
                              break;
                          case "NO_MATERIALIZED_PAX" :
                              exit$1 = 2;
                              break;
                          case "INVALID_CONTROLLED_PAX_WITH_TRAVEL_DOC_UPDATE" :
                              setError$1(function (param) {
                                    return "Invalid passenger with incomplete travel data added.";
                                  });
                              break;
                          case "UPDATE_TRAVEL_DOC_FAILURE" :
                              setError$1(function (param) {
                                    return "Failed to update travel document.";
                                  });
                              break;
                          
                        }
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        setError$1(function (param) {
                              return "An app error occurred. Please try again.";
                            });
                      }
                      if (setLocalLoading !== undefined) {
                        setLocalLoading(function (param) {
                              return false;
                            });
                      }
                      return ;
                    }
                    
                  }
                  
                }
                
              }
              setError$1(function (param) {
                    return "An error occurred. Please try again.";
                  });
              if (setLocalLoading !== undefined) {
                setLocalLoading(function (param) {
                      return false;
                    });
              }
              return ;
            }
            setError$1(function (param) {
                  return "An error occurred. Please try again.";
                });
            if (setLocalLoading !== undefined) {
              setLocalLoading(function (param) {
                    return false;
                  });
            }
            
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          save: save
        };
}

exports.SaveBookingSessionMutation = SaveBookingSessionMutation;
exports.useQuery = useQuery;
/* commitMutation Not a pure module */
