// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var ImageSet$Thin = require("../../context/ImageSet.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var validOccupancies = [
  1,
  2,
  3,
  4
];

function useSalesCabinLinkedImagesForVariant(slug, variant) {
  var match = React.useContext(ImageSet$Thin.Context.context);
  var arr = match.useImageSet(slug);
  return Belt_Array.map(arr, (function (param) {
                return {
                        src: param.getSrc(variant),
                        alt: CS_NonemptyStrings$Util.NonEmptyString.toString(param.altText),
                        caption: Belt_Option.map(param.captionText, (function (v) {
                                return CS_NonemptyStrings$Util.NonEmptyString.toString(v);
                              }))
                      };
              }));
}

function getFcMeta(fcRaw) {
  if (fcRaw !== undefined) {
    return Belt_MapString.fromArray(Belt_Array.map(fcRaw.nodes, (function (fc) {
                      var fcSlug = CS_Slugs$Util.FareClassSlug.Exn.fromPrimitive(CS_Slugs$Util.FareClassSlug.toPrimitive(fc.fareClassSlug).toLowerCase());
                      var fcName = fc.salesName;
                      var fcBlurb = fc.salesHighlights;
                      var fcTerms = fc.salesTerms;
                      return [
                              CS_Slugs$Util.FareClassSlug.toString(fcSlug),
                              {
                                fcSlug: fcSlug,
                                fcName: fcName,
                                fcBlurb: fcBlurb,
                                fcTerms: fcTerms
                              }
                            ];
                    })));
  }
  
}

function getAvailStatus(d) {
  if (!(d === "AVAILABLE" || d === "LIMITED_AT_THIS_PRICE" || d === "LIMITED_AT_THIS_OCCUPANCY" || d === "LIMITED" || d === "SOLD_OUT_AT_THIS_OCCUPANCY" || d === "SOLD_OUT" || d === "UNAVAILABLE_AT_THIS_OCCUPANCY" || d === "UNAVAILABLE" || d === "BY_INQUIRY_ONLY")) {
    return "Unavailable";
  }
  switch (d) {
    case "UNAVAILABLE_AT_THIS_OCCUPANCY" :
        return "UnavailableAtOccupancy";
    case "SOLD_OUT" :
        return "SoldOut";
    case "SOLD_OUT_AT_THIS_OCCUPANCY" :
        return "SoldOutForThisOccupancy";
    case "LIMITED" :
        return "Limited";
    case "LIMITED_AT_THIS_PRICE" :
        return "LimitedAtPrice";
    case "AVAILABLE" :
        return "Available";
    case "BY_INQUIRY_ONLY" :
        return "InquiryOnly";
    default:
      return "Unavailable";
  }
}

function getAllAvails(currentAvails, validOccupancies) {
  var avails;
  if (Belt_MapInt.isEmpty(currentAvails)) {
    avails = Belt_MapInt.fromArray(Belt_Array.map(validOccupancies, (function (occ) {
                return [
                        occ,
                        {
                          availability: "Unavailable",
                          quotePricePerPerson: undefined,
                          referencePricePerPerson: undefined,
                          quotePricePerBooking: undefined,
                          referencePricePerBooking: undefined
                        }
                      ];
              })));
  } else {
    var availsArray = Belt_MapInt.toArray(currentAvails);
    avails = Belt_Array.every(availsArray, (function (param) {
            return param[1].availability === "SoldOut";
          })) ? currentAvails : Belt_MapInt.fromArray(Belt_Array.map(Belt_MapInt.toArray(currentAvails), (function (param) {
                  var obj = param[1];
                  var $$int = param[0];
                  var match = obj.availability;
                  if (match === "SoldOut") {
                    return [
                            $$int,
                            {
                              availability: "SoldOutForThisOccupancy",
                              quotePricePerPerson: obj.quotePricePerPerson,
                              referencePricePerPerson: obj.referencePricePerPerson,
                              quotePricePerBooking: obj.quotePricePerBooking,
                              referencePricePerBooking: obj.referencePricePerBooking
                            }
                          ];
                  } else {
                    return [
                            $$int,
                            obj
                          ];
                  }
                })));
  }
  var unavailAtOcc = Belt_Array.keepMap(validOccupancies, (function (v) {
          var match = Belt_MapInt.get(avails, v);
          if (match !== undefined) {
            return ;
          } else {
            return [
                    v,
                    {
                      availability: "UnavailableAtOccupancy",
                      quotePricePerPerson: undefined,
                      referencePricePerPerson: undefined,
                      quotePricePerBooking: undefined,
                      referencePricePerBooking: undefined
                    }
                  ];
          }
        }));
  return Belt_MapInt.fromArray(Belt_Array.concat(Belt_MapInt.toArray(currentAvails), unavailAtOcc));
}

function useWrangleClasses(data) {
  if (data !== undefined) {
    return Belt_Array.map(data.nodes, (function (n) {
                  var subclasses = Belt_Array.keepMap(n.subclasses, (function (sub) {
                          if (sub === undefined) {
                            return ;
                          }
                          var subclassAvails = Belt_MapInt.fromArray(Belt_Array.keepMap(sub.availability, (function (avail) {
                                      if (avail === undefined) {
                                        return ;
                                      }
                                      var match = avail.quoteIsDiscount;
                                      var match$1 = avail.quotedPricePerBerth;
                                      var match$2 = avail.quoteIsDiscount;
                                      var match$3 = avail.quotedPricePerCabin;
                                      return [
                                              avail.forOccupancy,
                                              {
                                                availability: getAvailStatus(avail.availability),
                                                quotePricePerPerson: match !== undefined && match && match$1 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$1)) : undefined,
                                                referencePricePerPerson: avail.referencePricePerBerth,
                                                quotePricePerBooking: match$2 !== undefined && match$2 && match$3 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$3)) : undefined,
                                                referencePricePerBooking: avail.referencePricePerCabin
                                              }
                                            ];
                                    })));
                          return {
                                  name: CS_NonemptyStrings$Util.NonEmptyString.toString(sub.subclassMetadata.name),
                                  slug: {
                                    TAG: "Subclass",
                                    _0: sub.voyageSubclassSlug
                                  },
                                  blurb: sub.subclassMetadata.shortBlurbMd,
                                  minSqFoot: Belt_Option.getWithDefault(sub.subclassMetadata.minSqft, 0),
                                  maxSqFoot: Belt_Option.getWithDefault(sub.subclassMetadata.maxSqft, 0),
                                  maxOccupancy: sub.subclassMetadata.maxOccupancy,
                                  imageSetSlug: sub.subclassMetadata.imageSetSlug,
                                  availabilities: getAllAvails(subclassAvails, validOccupancies),
                                  subs: undefined
                                };
                        }));
                  var classAvails = Belt_MapInt.fromArray(Belt_Array.keepMap(n.availability, (function (avail) {
                              if (avail === undefined) {
                                return ;
                              }
                              var match = avail.quoteIsDiscount;
                              var match$1 = avail.quotedPricePerBerth;
                              var match$2 = avail.quoteIsDiscount;
                              var match$3 = avail.quotedPricePerCabin;
                              return [
                                      avail.forOccupancy,
                                      {
                                        availability: getAvailStatus(avail.availability),
                                        quotePricePerPerson: match !== undefined && match && match$1 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$1)) : undefined,
                                        referencePricePerPerson: avail.referencePricePerBerth,
                                        quotePricePerBooking: match$2 !== undefined && match$2 && match$3 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$3)) : undefined,
                                        referencePricePerBooking: avail.referencePricePerCabin
                                      }
                                    ];
                            })));
                  return {
                          name: CS_NonemptyStrings$Util.NonEmptyString.toString(n.classMetadata.name),
                          slug: {
                            TAG: "Class",
                            _0: n.voyageClassSlug
                          },
                          blurb: n.classMetadata.shortBlurbMd,
                          minSqFoot: Belt_Option.getWithDefault(n.classMetadata.minSqft, 0),
                          maxSqFoot: Belt_Option.getWithDefault(n.classMetadata.maxSqft, 0),
                          maxOccupancy: n.classMetadata.maxOccupancy,
                          imageSetSlug: n.classMetadata.imageSetSlug,
                          availabilities: getAllAvails(classAvails, validOccupancies),
                          subs: subclasses
                        };
                }));
  }
  
}

function wrangleSubclass(occupancy, $$class, subclass, salesData, fareClassData) {
  if ($$class === undefined) {
    return ;
  }
  if (subclass === undefined) {
    return ;
  }
  if (salesData === undefined) {
    return ;
  }
  var nodes = salesData.nodes;
  var cl = CS_Slugs$Util.CabinClassSlug.Exn.fromPrimitive(CS_Slugs$Util.CabinClassSlug.toPrimitive(Caml_option.valFromOption($$class)).toLowerCase());
  var scl = CS_Slugs$Util.CabinSubclassSlug.Exn.fromPrimitive(CS_Slugs$Util.CabinSubclassSlug.toPrimitive(Caml_option.valFromOption(subclass)).toLowerCase());
  var rawSubclass = Belt_Array.get(Belt_Array.keepMap(nodes, (function (c) {
              if (Caml_obj.equal(c.voyageClassSlug, cl)) {
                return Belt_Array.get(Belt_Array.keepMap(c.subclasses, (function (sc) {
                                  if (sc !== undefined && sc.voyageSubclassSlug === scl) {
                                    return sc;
                                  }
                                  
                                })), 0);
              }
              
            })), 0);
  var className = Belt_Array.get(Belt_Array.keepMap(nodes, (function (c) {
              if (Caml_obj.equal(c.voyageClassSlug, cl)) {
                return CS_NonemptyStrings$Util.NonEmptyString.toString(c.classMetadata.name);
              }
              
            })), 0);
  if (rawSubclass === undefined) {
    return ;
  }
  var categories = Belt_Array.keepMap(rawSubclass.categories, (function (c) {
          if (c === undefined) {
            return ;
          }
          var avail = Belt_Array.keepMap(c.availability, (function (a) {
                  if (a !== undefined && a.forOccupancy === occupancy) {
                    return a;
                  }
                  
                }));
          var categoryAvail = Belt_Array.get(avail, 0);
          if (categoryAvail === undefined) {
            return ;
          }
          var fareClassMap = getFcMeta(fareClassData);
          var fareClasses = Belt_Array.keepMap(c.fareClasses, (function (fc) {
                  if (fc === undefined) {
                    return ;
                  }
                  var fcSlugString = CS_Slugs$Util.FareClassSlug.toString(fc.fareClassSlug);
                  return Belt_Array.get(Belt_Array.keepMap(fc.availability, (function (fca) {
                                    var match = Belt_MapString.get(fareClassMap, fcSlugString);
                                    if (fca === undefined) {
                                      return ;
                                    }
                                    if (match === undefined) {
                                      return ;
                                    }
                                    if (fca.forOccupancy !== occupancy) {
                                      return ;
                                    }
                                    var match$1 = fca.quoteIsDiscount;
                                    var match$2 = fca.quotedPricePerCabin;
                                    var match$3 = fca.quoteIsDiscount;
                                    var match$4 = fca.quotedPricePerBerth;
                                    var price = fca.referencePricePerCabin;
                                    var price$1 = fca.referencePricePerBerth;
                                    return {
                                            fcSlug: match.fcSlug,
                                            fcName: CS_NonemptyStrings$Util.NonEmptyString.toString(match.fcName),
                                            fcQuotePricePerBooking: match$1 !== undefined && match$1 && match$2 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$2)) : undefined,
                                            fcQuotePricePerPerson: match$3 !== undefined && match$3 && match$4 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$4)) : undefined,
                                            fcReferencePricePerBooking: price !== undefined ? Caml_option.valFromOption(price) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00"),
                                            fcReferencePricePerPerson: price$1 !== undefined ? Caml_option.valFromOption(price$1) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00"),
                                            fcBlurb: match.fcBlurb,
                                            fcTerms: match.fcTerms,
                                            fcAvailStatus: getAvailStatus(fca.availability),
                                            fcInquiryOnly: fca.byInquiryOnly
                                          };
                                  })), 0);
                }));
          var match = categoryAvail.quoteIsDiscount;
          var match$1 = categoryAvail.quotedPricePerBerth;
          var price = categoryAvail.referencePricePerBerth;
          var match$2 = categoryAvail.quoteIsDiscount;
          var match$3 = categoryAvail.quotedPricePerCabin;
          var price$1 = categoryAvail.referencePricePerCabin;
          return {
                  catName: CS_NonemptyStrings$Util.NonEmptyString.toString(c.categoryMetadata.name),
                  className: Belt_Option.getWithDefault(className, ""),
                  slug: CS_Slugs$Util.CabinCategorySlug.Exn.fromPrimitive(CS_Slugs$Util.CabinCategorySlug.toString(c.voyageCategorySlug).toLowerCase()),
                  blurb: c.categoryMetadata.longBlurbMd,
                  catMinSqFoot: Belt_Option.getWithDefault(c.categoryMetadata.minSqft, 0),
                  catMaxSqFoot: Belt_Option.getWithDefault(c.categoryMetadata.maxSqft, 0),
                  catMaxOccupancy: c.categoryMetadata.maxOccupancy,
                  quotePricePerPerson: match !== undefined && match && match$1 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$1)) : undefined,
                  referencePricePerPerson: price !== undefined ? Caml_option.valFromOption(price) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00"),
                  quotePricePerBooking: match$2 !== undefined && match$2 && match$3 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$3)) : undefined,
                  referencePricePerBooking: price$1 !== undefined ? Caml_option.valFromOption(price$1) : CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00"),
                  decks: [],
                  imageSetSlug: c.categoryMetadata.imageSetSlug,
                  fareClasses: fareClasses
                };
        }));
  return {
          name: CS_NonemptyStrings$Util.NonEmptyString.toString(rawSubclass.subclassMetadata.name),
          minSqFoot: Belt_Option.getWithDefault(rawSubclass.subclassMetadata.minSqft, 0),
          maxSqFoot: Belt_Option.getWithDefault(rawSubclass.subclassMetadata.maxSqft, 0),
          maxOccupancy: rawSubclass.subclassMetadata.maxOccupancy,
          imageSetSlug: rawSubclass.subclassMetadata.imageSetSlug,
          categories: categories
        };
}

function wrangleVoyage(data) {
  if (data === undefined) {
    return ;
  }
  var match = Belt_Array.get(data.nodes, 0);
  if (match !== undefined) {
    return {
            voyageSlug: match.voyageSlug,
            voyageName: match.voyageName,
            voyStartDate: match.voyStartDate,
            voyEndDate: match.voyEndDate,
            imageSetSlug: match.imageSetSlug
          };
  }
  
}

function getMiniQuote(source, fcSlug) {
  if (source.TAG === "CabinHold") {
    var match = source._0;
    var match$1 = match.billingAndPaymentTotals;
    if (match$1 === undefined) {
      return ;
    }
    var gb = match$1.grossBilled;
    if (gb === undefined) {
      return ;
    }
    var ql = match.quoteLines;
    if (ql === undefined) {
      return ;
    }
    var quoteLines = Belt_Array.keepMap(ql, (function (ql) {
            if (ql !== undefined) {
              return {
                      description: CS_NonemptyStrings$Util.NonEmptyString.toString(ql.billingLineDescription),
                      amount: ql.lineAmount
                    };
            }
            
          }));
    return {
            quoteLines: quoteLines,
            total: Caml_option.valFromOption(gb),
            fareClassSlug: fcSlug
          };
  }
  var match$2 = source._0;
  var match$3 = match$2.billingAndPaymentTotals;
  if (match$3 === undefined) {
    return ;
  }
  var gb$1 = match$3.grossBilled;
  if (gb$1 === undefined) {
    return ;
  }
  var ql$1 = match$2.quoteLines;
  if (ql$1 === undefined) {
    return ;
  }
  var quoteLines$1 = Belt_Array.keepMap(ql$1, (function (ql) {
          if (ql !== undefined) {
            return {
                    description: CS_NonemptyStrings$Util.NonEmptyString.toString(ql.billingLineDescription),
                    amount: ql.lineAmount
                  };
          }
          
        }));
  return {
          quoteLines: quoteLines$1,
          total: Caml_option.valFromOption(gb$1),
          fareClassSlug: fcSlug
        };
}

var emptyAvail = {
  availability: "UnavailableAtOccupancy",
  quotePricePerPerson: undefined,
  referencePricePerPerson: undefined,
  quotePricePerBooking: undefined,
  referencePricePerBooking: undefined
};

exports.validOccupancies = validOccupancies;
exports.useSalesCabinLinkedImagesForVariant = useSalesCabinLinkedImagesForVariant;
exports.getFcMeta = getFcMeta;
exports.getAvailStatus = getAvailStatus;
exports.getAllAvails = getAllAvails;
exports.useWrangleClasses = useWrangleClasses;
exports.wrangleSubclass = wrangleSubclass;
exports.wrangleVoyage = wrangleVoyage;
exports.emptyAvail = emptyAvail;
exports.getMiniQuote = getMiniQuote;
/* react Not a pure module */
