// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var List = require("@mui/joy/List").default;
var ImageSet$Thin = require("../../context/ImageSet.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Height = require("@mui/icons-material/Height").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var MailOutline = require("@mui/icons-material/MailOutline").default;
var LayersOutlined = require("@mui/icons-material/LayersOutlined").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var ElevatorOutlined = require("@mui/icons-material/ElevatorOutlined").default;
var BDStateroomDetails_detail_graphql$Thin = require("../../__generated__/BDStateroomDetails_detail_graphql.bs.js");
var BedroomParentOutlined = require("@mui/icons-material/BedroomParentOutlined").default;
var BDStateroomDetails_stateroom_graphql$Thin = require("../../__generated__/BDStateroomDetails_stateroom_graphql.bs.js");

var convertFragment = BDStateroomDetails_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDStateroomDetails_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDStateroomDetails_detail_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDStateroomDetails_detail_graphql$Thin.node, convertFragment, fRef);
}

var DetailFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = BDStateroomDetails_stateroom_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1, fRef);
}

var StateroomInfoFragment_elevatorProximity_decode = BDStateroomDetails_stateroom_graphql$Thin.Utils.elevatorProximity_decode;

var StateroomInfoFragment_elevatorProximity_fromString = BDStateroomDetails_stateroom_graphql$Thin.Utils.elevatorProximity_fromString;

var StateroomInfoFragment_latitudinality_decode = BDStateroomDetails_stateroom_graphql$Thin.Utils.latitudinality_decode;

var StateroomInfoFragment_latitudinality_fromString = BDStateroomDetails_stateroom_graphql$Thin.Utils.latitudinality_fromString;

var StateroomInfoFragment_longitudinality_decode = BDStateroomDetails_stateroom_graphql$Thin.Utils.longitudinality_decode;

var StateroomInfoFragment_longitudinality_fromString = BDStateroomDetails_stateroom_graphql$Thin.Utils.longitudinality_fromString;

var StateroomInfoFragment = {
  elevatorProximity_decode: StateroomInfoFragment_elevatorProximity_decode,
  elevatorProximity_fromString: StateroomInfoFragment_elevatorProximity_fromString,
  latitudinality_decode: StateroomInfoFragment_latitudinality_decode,
  latitudinality_fromString: StateroomInfoFragment_latitudinality_fromString,
  longitudinality_decode: StateroomInfoFragment_longitudinality_decode,
  longitudinality_fromString: StateroomInfoFragment_longitudinality_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function BDStateroomDetails$StateroomInfo(props) {
  var data = use$1(props.fragmentRefs);
  var match = React.useContext(ImageSet$Thin.Context.context);
  var stateroomImage = Belt_Array.get(match.useImageSet(data.cabinCategoryImageSet), 0);
  var stateroomImage$1 = Belt_Option.map(stateroomImage, (function (v) {
          return v.getSrc("public");
        }));
  var $$class = CS_NonemptyStrings$Util.NonEmptyString.toString(data.cabinClassName);
  var category = CS_NonemptyStrings$Util.NonEmptyString.toString(data.cabinCategoryName);
  var cabinNumber = Belt_Option.map(data.cabinNumber, CS_NonemptyStrings$Util.CabinNumber.toString);
  var cabinDeckNumber = Belt_Option.map(data.cabinDeckNumber, CS_NonemptyStrings$Util.DeckNumber.toString);
  var cabinForeAftPlacement = Belt_Option.map(data.cabinForeAftPlacement, (function (v) {
          if (!(v === "AFT" || v === "FORE" || v === "MID")) {
            return v;
          }
          switch (v) {
            case "FORE" :
                return "Forward";
            case "AFT" :
                return "Aft";
            case "MID" :
                return "Midship";
            
          }
        }));
  var cabinElevatorPlacement = Belt_Option.map(data.cabinElevatorPlacement, (function (v) {
          if (v === "NEARELEVATORS" || v === "FARELEVATORS") {
            if (v === "NEARELEVATORS") {
              return "Near elevators";
            } else {
              return "Away from elevators";
            }
          } else {
            return v;
          }
        }));
  var cabinPortStarboardPlacement = Belt_Option.map(data.cabinPortStarboardPlacement, (function (v) {
          if (v === "PORT" || v === "STARBOARD") {
            if (v === "PORT") {
              return "Port side";
            } else {
              return "Starboard side";
            }
          } else {
            return v;
          }
        }));
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var match$1 = diff.addBerths;
  var match$2 = diff.dropBerths;
  var numBerths = match$1 !== undefined ? (
      match$2 !== undefined ? (data.numBerths + match$1 | 0) - match$2.length | 0 : data.numBerths + match$1 | 0
    ) : (
      match$2 !== undefined ? data.numBerths - match$2.length | 0 : data.numBerths
    );
  var userContext_numBerths = String(numBerths);
  var userContext_berthsPlural = numBerths !== 1;
  var userContext = {
    cabinDeckNumber: cabinDeckNumber,
    cabinNumber: cabinNumber,
    numBerths: userContext_numBerths,
    berthsPlural: userContext_berthsPlural
  };
  return JsxRuntime.jsxs(Stack, {
              direction: {
                xs: "column",
                md: "row"
              },
              spacing: 2,
              sx: {
                width: "100%"
              },
              children: [
                Belt_Option.mapWithDefault(stateroomImage$1, null, (function (stateroomImage) {
                        return JsxRuntime.jsx(Stack, {
                                    sx: {
                                      backgroundImage: "url(" + stateroomImage + ")",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      width: {
                                        xs: "100%",
                                        md: "12rem",
                                        lg: "14rem"
                                      },
                                      minWidth: "8rem",
                                      height: "10rem",
                                      borderRadius: "md"
                                    }
                                  });
                      })),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: {
                        xs: 1,
                        lg: 2
                      },
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "h3",
                              component: "h3",
                              children: Caml_option.some($$class)
                            }),
                        JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              children: Caml_option.some(category)
                            }),
                        cabinNumber !== undefined ? null : JsxRuntime.jsx(Typography, {
                                level: "body-xs",
                                sx: {
                                  fontStyle: "italic"
                                },
                                textColor: "text.tertiary",
                                children: "Cabin assignments not yet released."
                              }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              spacing: 1,
                              useFlexGap: true,
                              flexWrap: "wrap",
                              sx: {
                                overflow: "hidden"
                              },
                              children: [
                                JsxRuntime.jsx(Chip, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                fontWeight: "sm",
                                                children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.berthsChipText, userContext))
                                              })),
                                      size: "md",
                                      startDecorator: Caml_option.some(JsxRuntime.jsx(BedroomParentOutlined, {})),
                                      sx: {
                                        backgroundColor: "neutral.100",
                                        color: "text.primary",
                                        borderRadius: "xs"
                                      }
                                    }),
                                cabinDeckNumber !== undefined ? JsxRuntime.jsx(Chip, {
                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  fontWeight: "sm",
                                                  children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.cabinDeckChipText, userContext))
                                                })),
                                        size: "md",
                                        startDecorator: Caml_option.some(JsxRuntime.jsx(LayersOutlined, {})),
                                        sx: {
                                          backgroundColor: "neutral.100",
                                          color: "text.primary",
                                          borderRadius: "xs"
                                        }
                                      }) : null,
                                cabinNumber !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                        children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-xs",
                                                            fontWeight: "sm",
                                                            children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.cabinNumberChipText, userContext))
                                                          })),
                                                  size: "md",
                                                  sx: {
                                                    backgroundColor: "neutral.100",
                                                    color: "text.primary",
                                                    borderRadius: "xs"
                                                  }
                                                }))
                                      }) : null,
                                cabinForeAftPlacement !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                        children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-xs",
                                                            fontWeight: "sm",
                                                            children: Caml_option.some(cabinForeAftPlacement)
                                                          })),
                                                  size: "md",
                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Height, {})),
                                                  sx: {
                                                    backgroundColor: "neutral.100",
                                                    color: "text.primary",
                                                    borderRadius: "xs"
                                                  }
                                                }))
                                      }) : null,
                                cabinPortStarboardPlacement !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                        children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-xs",
                                                            fontWeight: "sm",
                                                            children: Caml_option.some(cabinPortStarboardPlacement)
                                                          })),
                                                  size: "md",
                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Height, {
                                                            sx: {
                                                              transform: "rotate(90deg)"
                                                            }
                                                          })),
                                                  sx: {
                                                    backgroundColor: "neutral.100",
                                                    color: "text.primary",
                                                    borderRadius: "xs"
                                                  }
                                                }))
                                      }) : null,
                                cabinElevatorPlacement !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                        children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            level: "body-xs",
                                                            fontWeight: "sm",
                                                            children: Caml_option.some(cabinElevatorPlacement)
                                                          })),
                                                  size: "md",
                                                  startDecorator: Caml_option.some(JsxRuntime.jsx(ElevatorOutlined, {})),
                                                  sx: {
                                                    backgroundColor: "neutral.100",
                                                    color: "text.primary",
                                                    borderRadius: "xs"
                                                  }
                                                }))
                                      }) : null
                              ]
                            })
                      ]
                    })
              ]
            });
}

var StateroomInfo = {
  make: BDStateroomDetails$StateroomInfo
};

function BDStateroomDetails$BillingContactInfo(props) {
  var draftMode = props.draftMode;
  var canEdit = props.canEdit;
  var bc = props.bc;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  var tempContext_bookingId = props.bookingId;
  var tempContext_mailtoEmail = SysCtx$Thin.vars.mailtoEmail;
  var tempContext = {
    bookingId: tempContext_bookingId,
    mailtoEmail: tempContext_mailtoEmail
  };
  var tmp;
  if (canEdit) {
    var exit = 0;
    if (props.paxManagementLocked && props.prefManagementLocked) {
      tmp = JsxRuntime.jsx(Typography, {
            level: "body-sm",
            sx: {
              fontStyle: "italic"
            },
            textColor: "text.secondary",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManageReservationAllLockedText, tempContext))
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = draftMode ? JsxRuntime.jsx(Typography, {
              level: "body-sm",
              sx: {
                fontStyle: "italic"
              },
              textColor: "text.secondary",
              children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManageReservationText, tempContext))
            }) : null;
    }
    
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h3",
                      textColor: "text.tertiary",
                      children: Caml_option.some(Txt$Thin.BookingDetails.billingContactHeading.value)
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: {
                        xs: "column",
                        md: "row"
                      },
                      justifyContent: "space-between",
                      spacing: {
                        xs: 2,
                        md: 0
                      },
                      children: [
                        JsxRuntime.jsxs(List, {
                              children: [
                                JsxRuntime.jsxs(ListItem, {
                                      children: [
                                        JsxRuntime.jsx(ListItemDecorator, {
                                              children: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {}))
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              noWrap: true,
                                              sx: {
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              },
                                              textColor: "inherit",
                                              children: Caml_option.some(bc.preferredName)
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                                                      children: [
                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                              children: Caml_option.some(JsxRuntime.jsx(MailOutline, {}))
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                              },
                                                              textColor: "inherit",
                                                              children: Caml_option.some(bc.email)
                                                            })
                                                      ],
                                                      onClick: (function (param) {
                                                          Common$Thin.copyToClipboard(bc.email, setCopied);
                                                        })
                                                    }))
                                          }),
                                      arrow: true,
                                      disableHoverListener: true,
                                      open: match[0],
                                      size: "sm",
                                      title: Caml_option.some(Txt$Thin.Clipboard.copied.value)
                                    })
                              ],
                              size: "sm",
                              sx: {
                                maxWidth: "fit-content",
                                minWidth: 0
                              }
                            }),
                        JsxRuntime.jsx(Stack, {
                              sx: {
                                display: "flex",
                                minWidth: "fit-content",
                                justifyContent: {
                                  xs: "flex-start",
                                  md: "flex-end"
                                },
                                alignItems: {
                                  xs: "flex-start",
                                  md: "flex-end"
                                }
                              },
                              children: Caml_option.some(null)
                            })
                      ]
                    }),
                tmp
              ]
            });
}

var BillingContactInfo = {
  make: BDStateroomDetails$BillingContactInfo
};

function BDStateroomDetails(props) {
  var data = use(props.fragmentRefs);
  var match = React.useState(function () {
        
      });
  var bc_email = CS_Emails$Util.Email.toString(data.billingContactEmail);
  var bc_preferredName = data.billingContactPreferredName;
  var bc = {
    email: bc_email,
    preferredName: bc_preferredName
  };
  var cmt = data.cabinPlacementComment;
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.300",
                height: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "h3",
                                component: "h2",
                                fontWeight: "md",
                                children: Caml_option.some(Txt$Thin.BookingDetails.stateroomDetailsHeading.value)
                              }),
                          JsxRuntime.jsx(BDStateroomDetails$StateroomInfo, {
                                fragmentRefs: data.fragmentRefs
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column",
                                  md: "row"
                                },
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(BDStateroomDetails$BillingContactInfo, {
                                        bc: bc,
                                        draftBc: match[0],
                                        bookingId: props.bookingId,
                                        canEdit: props.canEdit,
                                        draftMode: props.draftMode,
                                        paxManagementLocked: data.paxManagementLocked,
                                        prefManagementLocked: data.prefManagementLocked
                                      }),
                                  cmt !== undefined ? JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  level: "h4",
                                                  component: "h3",
                                                  textColor: "text.tertiary",
                                                  children: "About Your Stateroom Placement"
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  textColor: "inherit",
                                                  children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(cmt)))
                                                })
                                          ]
                                        }) : null
                                ]
                              })
                        ]
                      }))
            });
}

var make = BDStateroomDetails;

exports.DetailFragment = DetailFragment;
exports.StateroomInfoFragment = StateroomInfoFragment;
exports.StateroomInfo = StateroomInfo;
exports.BillingContactInfo = BillingContactInfo;
exports.make = make;
/* react Not a pure module */
