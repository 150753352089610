// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CurrentUserFragment_graphql$Thin = require("./CurrentUserFragment_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"currentUserProfiles_nodes_pendingVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserProfiles_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_eligibleVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserProfiles_nodes_allBookedVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserConnectedAccounts_totalCount":{"b":""}}};

var fragmentConverterMap = {
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function custSignonStatus_decode($$enum) {
  if ($$enum === "VERIFY_EMAIL" || $$enum === "INVITED" || $$enum === "PENDING_2FA" || $$enum === "CLEAR_JWT" || $$enum === "ANONYMOUS" || $$enum === "SIGNED_ON") {
    return $$enum;
  }
  
}

function custSignonStatus_fromString(str) {
  return custSignonStatus_decode(str);
}

var Utils = {
  custSignonStatus_decode: custSignonStatus_decode,
  custSignonStatus_fromString: custSignonStatus_fromString
};

function makeNode(rescript_graphql_node_CurrentUserFragment) {
  return ({
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_CurrentUserFragment
    }
  },
  "name": "CurrentUser_frag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUserProfilesConnection",
      "kind": "LinkedField",
      "name": "currentUserProfiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserProfile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingVoyageSlugs",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eligibleVoyageSlugs",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allBookedVoyageSlugs",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUserConnectedAccountsConnection",
      "kind": "LinkedField",
      "name": "currentUserConnectedAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(CurrentUserFragment_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
