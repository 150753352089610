// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thin = require("../RelayEnv.bs.js");
var RelayRuntime = require("relay-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CurrentUser_frag_graphql$Thin = require("../__generated__/CurrentUser_frag_graphql.bs.js");
var CurrentUserFragment_graphql$Thin = require("../__generated__/CurrentUserFragment_graphql.bs.js");

var convertFragment = CurrentUser_frag_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CurrentUser_frag_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CurrentUser_frag_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CurrentUser_frag_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = CurrentUserFragment_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = CurrentUserFragment_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CurrentUser_frag_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var CurrentUserFragment_custSignonStatus_decode = CurrentUser_frag_graphql$Thin.Utils.custSignonStatus_decode;

var CurrentUserFragment_custSignonStatus_fromString = CurrentUser_frag_graphql$Thin.Utils.custSignonStatus_fromString;

var CurrentUserFragment = {
  custSignonStatus_decode: CurrentUserFragment_custSignonStatus_decode,
  custSignonStatus_fromString: CurrentUserFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var signedOnStatuses = [
  "SIGNED_ON",
  "VERIFY_EMAIL"
];

function useQuery(fragmentRefs) {
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var signOnStatus = query.currentUserSignonStatus;
  var refetchSignOnStatus = function (param) {
    return refetch(makeRefetchVariables(), "store-and-network", undefined);
  };
  var prof = Belt_Option.flatMap(query.currentUserProfiles, (function (param) {
          return Belt_Array.get(param.nodes, 0);
        }));
  var match$1 = query.currentUserEmail;
  var displayName;
  if (prof !== undefined) {
    var firstName = prof.firstName;
    if (firstName !== undefined) {
      var lastName = prof.lastName;
      var firstName$1 = Caml_option.valFromOption(firstName);
      displayName = lastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName$1);
    } else {
      var oneName = prof.lastName;
      displayName = oneName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(oneName)) : (
          match$1 !== undefined ? match$1 : "My account"
        );
    }
  } else {
    displayName = match$1 !== undefined ? match$1 : "My account";
  }
  var email = query.currentUserEmail;
  var email$1 = email !== undefined ? email : "";
  var mapVoyageSlugArray = function (vsarr) {
    return Belt_Array.keepMap(Belt_Option.getWithDefault(vsarr, []), (function (a) {
                  return a;
                }));
  };
  var match$2 = Belt_Option.getWithDefault(Belt_Option.map(prof, (function (param) {
              return [
                      mapVoyageSlugArray(param.pendingVoyageSlugs),
                      mapVoyageSlugArray(param.eligibleVoyageSlugs),
                      mapVoyageSlugArray(param.allBookedVoyageSlugs)
                    ];
            })), [
        [],
        [],
        []
      ]);
  var pendingVoyageSlugs = Belt_Set.fromArray(match$2[0], CS_Slugs$Util.VoyageSlug.Comparable);
  var eligibleVoyageSlugs = Belt_Set.fromArray(match$2[1], CS_Slugs$Util.VoyageSlug.Comparable);
  var allBookedVoyageSlugs = Belt_Set.fromArray(match$2[2], CS_Slugs$Util.VoyageSlug.Comparable);
  var match$3 = query.currentUserConnectedAccounts;
  return {
          signOnStatus: signOnStatus,
          isLoggedIn: signOnStatus === "SIGNED_ON" || signOnStatus === "VERIFY_EMAIL",
          refetchSignOnStatus: refetchSignOnStatus,
          hasConnectedAccounts: match$3 !== undefined ? match$3.totalCount > 0 : false,
          displayName: displayName,
          email: email$1,
          userContext: {
            userEmail: email$1
          },
          pendingVoyageSlugs: pendingVoyageSlugs,
          eligibleVoyageSlugs: eligibleVoyageSlugs,
          allBookedVoyageSlugs: allBookedVoyageSlugs,
          canBookVoyage: (function (vs) {
              return !Belt_Set.has(allBookedVoyageSlugs, vs);
            }),
          logout: (function () {
              Common$Thin.cookieLogout(Caml_option.some((function () {
                          refetchSignOnStatus();
                          RelayRuntime.commitLocalUpdate(RelayEnv$Thin.environment, (function (store) {
                                  store.invalidateStore();
                                }));
                          RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
                        })), undefined);
            })
        };
}

var defaultContextWhichIsBroken = React.createContext("ERROR");

function use$1() {
  return React.useContext(defaultContextWhichIsBroken);
}

var make = defaultContextWhichIsBroken.Provider;

var Provider = {
  make: make
};

var Context = {
  defaultContextWhichIsBroken: defaultContextWhichIsBroken,
  use: use$1,
  Provider: Provider
};

exports.CurrentUserFragment = CurrentUserFragment;
exports.signedOnStatuses = signedOnStatuses;
exports.useQuery = useQuery;
exports.Context = Context;
/* defaultContextWhichIsBroken Not a pure module */
