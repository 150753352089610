// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Check = require("@mui/icons-material/Check").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function SalesStep$Stepper(props) {
  var onClose = props.onClose;
  var canNavigate = props.canNavigate;
  var url = props.url;
  var urlState = props.urlState;
  var navTarget = props.navTarget;
  var status = props.status;
  var tmp;
  switch (status) {
    case "NotStarted" :
    case "InProgress" :
        tmp = "neutral";
        break;
    case "Completed" :
        tmp = "primary";
        break;
    
  }
  var tmp$1;
  switch (status) {
    case "NotStarted" :
    case "InProgress" :
        tmp$1 = null;
        break;
    case "Completed" :
        tmp$1 = JsxRuntime.jsx(Check, {});
        break;
    
  }
  var tmp$2;
  switch (status) {
    case "NotStarted" :
        tmp$2 = "outlined";
        break;
    case "InProgress" :
    case "Completed" :
        tmp$2 = "solid";
        break;
    
  }
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some(props.name),
              color: tmp,
              onClick: (function (e) {
                  e.stopPropagation();
                  if (canNavigate) {
                    SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(urlState, navTarget)), url);
                  }
                  onClose();
                }),
              size: "sm",
              startDecorator: Caml_option.some(tmp$1),
              variant: tmp$2,
              sx: {
                borderRadius: "4px",
                opacity: status === "NotStarted" ? "0.5" : "1",
                button: {
                  "&:hover": {
                    backgroundColor: status === "NotStarted" ? "transparent" : "",
                    cursor: status === "NotStarted" ? "not-allowed" : "pointer"
                  }
                }
              }
            });
}

var Stepper = {
  make: SalesStep$Stepper
};

function SalesStep(props) {
  var shouldOpenDrawerOnClick = props.shouldOpenDrawerOnClick;
  var onClose = props.onClose;
  var validNavStates = props.validNavStates;
  var url = props.url;
  var urlState = props.urlState;
  var __targetingCriteria = props.targetingCriteria;
  var __showSteppers = props.showSteppers;
  var stepStatus = props.stepStatus;
  var step = props.step;
  var showSteppers = __showSteppers !== undefined ? __showSteppers : false;
  var targetingCriteria = __targetingCriteria !== undefined ? __targetingCriteria : [];
  var eligibleTargets = Belt_Set.toArray(props.validNavTargets(validNavStates));
  var target = Belt_Array.get(targetingCriteria, 0);
  var fullStepSelectableTarget = target !== undefined && Belt_Array.some(eligibleTargets, (function (t) {
          return Caml_obj.equal(t, target);
        })) ? target : undefined;
  var match;
  switch (props.flow) {
    case "ByCategory" :
        match = [
          SFState$Thin.ByCategory.stepIsComplete,
          SFState$Thin.ByCategory.isCurrentStep,
          SFState$Thin.ByCategory.stepToSteppers
        ];
        break;
    case "AllNoCabin" :
        match = [
          SFState$Thin.ByAllNoCabin.stepIsComplete,
          SFState$Thin.ByAllNoCabin.isCurrentStep,
          SFState$Thin.ByAllNoCabin.stepToSteppers
        ];
        break;
    case "All" :
        match = [
          SFState$Thin.ByAll.stepIsComplete,
          SFState$Thin.ByAll.isCurrentStep,
          SFState$Thin.ByAll.stepToSteppers
        ];
        break;
    
  }
  var isCurrentStep = match[1];
  var stepIsComplete = match[0];
  var substeps = match[2](step);
  var substeps$1 = substeps !== undefined && showSteppers ? substeps : undefined;
  var tmp;
  switch (stepStatus) {
    case "NotStarted" :
        tmp = JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsx(Box, {
                        sx: {
                          backgroundColor: "white",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%"
                        }
                      })),
              sx: {
                width: "24px !important",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: "neutral.300",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            });
        break;
    case "InProgress" :
        tmp = JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsx(Box, {
                        children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                  fontSize: "md",
                                  sx: {
                                    color: "white"
                                  }
                                })),
                        sx: {
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          backgroundColor: "primary.500",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }
                      })),
              sx: {
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                p: "1px",
                backgroundColor: "white",
                border: "1px solid",
                borderColor: "primary.500"
              }
            });
        break;
    case "Completed" :
        tmp = JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsx(Check, {
                        fontSize: "md",
                        sx: {
                          color: "white"
                        }
                      })),
              sx: {
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: "primary.500",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            });
        break;
    
  }
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "center",
              sx: {
                cursor: fullStepSelectableTarget === undefined ? "not-allowed" : "pointer",
                borderColor: "neutral.300",
                borderRight: {
                  xs: "none",
                  md: step !== 4 ? "1px solid #CDD7E1" : "none"
                },
                px: {
                  xs: 0,
                  sm: 1,
                  md: 4
                },
                height: "100%"
              },
              onClick: (function (param) {
                  if (fullStepSelectableTarget !== undefined && Belt_Array.some(eligibleTargets, (function (t) {
                            return Caml_obj.equal(t, fullStepSelectableTarget);
                          })) && !shouldOpenDrawerOnClick) {
                    SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(urlState, fullStepSelectableTarget)), url);
                  }
                  onClose();
                }),
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        sx: {
                          overflow: "hidden"
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                spacing: 1,
                                children: [
                                  tmp,
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        sx: {
                                          flex: 1,
                                          overflowX: "hidden"
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                textTransform: "uppercase",
                                                fontWeight: "300",
                                                children: Caml_option.some("Step " + String(step) + " of " + String(props.totalStepNumber))
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                noWrap: true,
                                                sx: {
                                                  textOverflow: "ellipsis"
                                                },
                                                children: Caml_option.some(props.title)
                                              }),
                                          substeps$1 !== undefined ? JsxRuntime.jsx(Stack, {
                                                  direction: "row",
                                                  alignItems: "center",
                                                  flexWrap: "wrap",
                                                  children: Caml_option.some(Belt_Array.mapWithIndex(substeps$1, (function (idx, param) {
                                                              var params = param.params;
                                                              var nav = param.nav;
                                                              var match = Belt_Array.every(params, (function (p) {
                                                                      return stepIsComplete(p, validNavStates);
                                                                    }));
                                                              var match$1 = Belt_Array.some(params, (function (p) {
                                                                      return isCurrentStep(p, validNavStates);
                                                                    }));
                                                              return JsxRuntime.jsxs(Stack, {
                                                                          direction: "row",
                                                                          sx: {
                                                                            py: "5px"
                                                                          },
                                                                          children: [
                                                                            JsxRuntime.jsx(SalesStep$Stepper, {
                                                                                  name: param.title,
                                                                                  status: match ? "Completed" : (
                                                                                      match$1 ? "InProgress" : "NotStarted"
                                                                                    ),
                                                                                  navTarget: nav,
                                                                                  urlState: urlState,
                                                                                  url: url,
                                                                                  canNavigate: Belt_Array.some(eligibleTargets, (function (t) {
                                                                                          return Caml_obj.equal(t, nav);
                                                                                        })),
                                                                                  onClose: onClose
                                                                                }, String(idx)),
                                                                            idx !== (substeps$1.length - 1 | 0) ? JsxRuntime.jsx(Stack, {
                                                                                    component: "span",
                                                                                    sx: {
                                                                                      fontSize: "0.7rem",
                                                                                      mx: "4px",
                                                                                      color: "neutral.400"
                                                                                    },
                                                                                    children: "▸"
                                                                                  }) : null
                                                                          ]
                                                                        }, String(idx));
                                                            })))
                                                }) : null
                                        ]
                                      })
                                ]
                              }),
                          stepStatus === "InProgress" && props.isMobile ? JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  sx: {
                                    display: {
                                      xs: "flex",
                                      md: "none"
                                    }
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                            children: "CURRENT",
                                            size: "sm",
                                            sx: {
                                              backgroundColor: "neutral.200",
                                              color: "neutral.500",
                                              borderRadius: "4px"
                                            }
                                          }))
                                }) : null
                        ]
                      }))
            });
}

var make = SalesStep;

exports.Stepper = Stepper;
exports.make = make;
/* @mui/joy/Box Not a pure module */
