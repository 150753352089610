// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var URL$Util = require("util/src/URL.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function AppLink(props) {
  var __textColor = props.textColor;
  var __underline = props.underline;
  var __color = props.color;
  var onClick = props.onClick;
  var href = props.to;
  var color = __color !== undefined ? __color : "primary";
  var underline = __underline !== undefined ? __underline : "hover";
  var textColor = __textColor !== undefined ? __textColor : "";
  var match = URL$Util.parse(Belt_Option.getWithDefault(href, ""));
  var isExternal = match !== undefined ? match.host !== "localhost" : false;
  var isTargetBlank = Belt_Option.getWithDefault(props.forceTargetBlank, false);
  return JsxRuntime.jsx(Joy$Util.Link.make, {
              children: props.children,
              href: Belt_Option.getWithDefault(href, ""),
              underline: underline,
              textColor: textColor,
              color: color,
              target: isExternal || isTargetBlank ? "_blank" : "",
              onClick: (function (e) {
                  if (onClick !== undefined) {
                    onClick(e);
                    return ;
                  } else if (isExternal || isTargetBlank || href === undefined) {
                    return ;
                  } else {
                    e.preventDefault();
                    RescriptReactRouter.push(href);
                    return ;
                  }
                })
            });
}

var make = AppLink;

exports.make = make;
/* Joy-Util Not a pure module */
