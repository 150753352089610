// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Menu = require("@mui/joy/Menu").default;
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var AddPassDrawer$Thin = require("./add-passenger/AddPassDrawer.bs.js");
var CheckValidity$Thin = require("./drafts/CheckValidity.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thin = require("./molecules/BDContentBlock.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDPatchPaxDrawer$Thin = require("./BDPatchPaxDrawer.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Add = require("@mui/icons-material/Add").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CopyableWithTooltip$Thin = require("../common/atoms/CopyableWithTooltip.bs.js");
var ResendPaxInvitation$Thin = require("./drafts/mutations/ResendPaxInvitation.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var RemoveSelfOrConnected$Thin = require("./RemoveSelfOrConnected.bs.js");
var Warning = require("@mui/icons-material/Warning").default;
var ModeEdit = require("@mui/icons-material/ModeEdit").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var BedOutlined = require("@mui/icons-material/BedOutlined").default;
var ErrorOutline = require("@mui/icons-material/ErrorOutline").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var ResendInvitationConfirmModal$Thin = require("./drafts/ResendInvitationConfirmModal.bs.js");
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var BDPassengerList_detail_graphql$Thin = require("../../__generated__/BDPassengerList_detail_graphql.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var PersonAddOutlined = require("@mui/icons-material/PersonAddOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var PersonRemoveOutlined = require("@mui/icons-material/PersonRemoveOutlined").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;
var SendAndArchiveOutlined = require("@mui/icons-material/SendAndArchiveOutlined").default;
var BDPassengerList_addberthdetail_graphql$Thin = require("../../__generated__/BDPassengerList_addberthdetail_graphql.bs.js");
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;

var convertFragment = BDPassengerList_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_detail_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDPassengerList_detail_graphql$Thin.node, convertFragment, fRef);
}

var DetailFragment_docGender_decode = BDPassengerList_detail_graphql$Thin.Utils.docGender_decode;

var DetailFragment_docGender_fromString = BDPassengerList_detail_graphql$Thin.Utils.docGender_fromString;

var DetailFragment_paxDataStatus_decode = BDPassengerList_detail_graphql$Thin.Utils.paxDataStatus_decode;

var DetailFragment_paxDataStatus_fromString = BDPassengerList_detail_graphql$Thin.Utils.paxDataStatus_fromString;

var DetailFragment = {
  docGender_decode: DetailFragment_docGender_decode,
  docGender_fromString: DetailFragment_docGender_fromString,
  paxDataStatus_decode: DetailFragment_paxDataStatus_decode,
  paxDataStatus_fromString: DetailFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDPassengerList$PassengerMenu(props) {
  var autoOpenPaxDetailsDrawer = props.autoOpenPaxDetailsDrawer;
  var fareClassManagePaxBlocked = props.fareClassManagePaxBlocked;
  var bookingVersionNumber = props.bookingVersionNumber;
  var bookingId = props.bookingId;
  var voyageSlug = props.voyageSlug;
  var refetchBookingDetails = props.refetchBookingDetails;
  var isCurrentUserConnected = props.isCurrentUserConnected;
  var accountId = props.accountId;
  var getPreviewFromDiff = props.getPreviewFromDiff;
  var idx = props.idx;
  var isBc = props.isBc;
  var currentUserEmail = props.currentUserEmail;
  var pax = props.pax;
  var match = Belt_Option.map(props.paxEmail, CS_Emails$Util.Email.toPrimitive);
  var isCurrentUser = currentUserEmail !== undefined && match !== undefined ? currentUserEmail === match : false;
  var isManagedAccount = isCurrentUser || isCurrentUserConnected;
  var isEditing = props.draftMode && isBc;
  var isPrimaryPax = pax.idx === 1;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var match$1 = CheckValidity$Thin.useQuery(getPreviewFromDiff, url, undefined);
  var addOrErr = match$1.addOrErr;
  var setError = match$1.setError;
  var onRemovePax = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thin.Alter.detachSingleBerth(diff, idx);
    addOrErr(applyDiff);
  };
  var onDropBerth = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thin.Alter.dropSingleBerth(diff, idx);
    addOrErr(applyDiff);
  };
  var onRevokeInvitation = function (e) {
    e.preventDefault();
    var match = pax.paxStatus;
    if (typeof match !== "object") {
      console.log("Invalid status or accountId");
      return ;
    }
    if (match.TAG === "Status") {
      var tmp = match._0;
      if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
        if (tmp === "INVITED") {
          if (accountId !== undefined) {
            var diff$1 = BookingDiff$Thin.Alter.revokePaxInvitation(diff, idx, Caml_option.valFromOption(accountId));
            return addOrErr(diff$1);
          }
          console.log("Invalid status or accountId");
          return ;
        }
        console.log("Invalid status or accountId");
        return ;
      } else {
        console.log("Invalid status or accountId");
        return ;
      }
    } else {
      console.log("Invalid status or accountId");
      return ;
    }
  };
  var match$2 = React.useState(function () {
        return "Attach";
      });
  var setDraftAction = match$2[1];
  var draftAction = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setPaxDetailsOpen = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setPatchPaxDrawerOpen = match$4[1];
  React.useEffect((function () {
          if (autoOpenPaxDetailsDrawer) {
            setPaxDetailsOpen(function (param) {
                  return true;
                });
          }
          
        }), [autoOpenPaxDetailsDrawer]);
  var addPassenger = function (a) {
    setDraftAction(function (param) {
          return a;
        });
    setPaxDetailsOpen(function (param) {
          return true;
        });
  };
  var match$5 = RemoveSelfOrConnected$Thin.useQuery();
  var previewRemove = match$5.previewRemove;
  var setPaxRemoveModalOpen = match$5.setPaxRemoveModalOpen;
  var setFee = match$5.setFee;
  var setQueryError = match$5.setError;
  var match$6 = React.useState(function () {
        return false;
      });
  var setResendConfirmationOpen = match$6[1];
  var match$7 = ResendPaxInvitation$Thin.useMutation(setError, (function () {
          setResendConfirmationOpen(function (param) {
                return true;
              });
          refetchBookingDetails();
        }));
  var resendInvitation = match$7.resendInvitation;
  var onResendInvitation = function (e) {
    e.preventDefault();
    var match = pax.paxStatus;
    if (typeof match !== "object") {
      console.log("Invalid status or accountId");
      return ;
    }
    if (match.TAG === "Status") {
      var tmp = match._0;
      if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
        if (tmp === "INVITED") {
          if (accountId !== undefined) {
            return resendInvitation(Caml_option.valFromOption(accountId));
          } else {
            console.log("Invalid status or accountId");
            return ;
          }
        }
        console.log("Invalid status or accountId");
        return ;
      } else {
        console.log("Invalid status or accountId");
        return ;
      }
    } else {
      console.log("Invalid status or accountId");
      return ;
    }
  };
  var match$8 = !props.paxManagementLocked;
  var match$9 = isManagedAccount || isEditing;
  if (!props.isActive) {
    return null;
  }
  if (!match$8) {
    return null;
  }
  if (!match$9) {
    return null;
  }
  var match$10 = !isEditing;
  var match$11 = !isPrimaryPax;
  var match$12 = !fareClassManagePaxBlocked;
  var match$13 = RemoveSelfOrConnected$Thin.getPreviewVars(pax, bookingId, bookingVersionNumber);
  var match$14 = Caml_obj.notequal(pax.paxStatus, {
        TAG: "Status",
        _0: "INVITED"
      });
  var match$15 = !fareClassManagePaxBlocked;
  var tmp;
  tmp = draftAction === "Attach" ? Txt$Thin.BookingDetails.addPassDrawerTitle.value : Txt$Thin.BookingDetails.replacePassDrawerTitle.value;
  var match$16 = pax.paxPatchPerm;
  var tmp$1;
  var exit = 0;
  switch (match$16) {
    case "Limited" :
    case "All" :
        exit = 1;
        break;
    case "None" :
        tmp$1 = null;
        break;
    
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx(BDPatchPaxDrawer$Thin.make, {
          open_: match$4[0],
          onClose: (function () {
              setPatchPaxDrawerOpen(function (param) {
                    return false;
                  });
            }),
          currentVoyage: voyageSlug,
          isBc: isBc,
          isSelf: isCurrentUser,
          isCurrentUserConnected: isCurrentUserConnected,
          pax: pax,
          getPreviewFromDiff: getPreviewFromDiff,
          refetchBookingDetails: refetchBookingDetails,
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          countryFrag: props.countryFrag
        });
  }
  var applyInput = RemoveSelfOrConnected$Thin.getApplyInput(pax, bookingId, bookingVersionNumber);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                                children: [
                                  JsxRuntime.jsx(MenuButton, {
                                        children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                        size: "md",
                                        slotProps: {
                                          root: {
                                            color: "neutral"
                                          }
                                        },
                                        slots: {
                                          root: IconButton
                                        }
                                      }),
                                  JsxRuntime.jsxs(Menu, {
                                        children: [
                                          Caml_obj.equal(pax.paxStatus, {
                                                TAG: "Status",
                                                _0: "INVITED"
                                              }) && isEditing ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    JsxRuntime.jsxs(MenuItem, {
                                                          children: [
                                                            JsxRuntime.jsx(ListItemDecorator, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(ScheduleSendOutlined, {}))
                                                                }),
                                                            Txt$Thin.BookingDetails.resendInvitation.value
                                                          ],
                                                          onClick: onResendInvitation
                                                        }, Txt$Thin.BookingDetails.resendInvitation.value),
                                                    fareClassManagePaxBlocked ? null : JsxRuntime.jsxs(MenuItem, {
                                                            children: [
                                                              JsxRuntime.jsx(ListItemDecorator, {
                                                                    children: Caml_option.some(JsxRuntime.jsx(PersonAddDisabledOutlined, {
                                                                              sx: {
                                                                                color: "danger.500"
                                                                              }
                                                                            }))
                                                                  }),
                                                              Txt$Thin.BookingDetails.revokeInvitation.value
                                                            ],
                                                            onClick: onRevokeInvitation
                                                          }, Txt$Thin.BookingDetails.revokeInvitation.value)
                                                  ]
                                                }) : (
                                              pax.paxStatus === "TBA" && isEditing && !fareClassManagePaxBlocked ? JsxRuntime.jsxs(MenuItem, {
                                                      children: [
                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                              children: Caml_option.some(JsxRuntime.jsx(PersonAddOutlined, {}))
                                                            }),
                                                        Txt$Thin.BookingDetails.addPassAddPassenger.value
                                                      ],
                                                      onClick: (function (param) {
                                                          addPassenger("Attach");
                                                        })
                                                    }, Txt$Thin.BookingDetails.addPassAddPassenger.value) : null
                                            ),
                                          !isEditing && isManagedAccount || isEditing && pax.paxStatus !== "TBA" && Caml_obj.notequal(pax.paxStatus, {
                                                TAG: "Status",
                                                _0: "INVITED"
                                              }) ? JsxRuntime.jsxs(MenuItem, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(ModeEdit, {}))
                                                        }),
                                                    Txt$Thin.BookingDetails.editDetails.value
                                                  ],
                                                  onClick: (function (param) {
                                                      setPatchPaxDrawerOpen(function (param) {
                                                            return true;
                                                          });
                                                    })
                                                }, Txt$Thin.BookingDetails.editDetails.value) : null,
                                          isManagedAccount && match$10 && match$11 && match$12 && match$13 !== undefined ? JsxRuntime.jsxs(MenuItem, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DoNotDisturbAlt, {
                                                                    sx: {
                                                                      color: "danger.500"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "inherit",
                                                          children: Caml_option.some(isCurrentUser ? Txt$Thin.BookingDetails.removeMyself.value : "Remove Passenger")
                                                        })
                                                  ],
                                                  onClick: (function (param) {
                                                      previewRemove(match$13);
                                                    })
                                                }, "remove-pax") : null,
                                          match$14 && isEditing && match$15 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    pax.paxStatus !== "TBA" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                            children: [
                                                              JsxRuntime.jsxs(MenuItem, {
                                                                    children: [
                                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                                            children: Caml_option.some(JsxRuntime.jsx(PersonRemoveOutlined, {}))
                                                                          }),
                                                                      Txt$Thin.BookingDetails.replacePassenger.value
                                                                    ],
                                                                    onClick: (function (param) {
                                                                        addPassenger("Replace");
                                                                      })
                                                                  }, Txt$Thin.BookingDetails.replacePassenger.value + "-bc"),
                                                              isPrimaryPax ? null : JsxRuntime.jsxs(MenuItem, {
                                                                      children: [
                                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                                              children: Caml_option.some(JsxRuntime.jsx(DoNotDisturbAlt, {
                                                                                        sx: {
                                                                                          color: "danger.500"
                                                                                        }
                                                                                      }))
                                                                            }),
                                                                        JsxRuntime.jsx(Typography, {
                                                                              level: "inherit",
                                                                              children: Caml_option.some(isCurrentUser ? Txt$Thin.BookingDetails.removeMyself.value : Txt$Thin.BookingDetails.detachPassenger.value)
                                                                            })
                                                                      ],
                                                                      onClick: onRemovePax
                                                                    }, "detach-pax")
                                                            ]
                                                          }) : null,
                                                    isPrimaryPax ? null : JsxRuntime.jsx(JsxRuntime.Fragment, {
                                                            children: Caml_option.some(JsxRuntime.jsxs(MenuItem, {
                                                                      children: [
                                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                                              children: Caml_option.some(JsxRuntime.jsx(BedOutlined, {}))
                                                                            }),
                                                                        Txt$Thin.BookingDetails.dropBerth.value
                                                                      ],
                                                                      disabled: props.cantDrop,
                                                                      onClick: onDropBerth
                                                                    }, Txt$Thin.BookingDetails.dropBerth.value + "-bc"))
                                                          })
                                                  ]
                                                }) : null
                                        ],
                                        placement: "bottom-start"
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(AddPassDrawer$Thin.make, {
                      open_: match$3[0],
                      title: tmp,
                      onClose: (function () {
                          setPaxDetailsOpen(function (param) {
                                return false;
                              });
                        }),
                      areConnectedAccts: props.areConnectedAccts,
                      action: draftAction,
                      idx: idx,
                      custodianAccountId: props.custodianAccountId,
                      getPreviewFromDiff: getPreviewFromDiff,
                      voyageSlug: voyageSlug,
                      isPrimaryPax: isPrimaryPax,
                      pax1MustBeBornBy: props.pax1MustBeBornBy,
                      showFillInLater: autoOpenPaxDetailsDrawer,
                      queryFrag: props.queryFrag
                    }),
                tmp$1,
                JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                      error: match$1.error,
                      setError: setError
                    }),
                JsxRuntime.jsx(ResendInvitationConfirmModal$Thin.make, {
                      open_: match$6[0],
                      onClose: (function () {
                          setResendConfirmationOpen(function (param) {
                                return false;
                              });
                        })
                    }),
                applyInput !== undefined ? JsxRuntime.jsx(RemoveSelfOrConnected$Thin.Modal.make, {
                        open_: match$5.paxRemoveModalOpen,
                        onClose: (function () {
                            setFee(function (param) {
                                  
                                });
                            setQueryError(function (param) {
                                  
                                });
                            setPaxRemoveModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        applyInput: applyInput,
                        onSuccess: (function () {
                            refetchBookingDetails();
                          }),
                        fee: match$5.fee,
                        queryError: match$5.error,
                        name: pax.preferredFullName,
                        isSelf: isCurrentUser
                      }) : null
              ]
            });
}

var PassengerMenu = {
  make: BDPassengerList$PassengerMenu
};

function BDPassengerList$BDPassengerTableRow(props) {
  var __justAddedBerth = props.justAddedBerth;
  var __isLastTableRow = props.isLastTableRow;
  var pax = props.pax;
  var isLastTableRow = __isLastTableRow !== undefined ? __isLastTableRow : false;
  var justAddedBerth = __justAddedBerth !== undefined ? __justAddedBerth : false;
  var paxStatus = pax.paxStatus;
  var email = pax.email;
  var preferredFullName = pax.preferredFullName;
  var match;
  var exit = 0;
  if (typeof paxStatus !== "object") {
    match = [
      Txt$Thin.BookingDetails.plTBA.value,
      "warningSecondary",
      JsxRuntime.jsx(Warning, {
            color: "warningSecondary"
          })
    ];
  } else if (paxStatus.TAG === "Status") {
    var tmp = paxStatus._0;
    if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
      switch (tmp) {
        case "CONFIRMED" :
            match = [
              Txt$Thin.BookingDetails.plConfirmed.value,
              "success",
              JsxRuntime.jsx(CheckCircleOutline, {
                    color: "success"
                  })
            ];
            break;
        case "INCOMPLETE" :
            exit = 1;
            break;
        case "INVITED" :
            match = [
              Txt$Thin.BookingDetails.plInvited.value,
              "secondary",
              JsxRuntime.jsx(SendAndArchiveOutlined, {
                    color: "secondary"
                  })
            ];
            break;
        
      }
    } else {
      exit = 1;
    }
  } else {
    switch (paxStatus._0) {
      case "Invite" :
          match = [
            Txt$Thin.BookingDetails.plInviteOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      case "Replace" :
          match = [
            Txt$Thin.BookingDetails.plReplaceOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      case "Attach" :
          match = [
            Txt$Thin.BookingDetails.plAttachOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      
    }
  }
  if (exit === 1) {
    match = [
      Txt$Thin.BookingDetails.plUnknown.value,
      "danger",
      JsxRuntime.jsx(ErrorOutline, {
            color: "danger"
          })
    ];
  }
  var match$1 = Belt_Option.map(pax.docFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$2 = Belt_Option.map(pax.docLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$3 = Belt_Option.map(pax.preferredFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$4 = Belt_Option.map(pax.preferredLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$5;
  if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined && (match$1 !== match$3 || match$2 !== match$4)) {
    var legalName = match$1 + " " + match$2;
    match$5 = [
      true,
      legalName
    ];
  } else {
    match$5 = [
      false,
      ""
    ];
  }
  var idx = pax.shouldAttachToIdx;
  var idx$1 = idx !== undefined ? idx : pax.idx;
  var autoOpenPaxDetailsDrawer = paxStatus === "TBA" && justAddedBerth && isLastTableRow;
  var accountId = pax.accountId;
  var tmp$1;
  var exit$1 = 0;
  if (preferredFullName !== undefined) {
    var exit$2 = 0;
    if (typeof paxStatus !== "object") {
      exit$1 = 1;
    } else {
      exit$2 = 2;
    }
    if (exit$2 === 2) {
      tmp$1 = JsxRuntime.jsxs(Stack, {
            direction: "row",
            alignItems: "center",
            sx: {
              display: "flex",
              width: "100%"
            },
            children: [
              JsxRuntime.jsx(Tooltip, {
                    children: JsxRuntime.jsx(Typography, {
                          level: "inherit",
                          noWrap: true,
                          sx: {
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          },
                          textColor: "inherit",
                          fontWeight: "inherit",
                          children: Caml_option.some(preferredFullName)
                        }),
                    arrow: true,
                    size: "sm",
                    title: Caml_option.some(preferredFullName)
                  }),
              JsxRuntime.jsxs(Stack, {
                    direction: "row",
                    sx: {
                      flex: 1
                    },
                    children: [
                      match$5[0] ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    children: Caml_option.some(JsxRuntime.jsx("sup", {
                                              children: JsxRuntime.jsx(InfoOutlined, {
                                                    color: "info",
                                                    fontSize: "inherit"
                                                  })
                                            }))
                                  }),
                              arrow: true,
                              placement: "top",
                              size: "sm",
                              sx: {
                                position: "relative",
                                left: 2,
                                justifySelf: "flex-start"
                              },
                              title: Caml_option.some("Traveling as " + match$5[1])
                            }) : null,
                      pax.idx === 1 ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    color: "danger",
                                    level: "body-lg",
                                    children: Caml_option.some(JsxRuntime.jsx("sup", {
                                              children: "*"
                                            }))
                                  }),
                              arrow: true,
                              placement: "top",
                              size: "sm",
                              sx: {
                                position: "relative",
                                left: 4,
                                bottom: 2,
                                justifySelf: "flex-start"
                              },
                              title: "Primary Passenger"
                            }) : null
                    ]
                  })
            ]
          });
    }
    
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
        });
  }
  var loyaltyNumber = pax.loyaltyNumber;
  var tmp$2;
  if (loyaltyNumber !== undefined) {
    var loyaltyNumber$1 = Caml_option.valFromOption(loyaltyNumber);
    tmp$2 = JsxRuntime.jsxs(Stack, {
          direction: "row",
          alignItems: "center",
          spacing: 1,
          sx: {
            overflow: "hidden"
          },
          children: [
            JsxRuntime.jsx(Tooltip, {
                  children: JsxRuntime.jsx(Typography, {
                        level: "inherit",
                        noWrap: true,
                        sx: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "fit-content"
                        },
                        textColor: "inherit",
                        fontWeight: "inherit",
                        children: Caml_option.some(CS_NonemptyStrings$Util.LoyaltyNumber.toString(loyaltyNumber$1))
                      }),
                  arrow: true,
                  size: "sm",
                  sx: {
                    width: "fit-content"
                  },
                  title: Caml_option.some(CS_NonemptyStrings$Util.LoyaltyNumber.toString(loyaltyNumber$1))
                }),
            JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                  value: CS_NonemptyStrings$Util.LoyaltyNumber.toString(loyaltyNumber$1),
                  label: "Loyalty Number"
                })
          ]
        });
  } else {
    tmp$2 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: "Loyalty Number"
        });
  }
  var tier = pax.loyaltyTier;
  var tmp$3;
  if (tier !== undefined) {
    var tierText = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier)) + " (" + String(pax.numLoyaltyVoyages) + ")";
    tmp$3 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Typography, {
                level: "inherit",
                noWrap: true,
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "fit-content"
                },
                textColor: "inherit",
                fontWeight: "inherit",
                children: Caml_option.some(tierText)
              }),
          arrow: true,
          size: "sm",
          sx: {
            width: "fit-content"
          },
          title: Caml_option.some(tierText)
        });
  } else {
    tmp$3 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: "Loyalty Tier"
        });
  }
  var date = Belt_Option.map(pax.birthdate, (function (v) {
          return DateOnly$Util.format(v, SysCtx$Thin.vars.dateFormat);
        }));
  var match$6 = Belt_Option.map(email, CS_Emails$Util.Email.toPrimitive);
  var tmp$4;
  var exit$3 = 0;
  if (match$6 !== undefined && !(typeof paxStatus !== "object" || paxStatus.TAG !== "Status")) {
    tmp$4 = JsxRuntime.jsxs(Stack, {
          direction: "row",
          alignItems: "center",
          spacing: 1,
          sx: {
            overflow: "hidden"
          },
          children: [
            JsxRuntime.jsx(Tooltip, {
                  children: JsxRuntime.jsx(Typography, {
                        level: "inherit",
                        noWrap: true,
                        sx: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "fit-content"
                        },
                        textColor: "inherit",
                        fontWeight: "inherit",
                        children: Caml_option.some(match$6)
                      }),
                  arrow: true,
                  size: "sm",
                  sx: {
                    width: "fit-content"
                  },
                  title: Caml_option.some(match$6)
                }),
            JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                  value: match$6,
                  label: "Email"
                })
          ]
        });
  } else {
    exit$3 = 1;
  }
  if (exit$3 === 1) {
    tmp$4 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
        });
  }
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(BDPassengerList$PassengerMenu, {
                            pax: pax,
                            currentUserEmail: props.currentUserEmail,
                            paxEmail: email,
                            isBc: props.isBc,
                            isActive: props.isActive,
                            areConnectedAccts: props.areConnectedAccts,
                            idx: idx$1,
                            custodianAccountId: props.custodianAccountId,
                            getPreviewFromDiff: props.getPreviewFromDiff,
                            cantDetach: props.cantDetach,
                            cantDrop: props.cantDrop,
                            accountId: pax.accountId,
                            draftMode: props.draftMode,
                            isCurrentUserConnected: accountId !== undefined ? Belt_Set.has(props.userConnected, Caml_option.valFromOption(accountId)) : false,
                            queryFrag: props.queryFrag,
                            countryFrag: props.countryFrag,
                            refetchBookingDetails: props.refetchBookingDetails,
                            voyageSlug: props.voyageSlug,
                            bookingId: props.bookingId,
                            bookingVersionNumber: props.bookingVersionNumber,
                            pax1MustBeBornBy: props.pax1MustBeBornBy,
                            paxManagementLocked: props.paxManagementLocked,
                            fareClassManagePaxBlocked: props.fareClassManagePaxBlocked,
                            autoOpenPaxDetailsDrawer: autoOpenPaxDetailsDrawer
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            spacing: 1,
                            children: [
                              match[2],
                              JsxRuntime.jsx(Typography, {
                                    color: match[1],
                                    level: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(match[0])
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp$1
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp$2
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp$3
                    }),
                JsxRuntime.jsx("td", {
                      children: date !== undefined ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "fit-content"
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(date)
                                  }),
                              arrow: true,
                              size: "sm",
                              sx: {
                                width: "fit-content"
                              },
                              title: Caml_option.some(date)
                            }) : JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              textColor: "primary.solidDisabledColor",
                              fontWeight: "inherit",
                              children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                            })
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp$4
                    })
              ]
            });
}

var BDPassengerTableRow = {
  make: BDPassengerList$BDPassengerTableRow
};

var convertFragment$1 = BDPassengerList_addberthdetail_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1, fRef);
}

var AddBerthFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function BDPassengerList$AddBerth(props) {
  var diff = props.diff;
  var data = use$1(props.fragmentRefs);
  var maxedOut = props.numBerths >= data.salesMaxBerth;
  var match = CheckValidity$Thin.useQuery(props.getPreviewFromDiff, props.url, undefined);
  var addOrErr = match.addOrErr;
  var onSubmit = function (param) {
    var applyDiff = BookingDiff$Thin.Alter.addSingleBerth(diff);
    addOrErr(applyDiff);
  };
  if (props.paxManagementLocked || props.fareClassManagePaxBlocked) {
    return null;
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Button, {
                                    loading: match.loading,
                                    onClick: onSubmit,
                                    endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                              fontSize: "lg"
                                            })),
                                    variant: "outlined",
                                    color: "neutral",
                                    disabled: maxedOut,
                                    children: Caml_option.some(Txt$Thin.BookingDetails.addBerth.value)
                                  })
                            }),
                        arrow: true,
                        disableHoverListener: !maxedOut,
                        size: "sm",
                        sx: {
                          maxWidth: "12rem"
                        },
                        title: Caml_option.some(Txt$Thin.BookingDetails.maxOccReached.value)
                      }),
                  JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                        error: match.error,
                        setError: match.setError
                      })
                ]
              });
  }
}

var AddBerth = {
  make: BDPassengerList$AddBerth
};

function BDPassengerList$PaxTable(props) {
  var title = props.title;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                title !== undefined ? JsxRuntime.jsx(Typography, {
                        level: "h4",
                        children: Caml_option.some(title)
                      }) : null,
                JsxRuntime.jsxs(Table, {
                      sx: {
                        width: {
                          xs: "100%"
                        },
                        "--Table-headerUnderlineThickness": "1px",
                        "& th": {
                          py: 1
                        },
                        "& td": {
                          py: 1
                        }
                      },
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            style: {
                                              width: "2rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plStatus.value)
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
                                                }),
                                            style: {
                                              width: "12rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: "Loyalty #"
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: "Tier"
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
                                                }),
                                            style: {
                                              width: "12rem"
                                            }
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: props.children
                            })
                      ]
                    })
              ]
            });
}

var PaxTable = {
  make: BDPassengerList$PaxTable
};

function BDPassengerList(props) {
  var fareClassManagePaxBlocked = props.fareClassManagePaxBlocked;
  var paxManagementLocked = props.paxManagementLocked;
  var numBerths = props.numBerths;
  var passengers = props.passengers;
  var bookingVersionNumber = props.bookingVersionNumber;
  var bookingId = props.bookingId;
  var voyageSlug = props.voyageSlug;
  var countryFrag = props.countryFrag;
  var draftMode = props.draftMode;
  var getPreviewFromDiff = props.getPreviewFromDiff;
  var custodianAccountId = props.custodianAccountId;
  var allConnected = props.allConnected;
  var areConnectedAccts = props.areConnectedAccts;
  var queryFrag = props.queryFrag;
  var refetchBookingDetails = props.refetch;
  var isActive = props.isActive;
  var isBc = props.isBc;
  var currentUserEmail = props.currentUserEmail;
  var fragmentRefs = props.fragmentRefs;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var cantDetach = Belt_Array.keep(passengers, (function (pax) {
          return Caml_obj.equal(pax.paxStatus, {
                      TAG: "Status",
                      _0: "CONFIRMED"
                    });
        })).length === 1;
  var cantDrop = props.salesMinBerth === numBerths;
  var titleComponent = props.canEdit && draftMode ? JsxRuntime.jsx(Stack, {
          sx: {
            display: {
              xs: "none",
              md: "flex"
            }
          },
          children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$AddBerth, {
                    fragmentRefs: fragmentRefs,
                    numBerths: numBerths,
                    diff: diff,
                    url: url,
                    paxManagementLocked: paxManagementLocked,
                    fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                    getPreviewFromDiff: getPreviewFromDiff
                  }))
        }) : null;
  var userConnected = allConnected !== undefined ? Belt_Set.fromArray(Belt_Array.map(allConnected.nodes, (function (node) {
                return node.accountId;
              })), CS_NonemptyStrings$Util.AccountId.Comparable) : Belt_Set.make(CS_NonemptyStrings$Util.AccountId.Comparable);
  var match = Belt_Array.partition(passengers, (function (pax) {
          if (Caml_obj.equal(Belt_Option.map(pax.email, CS_Emails$Util.Email.toPrimitive), currentUserEmail)) {
            return true;
          }
          var accountId = pax.accountId;
          if (accountId !== undefined) {
            return Belt_Set.has(userConnected, Caml_option.valFromOption(accountId));
          } else {
            return false;
          }
        }));
  var paxesIManage = match[0];
  var pax1MustBeBornBy = Belt_Option.flatMap(props.bookingListitem, (function (item) {
          return Belt_Option.map(item.voyage, (function (voyage) {
                        return voyage.pax1MustBeBornBy;
                      }));
        }));
  var tempContext_bookingId = CS_NonemptyStrings$Util.BookingId.toString(bookingId);
  var tempContext_mailtoEmail = SysCtx$Thin.vars.mailtoEmail;
  var tempContext = {
    bookingId: tempContext_bookingId,
    mailtoEmail: tempContext_mailtoEmail
  };
  var tmp;
  var exit = 0;
  if (isBc) {
    if (draftMode) {
      exit = 1;
    } else {
      tmp = paxManagementLocked ? null : JsxRuntime.jsx(Typography, {
              level: "body-sm",
              textColor: "neutral.500",
              children: "Press the \"Manage Booking\" button to enable changes to this list"
            });
    }
  } else if (draftMode) {
    exit = 1;
  } else {
    tmp = paxManagementLocked ? JsxRuntime.jsx(Typography, {
            level: "body-sm",
            textColor: "neutral.500",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManagePassengersLockedNonBCText, tempContext))
          }) : null;
  }
  if (exit === 1) {
    tmp = paxManagementLocked ? JsxRuntime.jsx(Typography, {
            level: "body-sm",
            textColor: "neutral.500",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManagePassengersLockedText, tempContext))
          }) : null;
  }
  return JsxRuntime.jsxs(BDContentBlock$Thin.make, {
              title: Txt$Thin.BookingDetails.passengerListHeading.value + " (" + String(numBerths) + ")",
              titleComponent: Caml_option.some(titleComponent),
              anchor: "paxList",
              children: [
                tmp,
                JsxRuntime.jsxs(Sheet, {
                      sx: {
                        overflow: "auto"
                      },
                      children: [
                        JsxRuntime.jsx(Stack, {
                              sx: {
                                display: {
                                  xs: "flex",
                                  md: "none"
                                }
                              },
                              children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$AddBerth, {
                                        fragmentRefs: fragmentRefs,
                                        numBerths: numBerths,
                                        diff: diff,
                                        url: url,
                                        paxManagementLocked: paxManagementLocked,
                                        fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                        getPreviewFromDiff: getPreviewFromDiff
                                      }))
                            }),
                        isBc || paxesIManage.length === 0 ? JsxRuntime.jsx(BDPassengerList$PaxTable, {
                                children: Belt_Array.mapWithIndex(passengers, (function (i, pax) {
                                        return JsxRuntime.jsx(BDPassengerList$BDPassengerTableRow, {
                                                    pax: pax,
                                                    currentUserEmail: currentUserEmail,
                                                    areConnectedAccts: areConnectedAccts,
                                                    queryFrag: queryFrag,
                                                    custodianAccountId: custodianAccountId,
                                                    getPreviewFromDiff: getPreviewFromDiff,
                                                    cantDetach: cantDetach,
                                                    cantDrop: cantDrop,
                                                    draftMode: draftMode,
                                                    isBc: isBc,
                                                    isActive: isActive,
                                                    userConnected: userConnected,
                                                    countryFrag: countryFrag,
                                                    refetchBookingDetails: refetchBookingDetails,
                                                    voyageSlug: voyageSlug,
                                                    bookingId: bookingId,
                                                    bookingVersionNumber: bookingVersionNumber,
                                                    pax1MustBeBornBy: pax1MustBeBornBy,
                                                    paxManagementLocked: paxManagementLocked,
                                                    fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                                    isLastTableRow: i === (passengers.length - 1 | 0),
                                                    justAddedBerth: Belt_Option.getWithDefault(diff.autoAdd, false)
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PaxTable, {
                                                  title: "Passengers I manage",
                                                  children: Belt_Array.mapWithIndex(paxesIManage, (function (i, pax) {
                                                          return JsxRuntime.jsx(BDPassengerList$BDPassengerTableRow, {
                                                                      pax: pax,
                                                                      currentUserEmail: currentUserEmail,
                                                                      areConnectedAccts: areConnectedAccts,
                                                                      queryFrag: queryFrag,
                                                                      custodianAccountId: custodianAccountId,
                                                                      getPreviewFromDiff: getPreviewFromDiff,
                                                                      cantDetach: cantDetach,
                                                                      cantDrop: cantDrop,
                                                                      draftMode: draftMode,
                                                                      isBc: isBc,
                                                                      isActive: isActive,
                                                                      userConnected: userConnected,
                                                                      countryFrag: countryFrag,
                                                                      refetchBookingDetails: refetchBookingDetails,
                                                                      voyageSlug: voyageSlug,
                                                                      bookingId: bookingId,
                                                                      bookingVersionNumber: bookingVersionNumber,
                                                                      pax1MustBeBornBy: pax1MustBeBornBy,
                                                                      paxManagementLocked: paxManagementLocked,
                                                                      fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                                                      isLastTableRow: i === (paxesIManage.length - 1 | 0),
                                                                      justAddedBerth: Belt_Option.getWithDefault(diff.autoAdd, false)
                                                                    }, String(i));
                                                        }))
                                                }))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PaxTable, {
                                                  title: "Other Passengers",
                                                  children: Belt_Array.mapWithIndex(match[1], (function (i, pax) {
                                                          return JsxRuntime.jsx(BDPassengerList$BDPassengerTableRow, {
                                                                      pax: pax,
                                                                      currentUserEmail: currentUserEmail,
                                                                      areConnectedAccts: areConnectedAccts,
                                                                      queryFrag: queryFrag,
                                                                      custodianAccountId: custodianAccountId,
                                                                      getPreviewFromDiff: getPreviewFromDiff,
                                                                      cantDetach: cantDetach,
                                                                      cantDrop: cantDrop,
                                                                      draftMode: draftMode,
                                                                      isBc: isBc,
                                                                      isActive: isActive,
                                                                      userConnected: userConnected,
                                                                      countryFrag: countryFrag,
                                                                      refetchBookingDetails: refetchBookingDetails,
                                                                      voyageSlug: voyageSlug,
                                                                      bookingId: bookingId,
                                                                      bookingVersionNumber: bookingVersionNumber,
                                                                      pax1MustBeBornBy: pax1MustBeBornBy,
                                                                      paxManagementLocked: paxManagementLocked,
                                                                      fareClassManagePaxBlocked: fareClassManagePaxBlocked
                                                                    }, String(i));
                                                        }))
                                                }))
                                      })
                                ]
                              })
                      ]
                    })
              ],
              initiallyOpen: true
            });
}

var make = BDPassengerList;

exports.DetailFragment = DetailFragment;
exports.PassengerMenu = PassengerMenu;
exports.BDPassengerTableRow = BDPassengerTableRow;
exports.AddBerthFragment = AddBerthFragment;
exports.AddBerth = AddBerth;
exports.PaxTable = PaxTable;
exports.make = make;
/* react Not a pure module */
