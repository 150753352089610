// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ConfirmPaxCard$Thin = require("./ConfirmPaxCard.bs.js");
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function ProvideInfoSelector$Tester(props) {
  var match = React.useState(function () {
        return "";
      });
  var setTest = match[1];
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "text",
              value: match[0],
              onChange: (function (e) {
                  var val = e.currentTarget.value;
                  setTest(function (param) {
                        return val;
                      });
                })
            });
}

var Tester = {
  make: ProvideInfoSelector$Tester
};

function ProvideInfoSelector$SaveButton(props) {
  var setLocalError = props.setLocalError;
  var model = HopperState$Util.Observable.getRoot(props.sessionPax, "RetainSkipUpdateFromView");
  var handler = props.useSessionSaveHandler(model, setLocalError, props.onLocalSuccess, props.setLocalLoading);
  return JsxRuntime.jsx(AppButton$Thin.make, {
              loading: props.loading,
              onClick: (function (param) {
                  if (handler.TAG === "Ok") {
                    return handler._0();
                  } else if (setLocalError !== undefined) {
                    return setLocalError(function (param) {
                                return "Error saving passenger information";
                              });
                  } else {
                    return ;
                  }
                }),
              disabled: Belt_Result.isError(handler),
              children: "Save"
            });
}

var SaveButton = {
  make: ProvideInfoSelector$SaveButton
};

function ProvideInfoSelector(props) {
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var provideInfoError = props.provideInfoError;
  var locked = props.locked;
  var sessionPax = props.sessionPax;
  var paxNum = props.paxNum;
  var sameAsBc = props.sameAsBc;
  var isSelected = props.isSelected;
  var match = React.useState(function () {
        return true;
      });
  var setProvideInfoLoading = match[1];
  var allTravelDocFieldsComplete = HopperState$Util.Observable.useComputed(sessionPax, SFState$Thin.allTravelDocFieldsComplete, undefined, undefined);
  HopperState$Util.Observable.useComputed(sessionPax, (function (p) {
          return SFState$Thin.paxBirthdateShouldError(p, pax1MustBeBornBy);
        }), undefined, undefined);
  var isDirty = HopperState$Util.Observable.FormField.useIsDirty(sessionPax, SFState$Thin.paxUtils);
  var confirmPaxCard = HopperState$Util.Observable.useComputed(sessionPax, (function (p) {
          var match = p.idx;
          var match$1 = SFState$Thin.preferredFirstName.getValue(p);
          var match$2 = SFState$Thin.preferredLastName.getValue(p);
          var match$3 = SFState$Thin.email.getValue(p);
          if (sameAsBc && !(match !== 1 || !(match$1 !== undefined && match$2 !== undefined && match$3 !== undefined))) {
            return [
                    match$1,
                    match$2,
                    match$3,
                    p.isComplete
                  ];
          }
          
        }), undefined, undefined);
  var minBirthdate = paxNum === 1 ? pax1MustBeBornBy : undefined;
  var isPaxIncomplete = HopperState$Util.Observable.useComputed(sessionPax, (function (p) {
          return !p.isComplete;
        }), undefined, undefined);
  var isBc = sameAsBc && paxNum === 1;
  return JsxRuntime.jsx(SelectorCard$Thin.make, {
              isSelected: isSelected,
              onClick: props.onClick,
              title: props.title,
              description: props.description,
              children: JsxRuntime.jsx(Stack, {
                    spacing: 1,
                    sx: {
                      display: isSelected ? "flex" : "none",
                      overflow: "hidden",
                      py: 1
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              sx: {
                                py: 1
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      sx: {
                                        p: 2,
                                        border: "1px solid",
                                        borderColor: "neutral.300",
                                        backgroundColor: "neutral.200",
                                        borderRadius: "sm"
                                      },
                                      children: [
                                        confirmPaxCard !== undefined ? JsxRuntime.jsx(ConfirmPaxCard$Thin.make, {
                                                name: confirmPaxCard[0] + " " + confirmPaxCard[1],
                                                email: confirmPaxCard[2],
                                                custodian: undefined,
                                                custodianEmail: undefined,
                                                isComplete: confirmPaxCard[3] || allTravelDocFieldsComplete
                                              }) : null,
                                        isBc && isPaxIncomplete || !sameAsBc || paxNum !== 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                children: [
                                                  isBc && !allTravelDocFieldsComplete ? JsxRuntime.jsx(Alert, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    color: "warning",
                                                                    level: "body-sm",
                                                                    children: "Passenger information is incomplete. Please fill out the form below!"
                                                                  })),
                                                          color: "warning",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                                    sx: {
                                                                      color: "warning.500"
                                                                    }
                                                                  })),
                                                          sx: {
                                                            borderLeft: "4px solid",
                                                            borderColor: "warningSecondary.500",
                                                            alignItems: "flex-start"
                                                          }
                                                        }) : null,
                                                  JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                                                        interface: PassengerForms$Thin.Travel.PassengerState.$$interface(sessionPax),
                                                        titleSize: "md",
                                                        disabled: locked,
                                                        minBirthdate: minBirthdate
                                                      })
                                                ]
                                              }) : null,
                                        confirmPaxCard !== undefined ? null : JsxRuntime.jsx(PassengerForms$Thin.Personal.make, {
                                                title: "User Profile",
                                                titleSize: "md",
                                                pax: sessionPax,
                                                disabled: locked
                                              })
                                      ]
                                    }),
                                isBc && isPaxIncomplete || !sameAsBc || paxNum !== 1 ? JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        spacing: 1,
                                        sx: {
                                          width: "fit-content",
                                          display: isDirty ? "flex" : "none",
                                          py: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(AppButton$Thin.make, {
                                                onClick: (function (param) {
                                                    HopperState$Util.Observable.FormField.resetToInitialValue(sessionPax, SFState$Thin.paxUtils);
                                                  }),
                                                variant: "outlined",
                                                color: "neutral",
                                                disabled: !isDirty,
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(ProvideInfoSelector$SaveButton, {
                                                sessionPax: sessionPax,
                                                loading: props.loading,
                                                useSessionSaveHandler: props.useSessionSaveHandler,
                                                setLocalError: props.setProvideInfoError,
                                                onLocalSuccess: (function () {
                                                    setProvideInfoLoading(function (param) {
                                                          return false;
                                                        });
                                                  }),
                                                setLocalLoading: setProvideInfoLoading
                                              })
                                        ]
                                      }) : null,
                                provideInfoError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                        message: provideInfoError,
                                        type_: "error"
                                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                              ]
                            }))
                  }),
              locked: locked
            });
}

var make = ProvideInfoSelector;

exports.Tester = Tester;
exports.SaveButton = SaveButton;
exports.make = make;
/* react Not a pure module */
