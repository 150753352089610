// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var regex = /^[0-9]{6,}$/;

var struct = S$RescriptSchema.refine(S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 6, undefined), (function (s) {
        return function (v) {
          var match = regex.test(v);
          var match$1 = Belt_Option.getWithDefault(Belt_Int.fromString(v), 0);
          if (match) {
            if (match$1 !== 0) {
              return ;
            } else {
              return s.fail("Mariner numbers cannot be entirely 0s", undefined);
            }
          } else {
            return s.fail("Mariner numbers are 6 or more digits only", undefined);
          }
        };
      }));

var HalMarinerNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

exports.HalMarinerNumber = HalMarinerNumber;
/* HalMarinerNumber Not a pure module */
