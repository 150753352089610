// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var SelectorCard$Thin = require("../../common/molecules/SelectorCard.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var InviteSelector$Thin = require("./InviteSelector.bs.js");
var LoyaltySelector$Thin = require("./LoyaltySelector.bs.js");
var ConnectedSelector$Thin = require("./ConnectedSelector.bs.js");
var ProvideInfoSelector$Thin = require("./ProvideInfoSelector.bs.js");

function PaxForm(props) {
  var countryFrag = props.countryFrag;
  var useSessionSaveHandler = props.useSessionSaveHandler;
  var sessionSaveLoading = props.sessionSaveLoading;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var voyageSlug = props.voyageSlug;
  var locked = props.pageLocked;
  var currentUserConnectedInfo = props.currentUserConnectedInfo;
  var paxNum = props.paxNum;
  var sessionData = props.sessionData;
  var isPaxOne = paxNum === 1;
  var match = AppConfig$Thin.Context.use();
  var sessionPax = SFState$Thin.paxViewByIdx(sessionData, paxNum);
  var match$1 = HopperState$Util.Observable.useReadWrite(SFState$Thin.paxTypeView(sessionPax), undefined);
  var setWhichSelected = match$1[1];
  var whichSelected = match$1[0];
  var showToggle = HopperState$Util.Observable.useComputed(sessionData, (function (param) {
          var bc = param.bc;
          if (bc !== undefined && isPaxOne && Belt_Option.isSome(bc.accountId)) {
            return !Belt_Array.some(bc.pendingVoyageSlugs, (function (slug) {
                          return Caml_obj.equal(slug, voyageSlug);
                        }));
          } else {
            return false;
          }
        }), undefined, undefined);
  var hasConnectedAccts = currentUserConnectedInfo !== undefined ? currentUserConnectedInfo.length !== 0 : false;
  var match$2 = React.useState(function () {
        
      });
  var setConnectedError = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var match$4 = HopperState$Util.Observable.useReadWrite(SFState$Thin.sameAsBcToggle(sessionData), undefined);
  var setSameAsBc = match$4[1];
  var sameAsBc = match$4[0];
  var lockedOrSelect = function (selector) {
    if (selector !== "AddConnected") {
      setConnectedError(function (param) {
            
          });
    }
    var match = selector === whichSelected;
    if (locked || match) {
      return ;
    } else if (sameAsBc && isPaxOne) {
      setSameAsBc(function (param) {
            return false;
          });
      return setWhichSelected(function (param) {
                  return selector;
                });
    } else {
      return HopperState$Util.Observable.notify(sessionPax, (function (param) {
                    var newrecord = Caml_obj.obj_dup(SFState$Thin.blankPax(paxNum));
                    newrecord.paxType = selector;
                    return newrecord;
                  }));
    }
  };
  return JsxRuntime.jsx(PaxFrame$Thin.make, {
              title: isPaxOne ? "Passenger 1 (Primary Contact)" : "Passenger " + String(paxNum),
              required: isPaxOne,
              sameAsBc: sameAsBc,
              showToggle: showToggle,
              onToggleSameAsBc: (function () {
                  if (sameAsBc) {
                    
                  } else {
                    setConnectedError(function (param) {
                          
                        });
                  }
                  setSameAsBc(function (param) {
                        return !sameAsBc;
                      });
                }),
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsx(ProvideInfoSelector$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "ProvideInfo"),
                            onClick: (function () {
                                lockedOrSelect("ProvideInfo");
                              }),
                            title: "Provide Info",
                            description: "Enter passenger details below for this guest.",
                            sameAsBc: sameAsBc,
                            paxNum: paxNum,
                            sessionPax: sessionPax,
                            locked: locked,
                            loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "ProvideInfo"),
                            provideInfoError: match$3[0],
                            setProvideInfoError: match$3[1],
                            pax1MustBeBornBy: pax1MustBeBornBy,
                            useSessionSaveHandler: useSessionSaveHandler,
                            countryFrag: countryFrag
                          }),
                      hasConnectedAccts ? JsxRuntime.jsx(ConnectedSelector$Thin.make, {
                              isSelected: Caml_obj.equal(whichSelected, "AddConnected"),
                              onClick: (function () {
                                  lockedOrSelect("AddConnected");
                                }),
                              title: "Select a connected account",
                              description: "Select from one of your connected accounts.",
                              paxNum: paxNum,
                              sessionPax: sessionPax,
                              currentUserConnectedInfo: currentUserConnectedInfo,
                              voyageSlug: voyageSlug,
                              countryFrag: countryFrag,
                              locked: locked || !hasConnectedAccts,
                              loading: sessionSaveLoading && Caml_obj.equal(whichSelected, "AddConnected"),
                              pax1MustBeBornBy: pax1MustBeBornBy,
                              useSessionSaveHandler: useSessionSaveHandler,
                              connectedError: match$2[0],
                              setConnectedError: setConnectedError
                            }) : null,
                      JsxRuntime.jsx(LoyaltySelector$Thin.make, {
                            isSelected: Caml_obj.equal(whichSelected, "AddByLoyalty"),
                            onClick: (function () {
                                lockedOrSelect("AddByLoyalty");
                              }),
                            title: "Add by loyalty number",
                            description: "Add a guest by their " + match.brandFamily.brandFamilyName + " loyalty number.",
                            paxNum: paxNum,
                            sessionPax: sessionPax,
                            locked: locked,
                            voyageSlug: voyageSlug,
                            useSessionSaveHandler: useSessionSaveHandler
                          }),
                      isPaxOne ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(InviteSelector$Thin.make, {
                                      isSelected: Caml_obj.equal(whichSelected, "Invite"),
                                      onClick: (function () {
                                          lockedOrSelect("Invite");
                                        }),
                                      title: "Invite by email",
                                      description: "Invite a guest to join your booking.",
                                      sessionPax: sessionPax,
                                      locked: locked,
                                      useSessionSaveHandler: useSessionSaveHandler
                                    }),
                                JsxRuntime.jsx(SelectorCard$Thin.make, {
                                      isSelected: Caml_obj.equal(whichSelected, "TBA"),
                                      onClick: (function () {
                                          if (locked) {
                                            return ;
                                          } else {
                                            return lockedOrSelect("TBA");
                                          }
                                        }),
                                      title: "I'll fill this out later",
                                      description: "I'm not sure who will be traveling yet.",
                                      children: null
                                    })
                              ]
                            })
                    ]
                  }),
              locked: locked
            });
}

var make = PaxForm;

exports.make = make;
/* react Not a pure module */
